import { CurrentUser } from '../users/types';
import { Directory } from '@capacitor/filesystem';

export const viewerTopBottom = 'top-bottom';
export const viewerLeft = 'left';
export type themeType = 'dark' | 'light';
export type displayMangaListType = 'cover' | 'list';
export type appModeType = 'sources' | 'ftp';
export type downloadFolderType = Directory.Data | Directory.Documents;

export interface AppState {
  token: string | undefined;
  appLoading: boolean;
  currentUser: CurrentUser;
  isFullyLogged: boolean;
  isLoading: boolean;
  error?: string;
  toastMessage: ToastMessage;
  tabBarVisible: boolean;
  headerVisible: boolean;
  viewer: string;
  apiUrl: string;
  theme: themeType;
  titleDisplay: boolean;
  displayMangaListType: displayMangaListType;
  displayFavoritesListType: displayMangaListType;
  downloadFolder: downloadFolderType;
  appMode: appModeType;
  ftpAccess: ftpAccessType[];
  connectedFtp?: ftpAccessType;
  appProdVersion?: string;
}

export interface ResetAppState {
  token: string | undefined;
  appLoading: boolean;
  currentUser: CurrentUser;
  isFullyLogged: boolean;
  isLoading: boolean;
  toastMessage: ToastMessage;
  tabBarVisible: boolean;
  headerVisible: boolean;
  ftpAccess: [];
}

export type ToastMessagePosition = 'top' | 'bottom' | 'middle' | undefined;

export interface ToastMessage {
  text: string;
  icon: string;
  position: ToastMessagePosition;
  isOpen: boolean;
}

export interface errorType {
  success: boolean;
  message: string;
}

export interface ftpAccessSendType {
  host: string;
  username: string;
  password?: string;
  port: string;
}

export interface ftpAccessUpdateType {
  host: string;
  username: string;
  password?: string;
  id: string;
  port: string;
}

export interface ftpAccessType {
  _id: string;
  host: string;
  username: string;
  port: string;
}
