import React, { useState } from 'react';
import { InputChangeEventDetail, IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import { ftpAccessSendType, ftpAccessType } from '../store/app/types';

interface FormFtpAccessComponentProps {
  submit: (data: ftpAccessSendType) => Promise<void>;
  ftpAccess?: ftpAccessType;
}

const FormFtpAccessComponent: React.FC<FormFtpAccessComponentProps> = ({ submit, ftpAccess }: FormFtpAccessComponentProps) => {
  const [username, setUsername] = useState(ftpAccess?.username);
  const [password, setPassword] = useState('');
  const [port, setPort] = useState(ftpAccess?.port);
  const [host, setHost] = useState(ftpAccess?.host);
  const handleUsernameChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setUsername(e.detail.value);
    }
  };

  const handlePasswordChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setPassword(e.detail.value);
    }
  };

  const handlePortChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setPort(e.detail.value);
    }
  };

  const handleHostChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setHost(e.detail.value);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>): void => {
    e.preventDefault();
    if (ftpAccess) {
      if (username && port && host) {
        submit({ username, port, host, password });
      }
      return;
    }
    if (username && password && port && host) {
      submit({ username, password, port, host });
    }
  };

  return (
    <div>
      <IonItem>
        <IonLabel position="floating">Host</IonLabel>
        <IonInput value={host} type="text" onIonChange={e => handleHostChange(e)} />
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Port</IonLabel>
        <IonInput value={port} type="text" onIonChange={e => handlePortChange(e)} />
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Username</IonLabel>
        <IonInput value={username} type="text" onIonChange={e => handleUsernameChange(e)} />
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Password</IonLabel>
        <IonInput value={password} placeholder={ftpAccess ? 'Laisser vide pour ne pas le modifier' : ''} type="password" onIonChange={e => handlePasswordChange(e)} />
      </IonItem>
      <div className="margin-button">
        <IonButton className="register-button" expand="block" color="primary" onClick={e => handleSubmit(e)}>
          Valider
        </IonButton>
      </div>
    </div>
  );
};

export default FormFtpAccessComponent;
