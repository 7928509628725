// Create the middleware instance and methods
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { addFavorite, removeFavorite } from './actions';
import { checkmarkCircle, bug } from 'ionicons/icons';
import { createToastMessage } from '../../utils/helpers';

const favoriteMiddleware = createListenerMiddleware();

////////FULFILLED///////

favoriteMiddleware.startListening({
  actionCreator: addFavorite.fulfilled,
  effect: async (action, listenerApi) => {
    createToastMessage('Favoris ajouté avec succès', checkmarkCircle, 'bottom', listenerApi);
  },
});

favoriteMiddleware.startListening({
  actionCreator: removeFavorite.fulfilled,
  effect: async (action, listenerApi) => {
    createToastMessage('Favoris supprimé avec succès', checkmarkCircle, 'bottom', listenerApi);
  },
});

////////REJECT///////

favoriteMiddleware.startListening({
  actionCreator: addFavorite.rejected,
  effect: async (action, listenerApi) => {
    if (action.error.message) {
      createToastMessage(action.error.message, bug, 'top', listenerApi);
    }
  },
});

export default favoriteMiddleware;
