import React, { useEffect, useRef, useState } from 'react';
import { IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonText, IonTitle, IonToolbar } from '@ionic/react';
import './PasswordListModal.scss';
import { closeOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { notification } from '../store/notifications/types';
import moment from 'moment';
import 'moment/locale/fr';

const NotificationsModal: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);
  const [notificationsFormatted, setNotificationsFormatted] = useState<{ [key: string]: notification[] }>();

  useEffect(() => {
    const array: { [key: string]: notification[] } = {};
    if (notifications) {
      notifications.forEach(notification => {
        moment.locale('fr');
        const date = moment(notification.createdDate).format('DD MMMM YYYY');
        if (!array[date]) {
          array[date] = [notification];
        } else {
          array[date].push(notification);
        }
      });
      setNotificationsFormatted(array);
    }
  }, [notifications]);

  const updateAllToRead = async (): Promise<void> => {
    modal.current?.dismiss();
  };

  const displayCover = (sourceId: string, cover?: string): string => {
    if (!cover) {
      return 'assets/img/none.jpg';
    }

    return cover;
  };

  return (
    <IonModal className="notifications-modal" ref={modal} trigger="open-notifications-modal" onDidDismiss={updateAllToRead}>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="p10">Notifications nouveaux chapitres</IonTitle>
          <IonButtons slot="end">
            <IonButtons slot="end">
              <IonButton strong={true} onClick={updateAllToRead}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {notificationsFormatted &&
          Object.keys(notificationsFormatted)
            .reverse()
            .map(key => (
              <div key={key} className="ion-item-with-padding">
                <IonItem className="ion-item-title">
                  <IonTitle className="border">{key}</IonTitle>
                </IonItem>
                {notificationsFormatted[key].map(item => (
                  <IonItem key={item._id} className="ion-item-no-padding-start" onClick={updateAllToRead} button routerLink={`/sources/${item.source}/manga?mangaId=${item.mangaId}`}>
                    <img src={displayCover(item.source, item.cover)} className="cover-mini" />
                    <IonLabel className="ion-text-wrap">
                      <IonText>
                        <h2>{item.mangaName}</h2>
                      </IonText>
                      <p>Source : {item.source}</p>
                    </IonLabel>
                    <IonBadge color="primary" slot="end">
                      + {item.nbrNewChapters}
                    </IonBadge>
                  </IonItem>
                ))}
              </div>
            ))}
      </IonContent>
    </IonModal>
  );
};

export default NotificationsModal;
