import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestApiWithAuth, postRequestApiWithAuth } from '../axiosApiRequest';
import axios from 'axios';
import { errorType } from '../app/types';

export const getUserNotificationAction = createAsyncThunk('app/getUserNotifications', async () => {
  try {
    return await getRequestApiWithAuth('/notifications/me');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const updateNotificationAction = createAsyncThunk('app/updateUserNotifications', async () => {
  try {
    return await getRequestApiWithAuth('/notifications/update');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const updateReadNNotificationsAction = createAsyncThunk('app/updateReadNNotifications', async () => {
  try {
    return await postRequestApiWithAuth('/notifications/update/read', {});
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});
