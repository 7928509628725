import { IonAlert, IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, persistor, RootState, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import TabBar from './components/TabBar';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.scss';
import TabBarFtp from './components/TabBarFtp';
import { getAppProdVersion } from './store/app/actions';

setupIonicReact();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppEnter />
      </PersistGate>
    </Provider>
  );
};

const AppEnter: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: RootState) => state.app.theme);
  const appMode = useSelector((state: RootState) => state.app.appMode);
  const appProdVersion = useSelector((state: RootState) => state.app.appProdVersion);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    dispatch(getAppProdVersion());
  }, []);

  useEffect(() => {
    if (appProdVersion && isPlatform('capacitor')) {
      if (appProdVersion !== process.env.REACT_APP_VERSION) {
        setShowAlert(true);
      }
    }
  }, [appProdVersion]);

  const redirectToUpdateAppUrl = (): void => {
    console.log('redirect');
    window.open('https://play.google.com/store/apps/details?id=perfect.scans&hl=fr-FR', '_system', 'location=yes');
  };

  return (
    <IonApp className={theme}>
      <IonReactRouter>
        {appMode === 'sources' && <TabBar />}
        {appMode === 'ftp' && <TabBarFtp />}
      </IonReactRouter>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header="Nouvelle version"
        subHeader="Une nouvelle version de l'app est disponible !"
        buttons={[
          {
            text: 'Mettre à jour',
            role: 'confirm',
            handler: redirectToUpdateAppUrl,
          },
        ]}
      />
    </IonApp>
  );
};

export default App;
