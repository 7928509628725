import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

interface HelpFtpAccessModalTypes {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const HelpFtpAccessModal: React.FC<HelpFtpAccessModalTypes> = ({ isOpen, setIsOpen }: HelpFtpAccessModalTypes) => {
  return (
    <IonModal className="edit-ftp-access-modal" isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Aide accès FTP</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={() => setIsOpen(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-item-with-padding">
        <h1>Identifiants</h1>
        <p>Pour se conneter à votre serveur FTP/SFTP il vous faut renseigner :</p>
        <ul>
          <li>Host</li>
          <li>Port</li>
          <li>Username</li>
          <li>Password</li>
        </ul>
        <p>{"Il est recommander d'utiliser la methode SFTP via le port 22"}</p>
        <p>{"Il faut que l'utilisateur ai les droits en lecture ET écriture."}</p>
        <p>{"L'écriture concerne uniquement l'ajout d'un fichier JSON afin de vous offrir un suivi des manga lus/non lus"}</p>

        <h1>{"Format d'images"}</h1>

        <p>Les formats compatibles sont</p>
        <ul>
          <li>png</li>
          <li>jpg</li>
          <li>jpeg</li>
          <li>webp</li>
          <li>cbr</li>
        </ul>

        <h1>{"L'arborescence"}</h1>
        <p>Afin de pouvoir récuperer correctement les manga il faut respecter le format suivant</p>
        <ul>
          <li>
            covers
            <ul>
              <li>{'{name_manga}.jpg/png/jpeg/gif/webp'}</li>
            </ul>
          </li>

          <li>
            manga
            <ul>
              <li>
                {'{name_manga}'}
                <ul>
                  <li>
                    001
                    <ul>
                      <li>{'01.jpg/png/jpeg/gif/webp/cbr'}</li>
                      <li>{'02.jpg/png/jpeg/gif/webp/cbr'}</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </IonContent>
    </IonModal>
  );
};

export default HelpFtpAccessModal;
