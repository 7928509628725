import React, { useState } from 'react';
import { IonButton, IonContent, IonPage, IonActionSheet, isPlatform } from '@ionic/react';
import HeaderComponent from '../components/HeaderComponent';
import './WelcomePage.scss';
import useLongPress from '../components/UseLongPress';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { setApiURL } from '../store/app/appSlice';
import UpdateInfosModal from '../components/UpdateInfosModal';
const WelcomePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const onLongPress = (): void => {
    setShowActionSheet(true);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 3000,
  };

  const changeApiUrl = async (url: string | undefined): Promise<void> => {
    if (url) {
      await dispatch(setApiURL(url));
      window.location.reload();
    }
  };

  const longPressEvent = useLongPress(onLongPress, defaultOptions);
  return (
    <IonPage className="welcome-page">
      <HeaderComponent title="Perfect Scans" />
      <IonContent fullscreen className="wallpaper" style={{ backgroundImage: 'url(assets/img/wallpaper.jpg)' }}>
        <div className="container-buttons-wallpaper">
          <div className="margin-button content-wallpaper">
            <h2 className="ion-text-center" {...longPressEvent}>
              {'Bienvenue'}
            </h2>
            <IonButton routerLink="/login" className="mb-20 mt-20" expand="block" color="secondary">
              Connexion
            </IonButton>
            <IonButton routerLink="/register" className="register-button mb-20" expand="block" fill="outline">
              Incription
            </IonButton>
            {isPlatform('capacitor') && (
              <IonButton routerLink="/offline" expand="block" color="secondary">
                Lire offline
              </IonButton>
            )}
          </div>
        </div>
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[
            {
              text: 'GOOGLE',
              role: 'destructive',
              handler: () => changeApiUrl(process.env.REACT_APP_API_PROD_URL),
            },
            {
              text: 'HEROKU',
              role: 'destructive',
              handler: () => changeApiUrl(process.env.REACT_APP_API_PROD_HEROKU),
            },
            {
              text: 'Local/Online',
              role: 'destructive',
              handler: () => changeApiUrl(process.env.REACT_APP_API_LOCAL_ONLINE_URL),
            },
            {
              text: 'Local',
              role: 'destructive',
              handler: () => changeApiUrl(process.env.REACT_APP_API_LOCAL_URL),
            },
            {
              text: 'Fermer',
              role: 'cancel',
            },
          ]}
        />
        <UpdateInfosModal />
      </IonContent>
    </IonPage>
  );
};

export default WelcomePage;
