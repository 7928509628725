import React from 'react';
import { IonContent, IonPage, IonItem } from '@ionic/react';
import HeaderComponent from '../components/HeaderComponent';
import './WelcomePage.scss';

const PrivacyPage: React.FC = () => {
  return (
    <IonPage className="welcome-page">
      <HeaderComponent title="Perfect Scans" />
      <IonContent fullscreen>
        <h1>CGU</h1>
        <IonItem>
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          Nous avons conçu l'application Prefect scans comme une application de lecture de livres.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          Cette page est utilisée pour informer les visiteurs du site Web de mes politiques en matière de collecte, d'utilisation et de divulgation des informations personnelles si quelqu'un décide
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          d'utiliser mon service.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          Si vous choisissez d'utiliser mon service, vous acceptez la collecte et l'utilisation d'informations en relation avec cette politique. Les informations personnelles que je collecte sont
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          utilisées pour fournir et améliorer le service. Je n'utiliserai ni ne partagerai vos informations avec qui que ce soit, sauf de la manière décrite dans la présente politique de
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          confidentialité.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          Les termes utilisés dans cette politique de confidentialité ont la même signification que dans nos conditions générales, qui sont accessibles sur Prefect scans, sauf indication contraire
          dans la présente politique de confidentialité.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          1. Les données que nous collectons dépendent du contexte de vos interactions avec nous, des choix que vous faites, y compris vos paramètres de confidentialité, et des produits et
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          fonctionnalités que vous utilisez. Les données que nous collectons peuvent inclure la version du code SDK/API/JS, le navigateur, le fournisseur de services Internet, l'adresse IP, la
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          plate-forme, l'horodatage, l'identifiant de l'application, la version de l'application, le canal de distribution de l'application, l'identifiant indépendant de l'appareil, l'identifiant
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          d'annonce iOS (IDFA), l'annonce Android. identifiant principal, adresse de carte réseau (MAC) et code international d'identification d'appareil mobile (IMEI) Le modèle d'équipement, le
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          fabricant du terminal, la version du système d'exploitation du terminal, l'heure de début/d'arrêt de la session, l'emplacement de la langue, le fuseau horaire et l'état du réseau (WiFi et
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          ainsi de suite), le disque dur, le CPU et l'utilisation de la batterie, etc.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          2. Services
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          2.1 "Prefect scans" Le contenu spécifique du service réseau est fourni par "Prefect scans" en fonction de la situation réelle, comme la lecture d'œuvres littéraires. "Prefect scans" se
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          réserve le droit de modifier, d'interrompre ou de résilier tous les services du réseau à tout moment.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          2.2 Les utilisateurs comprennent que "Prefect scans" ne fournit que les services réseau pertinents, en plus des équipements liés aux services réseau associés (tels que les ordinateurs, les
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          modems et autres appareils liés à Internet, etc.) et les coûts requis (tels que l'accès Internet et payer les frais de téléphone et d'accès à Internet, etc.) doit être à la charge de
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          l'utilisateur.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          2.3 Amélioration du produit. Nous utilisons les données pour améliorer continuellement nos produits, notamment en ajoutant de nouvelles fonctionnalités ou capacités
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          3. Utiliser les règles
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          3.1 Lors de la demande d'utilisation des Services « Prefect scans », l'utilisateur doit demander à « Prefect scans » son inscription et fournir des informations personnelles exactes. En cas
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          de modification des données personnelles, celles-ci doivent être mises à jour à temps. "Prefect scans" ne sera pas responsable de toute perte d'accès aux Services en raison de données
          inexactes.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          3.2 L'inscription de l'utilisateur est réussie, "Prefect scans" enregistrera chaque compte d'utilisateur et le mot de passe correspondant, le compte d'utilisateur et le mot de passe par
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          l'utilisateur responsable de la garde du compte d'utilisateur devrait être responsable de toutes les activités et événements de responsabilité légale.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (A) se conformer aux lois et réglementations applicables
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (B) ne doit pas utiliser le système de services Web à des fins illégales
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (C) se conformer à tous les protocoles, réglementations et procédures de réseau relatifs aux services de réseau
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (D) n'utilisera pas le système de service Web "Prefect scans" pour effectuer une action qui pourrait nuire au fonctionnement normal d'Internet
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (E) ne doit pas transmettre de matériel d'information harcelant, abusif, abusif, intimidant, vulgaire, obscène ou tout autre matériel d'information illégal en utilisant le système de
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          services Web "Prefect scans"
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (F) ne doit pas utiliser le système de service Web "Prefect scans" pour tout comportement préjudiciable
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          4. Propriété du contenu
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          4.1 Les services Perfect scans peuvent inclure du texte, des images.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          5. Protection de la vie privée
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          5.1 La protection de la vie privée des utilisateurs, en particulier des mineurs, est une politique fondamentale de "Prefect scans", donc si un parent (tuteur) souhaite que des mineurs (en
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          particulier des enfants de moins de 10 ans) utilisent le service, les parents (tuteurs)) Au nom de la demande d'enregistrement, en acceptant le service, devrait être le statut de tuteur
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          légal pour déterminer si le service est en ligne avec les mineurs. « Prefect scans » s'engage à ne pas fournir les informations d'enregistrement des utilisateurs à des tiers (autres que ceux
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          listés en 5.2) ni aux contenus non publics stockés sur « Prefect scans » lorsque les utilisateurs utilisent les Services Web, sauf dans les cas suivants :
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (A) autorisation préalable de l'utilisateur
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (B) conformément aux lois et réglementations applicables
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (C) conformément aux exigences des départements gouvernementaux concernés
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (D) pour sauvegarder les intérêts du public
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (E) Pour maintenir les droits et intérêts légitimes de "Prefect scans". 5.2 "Prefect scans" peut coopérer avec des tiers pour fournir les services réseau pertinents à l'utilisateur. Dans ce
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          cas, si le tiers accepte d'assumer la même responsabilité de protection de la vie privée de l'utilisateur, « Prefect scans » pourra fournir les informations d'inscription de l'utilisateur,
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          au tiers.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          5.3 Sans la divulgation des données de confidentialité des utilisateurs individuels, "Prefect scans" a le droit de procéder à une analyse technique de l'ensemble de la base de données des
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          utilisateurs et une analyse commerciale de la base de données des utilisateurs a été analysée, l'utilisation de. Bien que "Prefect scans" ait fait de grands efforts pour protéger la vie
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          privée des utilisateurs, il n'y a aucune garantie que les mesures de sécurité existantes rendront les informations techniques des utilisateurs exemptes de toute perte.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          5.4 L'application n'utilise aucun services tiers qui peuvent collecter des informations utilisées pour vous identifier.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          6. Clause de non-responsabilité
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          6.1 "Prefect scans" Le propriétaire ne garantit pas ce qui suit
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (A) Ce service répondra à vos exigences.
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
          (B) Le Service sera ininterrompu, ponctuel, sécurisé ou sans erreur.
        </IonItem>
      </IonContent>
    </IonPage>
  );
};
/* eslint-enable no-alert, no-console */
export default PrivacyPage;
