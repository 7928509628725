import React, { useEffect, useState } from 'react';
import { addFileInFolderAndroid, appName, naturalCompare, readFileContentOnAndroid, readFolderContentOnAndroid, readMediaContentOnAndroid } from '../utils/helpers';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonLoading, IonPage, isPlatform } from '@ionic/react';
import queryString from 'query-string';
import { setHeaderVisible, setTabBarVisible } from '../store/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import HeaderComponent from '../components/HeaderComponent';
import './ReaderPage.scss';
import { mangaInfos } from '../store/scanSources/types';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen';

const DownloadMangaReadPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const headerVisible = useSelector((state: RootState) => state.app.headerVisible);
  const tabBarVisible = useSelector((state: RootState) => state.app.tabBarVisible);
  // eslint-disable-next-line
  const path = queryString.parse(location.search).path as string;
  // eslint-disable-next-line
  const source = queryString.parse(location.search).source as string;
  // eslint-disable-next-line
  const mangaName = queryString.parse(location.search).mangaName as string;
  const [scansList, setScansList] = useState<{ fileName: string; file: string }[]>([]);
  // eslint-disable-next-line
  const [selectedChapter, setSelectedChapter] = useState<string>(queryString.parse(location.search).chapter as string);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mangaInfos, setMangaInfos] = useState<mangaInfos | undefined>(undefined);

  useEffect(() => {
    dispatch(setTabBarVisible(false));
    dispatch(setHeaderVisible(false));
    const fetchData = async (): Promise<void> => {
      const array = [];
      let dir;
      try {
        dir = await readFolderContentOnAndroid(path);

        for (let i = 0; i < dir.files.length; i++) {
          const scan = dir.files[i];
          try {
            const file = await readMediaContentOnAndroid(`${path}/${scan}`);
            array.push({ fileName: scan, file: file.data });
          } catch (e) {
            console.error(e);
          }
        }
        setScansList(array);
        setIsLoading(false);

        const mangaInfos = await readFileContentOnAndroid(`${appName}/${source}/${mangaName}/index.json`);

        if (mangaInfos) {
          setMangaInfos(JSON.parse(mangaInfos.data));
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();

    if (isPlatform('capacitor')) {
      AndroidFullScreen.immersiveMode();
    }

    return () => {
      if (isPlatform('capacitor')) {
        AndroidFullScreen.showSystemUI();
      }
      dispatch(setTabBarVisible(true));
      dispatch(setHeaderVisible(true));
    };
  }, []);

  const onClickMedia = async (): Promise<void> => {
    dispatch(setTabBarVisible(!tabBarVisible));
    dispatch(setHeaderVisible(!headerVisible));
  };

  // eslint-disable-next-line
  const loadNextPage = async (event: any): Promise<void> => {
    let dir;
    const array = [];
    try {
      dir = await readFolderContentOnAndroid(`${appName}/${source}/${mangaName}`);
      const files = dir.files.filter(data => data != 'index.json').sort(naturalCompare);
      const currentIndexOfScan = files.indexOf(selectedChapter);
      //set read
      if (mangaInfos && !mangaInfos.chaptersRead?.includes(files[currentIndexOfScan])) {
        setMangaInfos({ ...mangaInfos, chaptersRead: [...mangaInfos.chaptersRead, files[currentIndexOfScan]] });
        try {
          await addFileInFolderAndroid(`${appName}/${source}/${mangaName}`, 'index.json', JSON.stringify({ ...mangaInfos, chaptersRead: [...mangaInfos.chaptersRead, files[currentIndexOfScan]] }));
        } catch (e) {
          console.error(e);
        }
      }
      //load next page
      if (files[currentIndexOfScan + 1]) {
        const dirChapter = await readFolderContentOnAndroid(`${appName}/${source}/${mangaName}/${files[currentIndexOfScan + 1]}`);
        for (let i = 0; i < dirChapter.files.length; i++) {
          const scan = dirChapter.files[i];
          try {
            const file = await readMediaContentOnAndroid(`${appName}/${source}/${mangaName}/${files[currentIndexOfScan + 1]}/${scan}`);
            array.push({ fileName: scan, file: file.data });
          } catch (e) {
            console.error(e);
          }
        }
        setScansList([...scansList, ...array]);
        setSelectedChapter(files[currentIndexOfScan + 1]);
      }
    } catch (e) {
      console.error(e);
    }
    event.target.complete();
  };

  return (
    <IonPage className="reader download-manga-page">
      <HeaderComponent title={selectedChapter} />
      <IonContent fullscreen className="bg-reader">
        {!isLoading ? <ViewerTopBottom loadNextPage={loadNextPage} onClickMedia={onClickMedia} files={scansList} /> : <IonLoading cssClass="my-custom-class" isOpen={true} message={'Chargement...'} />}
      </IonContent>
    </IonPage>
  );
};

interface ScansListType {
  files: { fileName: string; file: string }[];
  onClickMedia: () => Promise<void>;
  // eslint-disable-next-line
  loadNextPage: (event: any) => Promise<void>;
}

const ViewerTopBottom: React.FC<ScansListType> = ({ files, onClickMedia, loadNextPage }: ScansListType) => {
  return (
    <>
      {files.map(item => (
        <span key={item.fileName} onClick={() => onClickMedia()} className="container-img">
          <div className="swiper-zoom-container">
            <img alt={item.fileName} src={`data:image/gif;base64,${item.file}`} height="auto" width="100%" />
          </div>
        </span>
      ))}
      <IonInfiniteScroll position="bottom" onIonInfinite={loadNextPage} threshold="100px">
        <IonInfiniteScrollContent loadingSpinner="bubbles" className="loading" loadingText="Chargement..."></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

export default DownloadMangaReadPage;
