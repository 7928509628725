import React, { useState } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonButton, IonIcon, IonItem, IonLoading, IonActionSheet } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import './MangaFtpPage.scss';
import { addCircleOutline, ellipsisHorizontalOutline, gridOutline, helpCircleOutline, informationOutline, listOutline, logInOutline, refreshOutline } from 'ionicons/icons';
import { addFtpAccess } from '../store/app/actions';
import { ftpAccessSendType } from '../store/app/types';
import FormFtpAccessComponent from '../components/FormFtpAccessComponent';
import { resetConnectedFtp, setConnectedFtp, setDisplayFavoritesListType } from '../store/app/appSlice';
import { getFtpAccessData } from '../store/ftpManga/actions';
import { GridMangaFtpCover, GridMangaFtpList } from '../components/GridMangaFtp';
import EditFtpAccessModal from '../components/EditFtpAccessModal';
import PopoverComponent, { textsPopover } from '../components/PopoverComponent';
import HelpFtpAccessModal from '../components/HelpFtpAccessModal';

const MangaFtpPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const ftpAccess = useSelector((state: RootState) => state.app.ftpAccess);
  const connectedFtp = useSelector((state: RootState) => state.app.connectedFtp);
  const ftpManga = useSelector((state: RootState) => state.ftpManga.manga);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const appIsLoading = useSelector((state: RootState) => state.app.isLoading);
  const displayFavoritesListType = useSelector((state: RootState) => state.app.displayFavoritesListType);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [modalEditFtpIsOpen, setModalEditFtpIsOpen] = useState<boolean>(false);
  const [modalHelpFtpAccessIsOpen, setModalHelpFtpAccessIsOpen] = useState<boolean>(false);

  const submit = async (data: ftpAccessSendType): Promise<void> => {
    dispatch(addFtpAccess(data));
  };

  const connectToFtp = async (id: string): Promise<void> => {
    setIsLoading(true);
    const data = await dispatch(getFtpAccessData(id));
    const selectedFtp = ftpAccess.find(ftp => ftp._id === id);
    if (data.payload.data && selectedFtp) {
      await dispatch(setConnectedFtp(selectedFtp));
    }
    setIsLoading(false);
  };

  const refresh = async (): Promise<void> => {
    if (connectedFtp) {
      setIsLoading(true);
      await dispatch(getFtpAccessData(connectedFtp._id));
      setIsLoading(false);
    }
  };

  const setIsModalEditFtpOpen = (value: boolean): void => {
    setModalEditFtpIsOpen(value);
  };

  const setIsModalHelpFtpAccessFtpOpen = (value: boolean): void => {
    setModalHelpFtpAccessIsOpen(value);
  };

  return (
    <IonPage className="manga-ftp-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{!connectedFtp ? 'Connexion FTP' : connectedFtp.host}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => dispatch(setDisplayFavoritesListType(displayFavoritesListType === 'cover' ? 'list' : 'cover'))} expand="block">
              <IonIcon icon={displayFavoritesListType === 'cover' ? listOutline : gridOutline} />
            </IonButton>
            {!connectedFtp && (
              <>
                <IonButton id="click-trigger-manga-ftp-page">
                  <IonIcon icon={informationOutline} />
                </IonButton>
                <IonButton onClick={() => setModalHelpFtpAccessIsOpen(true)}>
                  <IonIcon icon={helpCircleOutline} />
                </IonButton>
              </>
            )}
            {connectedFtp && (
              <IonButton onClick={refresh}>
                <IonIcon icon={refreshOutline} />
              </IonButton>
            )}
            <IonButton onClick={() => setShowActionSheet(true)} expand="block">
              <IonIcon icon={ellipsisHorizontalOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-item-with-padding" fullscreen>
        {ftpAccess.length === 0 && (
          <>
            <h1 className="ion-text-center">Aucun accès FTP disponnible, veuillez en ajouter un.</h1>
            <FormFtpAccessComponent submit={submit} />
          </>
        )}
        {ftpAccess.length !== 0 && !connectedFtp && !isLoading && !appIsLoading && (
          <>
            {ftpAccess.map(ftp => (
              <IonItem key={ftp.host}>
                <IonTitle>{ftp.host}</IonTitle>
                <IonButton onClick={() => connectToFtp(ftp._id)} slot="end">
                  <IonIcon icon={logInOutline} />
                </IonButton>
              </IonItem>
            ))}
            <div className="ion-text-center" onClick={() => setIsModalEditFtpOpen(true)}>
              <IonIcon className="add-icon" icon={addCircleOutline} />
            </div>
          </>
        )}
        {connectedFtp && ftpManga && <>{displayFavoritesListType === 'cover' ? <GridMangaFtpCover mangaList={ftpManga} /> : <GridMangaFtpList mangaList={ftpManga} />}</>}
        <IonLoading isOpen={isLoading} message={'Chargement...'} />
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={
            connectedFtp
              ? [
                  {
                    text: `Déconnexion de ${connectedFtp.host}`,
                    role: 'destructive',
                    handler: () => {
                      dispatch(resetConnectedFtp());
                    },
                  },
                  {
                    text: 'Fermer',
                    role: 'cancel',
                  },
                ]
              : [
                  {
                    text: 'Editer les accès FTP',
                    role: 'destructive',
                    handler: () => setIsModalEditFtpOpen(true),
                  },
                  {
                    text: 'Fermer',
                    role: 'cancel',
                  },
                ]
          }
        />
        <EditFtpAccessModal setIsOpen={setIsModalEditFtpOpen} isOpen={modalEditFtpIsOpen} />
        {!connectedFtp && <PopoverComponent text={textsPopover.mangaFtpPage} trigger="click-trigger-manga-ftp-page" />}
        <HelpFtpAccessModal isOpen={modalHelpFtpAccessIsOpen} setIsOpen={setIsModalHelpFtpAccessFtpOpen} />
      </IonContent>
    </IonPage>
  );
};

export default MangaFtpPage;
