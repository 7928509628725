import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestApiWithAuth } from '../axiosApiRequest';
import axios from 'axios';
import { errorType } from '../app/types';
import { DownloadChapters, getChaptersListType, getPagesType, getScanSourcesListType } from './types';

export const getSources = createAsyncThunk('app/getSources', async () => {
  try {
    return await getRequestApiWithAuth('/sources');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getScanSourcesList = createAsyncThunk('app/getScanSourcesList', async (data: getScanSourcesListType) => {
  let args = `?start=${data.start}`;

  if (data.title) {
    args = args.concat(`&title=${data.title}`);
  }
  if (data.genre) {
    args = args.concat(`&genre=${data.genre}`);
  }
  if (data.tag) {
    args = args.concat(`&tag=${data.tag}`);
  }
  if (data.orderBy) {
    args = args.concat(`&orderBy=${data.orderBy}`);
  }
  if (data.status) {
    args = args.concat(`&status=${data.status}`);
  }

  try {
    return await getRequestApiWithAuth(`/sources/${data.id}${args}`);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const searchAllSources = createAsyncThunk('app/getSearchSource', async (data: getScanSourcesListType) => {
  let args = `?start=${data.start}&searchLimit=50`;

  if (data.title) {
    args = args.concat(`&title=${data.title}`);
  }
  if (data.genre) {
    args = args.concat(`&genre=${data.genre}`);
  }
  if (data.tag) {
    args = args.concat(`&tag=${data.tag}`);
  }
  if (data.orderBy) {
    args = args.concat(`&orderBy=${data.orderBy}`);
  }
  if (data.status) {
    args = args.concat(`&status=${data.status}`);
  }

  try {
    return await getRequestApiWithAuth(`/sources/${data.id}${args}`);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getChaptersList = createAsyncThunk('app/getChapters', async (data: getChaptersListType) => {
  try {
    return await getRequestApiWithAuth(`/sources/${data.sourceId}/manga?mangaId=${data.mangaId}`);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getPagesList = createAsyncThunk('app/getPages', async (data: getPagesType) => {
  try {
    return await getRequestApiWithAuth(`/sources/${data.sourceId}/chapter?chapterId=${data.chaptersId}&mangaId=${data.mangaId}`);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getNextPage = createAsyncThunk('app/getNextPage', async (data: getPagesType) => {
  try {
    return await getRequestApiWithAuth(`/sources/${data.sourceId}/chapter?chapterId=${data.chaptersId}&mangaId=${data.mangaId}`);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getDownloadChapters = createAsyncThunk('app/getDownloadChapters', async (data: DownloadChapters) => {
  try {
    return await getRequestApiWithAuth(`/sources/${data.sourceId}/download?mangaId=${data.mangaId}&chapterId=${data.chaptersId}`);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});
