import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store/store';
import LoginPage from './pages/LoginPage';
import WelcomePage from './pages/WelcomePage';
import RegisterPage from './pages/RegisterPage';
import { IonLoading } from '@ionic/react';
import ToastMessage from './components/ToastMessage';
import { refreshToken } from './store/app/actions';
import { setAppLoading } from './store/app/appSlice';
import SourcesPage from './pages/SourcesPage';
import ScanListPage from './pages/ScanListPage';
import ChaptersPage from './pages/ChaptersPage';
import ReaderPage from './pages/ReaderPage';
import FavoritesPage from './pages/FavoritesPage';
import AccountPage from './pages/AccountPage';
import DownloadMangaPage from './pages/DownloadMangaPage';
import DownloadMangaReadPage from './pages/DownloadMangaReadPage';
import EditLostPassword from './pages/EditLostPassword';
import PrivacyPage from './pages/PrivacyPage';

const RedirectToHomepage: React.FC = () => {
  return <Redirect to="/favorites" />;
};

const RedirectToWelcomePage: React.FC = () => {
  return <Redirect to="/" />;
};

const RoutingMenu: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const appState = useSelector((state: RootState) => state.app);
  const isFullyLogged = appState.isFullyLogged;
  const isLoading = appState.isLoading;
  const appLoading = appState.appLoading;

  const refreshTokenAction = async (): Promise<void> => {
    if (!appState.isFullyLogged && localStorage.getItem('token')) {
      await dispatch(refreshToken());
    } else {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (appState.appLoading) {
      refreshTokenAction();
    }
  }, [appState.token]);

  return (
    <>
      {!appLoading && (
        <>
          <Route exact path="/" render={() => (isFullyLogged ? <RedirectToHomepage /> : <WelcomePage />)} />
          <Route exact path="/login" render={() => (isFullyLogged ? <RedirectToHomepage /> : <LoginPage />)} />
          <Route exact path="/register" render={() => (isFullyLogged ? <RedirectToHomepage /> : <RegisterPage />)} />
          <Route exact path="/sources" render={() => (isFullyLogged ? <SourcesPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/sources/:sourceId/manga" render={() => (isFullyLogged ? <ChaptersPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/sources/:sourceId/chapters" render={() => (isFullyLogged ? <ReaderPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/sources/:sourceId" render={() => (isFullyLogged ? <ScanListPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/favorites" render={() => (isFullyLogged ? <FavoritesPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/account" render={() => (isFullyLogged ? <AccountPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/offline" render={() => <DownloadMangaPage />} />
          <Route exact path="/offline/read" render={() => <DownloadMangaReadPage />} />
          <Route exact path="/password-edit" render={() => <EditLostPassword />} />
          <Route exact path="/privacy" render={() => <PrivacyPage />} />
        </>
      )}
      <IonLoading cssClass="my-custom-class" isOpen={isLoading || appLoading} message={'Chargement...'} />
      <ToastMessage />
    </>
  );
};

export default RoutingMenu;
