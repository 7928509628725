import { createListenerMiddleware } from '@reduxjs/toolkit';
import { updateNotificationAction } from './actions';
import { getUserFavorites } from '../favorites/actions';
import { createToastMessage } from '../../utils/helpers';
import { information } from 'ionicons/icons';
import { setUpdateInProgress } from './notificationsSlice';
import { getUserChaptersRead } from '../chaptersRead/actions';

const notificationsMiddleware = createListenerMiddleware();

notificationsMiddleware.startListening({
  actionCreator: updateNotificationAction.fulfilled,
  effect: async (action, listenerApi) => {
    await listenerApi.dispatch(setUpdateInProgress(false));
    listenerApi.dispatch(getUserFavorites());
    listenerApi.dispatch(getUserChaptersRead());
    if (action.payload.newNotification) {
      await listenerApi.dispatch(getUserFavorites());
      createToastMessage('De nouveaux chapitres sont disponibles !', information, 'bottom', listenerApi);
    }
  },
});

notificationsMiddleware.startListening({
  actionCreator: updateNotificationAction.pending,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setUpdateInProgress(true));
  },
});

notificationsMiddleware.startListening({
  actionCreator: updateNotificationAction.rejected,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setUpdateInProgress(false));
  },
});

export default notificationsMiddleware;
