import React from 'react';
import { IonHeader, IonTitle, IonToolbar, IonBackButton, IonButtons } from '@ionic/react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

interface HeaderComponentProps {
  title: string;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ title }: HeaderComponentProps) => {
  const headerVisible = useSelector((state: RootState) => state.app.headerVisible);
  return (
    <IonHeader style={{ display: headerVisible ? 'inherit' : 'none' }}>
      <IonToolbar>
        <IonTitle>{title}</IonTitle>
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderComponent;
