import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store/store';
import LoginPage from './pages/LoginPage';
import WelcomePage from './pages/WelcomePage';
import RegisterPage from './pages/RegisterPage';
import { IonLoading } from '@ionic/react';
import ToastMessage from './components/ToastMessage';
import { refreshToken } from './store/app/actions';
import { setAppLoading } from './store/app/appSlice';
import AccountPage from './pages/AccountPage';
import DownloadMangaPage from './pages/DownloadMangaPage';
import DownloadMangaReadPage from './pages/DownloadMangaReadPage';
import EditLostPassword from './pages/EditLostPassword';
import MangaFtpPage from './pages/MangaFtpPage';
import ChaptersFtpPage from './pages/ChaptersFtpPage';
import ReaderFtpPage from './pages/ReaderFtpPage';

const RedirectToHomepage: React.FC = () => {
  return <Redirect to="/manga" />;
};

const RedirectToWelcomePage: React.FC = () => {
  return <Redirect to="/" />;
};

const RoutingMenuFTP: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const appState = useSelector((state: RootState) => state.app);
  const isFullyLogged = appState.isFullyLogged;
  const isLoading = appState.isLoading;
  const appLoading = appState.appLoading;

  const refreshTokenAction = async (): Promise<void> => {
    if (!appState.isFullyLogged && localStorage.getItem('token')) {
      await dispatch(refreshToken());
    } else {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (appState.appLoading) {
      refreshTokenAction();
    }
  }, [appState.token]);

  return (
    <>
      {!appLoading && (
        <>
          <Route exact path="/" render={() => (isFullyLogged ? <RedirectToHomepage /> : <WelcomePage />)} />
          <Route exact path="/login" render={() => (isFullyLogged ? <RedirectToHomepage /> : <LoginPage />)} />
          <Route exact path="/register" render={() => (isFullyLogged ? <RedirectToHomepage /> : <RegisterPage />)} />
          <Route exact path="/account" render={() => (isFullyLogged ? <AccountPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/offline" render={() => <DownloadMangaPage />} />
          <Route exact path="/offline/read" render={() => <DownloadMangaReadPage />} />
          <Route exact path="/password-edit" render={() => <EditLostPassword />} />
          <Route exact path="/manga" render={() => (isFullyLogged ? <MangaFtpPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/manga/chapters" render={() => (isFullyLogged ? <ChaptersFtpPage /> : <RedirectToWelcomePage />)} />
          <Route exact path="/manga/chapters/read" render={() => (isFullyLogged ? <ReaderFtpPage /> : <RedirectToWelcomePage />)} />
        </>
      )}
      <IonLoading cssClass="my-custom-class" isOpen={isLoading || appLoading} message={'Chargement...'} />
      <ToastMessage />
    </>
  );
};

export default RoutingMenuFTP;
