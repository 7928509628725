import { createSlice } from '@reduxjs/toolkit';
import { ChapterReadState } from './types';
import { addChapterRead, addChaptersRead, getUserChaptersRead, removeChaptersRead } from './actions';

export const defaultChapterReadState: ChapterReadState = {
  mangas: [],
};

export const chapterReadSlice = createSlice({
  name: 'chapterRead',
  initialState: defaultChapterReadState,
  reducers: {
    reinitializeChapterRead: state => {
      state.mangas = [];
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserChaptersRead.fulfilled, (state, { payload }) => {
      state.mangas = payload;
    });
    builder.addCase(addChapterRead.fulfilled, (state, { payload }) => {
      const chaptersRead = state.mangas.find(manga => manga._id === payload._id);
      if (chaptersRead) {
        chaptersRead.chapters = payload.chapters;
        return;
      }

      state.mangas.push(payload);
    });
    builder.addCase(addChaptersRead.fulfilled, (state, { payload }) => {
      state.mangas = [...payload];
    });
    builder.addCase(removeChaptersRead.fulfilled, (state, { payload }) => {
      state.mangas = [...payload];
    });
  },
});

export const { reinitializeChapterRead } = chapterReadSlice.actions;

export default chapterReadSlice.reducer;
