import React, { useState } from 'react';
import { IonActionSheet, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import './EditFtpAccessModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { addCircleOutline, closeOutline, createOutline, helpCircleOutline, informationOutline } from 'ionicons/icons';
import { ftpAccessSendType, ftpAccessType } from '../store/app/types';
import { setToastMessage } from '../store/app/appSlice';
import { addFtpAccess, deleteFtpAccessAction, updateFtpAccess } from '../store/app/actions';
import FormFtpAccessComponent from './FormFtpAccessComponent';
import HelpFtpAccessModal from './HelpFtpAccessModal';

interface EditFtpAccessModalTypes {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const EditFtpAccessModal: React.FC<EditFtpAccessModalTypes> = ({ isOpen, setIsOpen }: EditFtpAccessModalTypes) => {
  const dispatch = useDispatch<AppDispatch>();
  const [presentAlert] = useIonAlert();
  const ftpAccess = useSelector((state: RootState) => state.app.ftpAccess);
  const [selectedFtpAccess, setSelectedFtpAccess] = useState<ftpAccessType>();
  const [currentEditFtpAccess, setCurrentEditFtpAccess] = useState<ftpAccessType>();
  const [addNewFtpAccess, setAddNewFtpAccess] = useState<boolean>(false);
  const [modalHelpFtpAccessIsOpen, setModalHelpFtpAccessIsOpen] = useState<boolean>(false);

  const [showActionSheet, setShowActionSheet] = useState(false);

  const deleteFtpAccess = async (): Promise<void> => {
    if (!selectedFtpAccess) {
      return;
    }
    await dispatch(deleteFtpAccessAction({ id: selectedFtpAccess._id }));
    setCurrentEditFtpAccess(undefined);
    setSelectedFtpAccess(undefined);
  };

  const submit = async (data: ftpAccessSendType): Promise<void> => {
    if (currentEditFtpAccess) {
      const response = await dispatch(
        updateFtpAccess({
          ...data,
          id: currentEditFtpAccess._id,
        }),
      );
      if (response.payload) {
        setCurrentEditFtpAccess(undefined);
        setIsOpen(false);
        dispatch(
          setToastMessage({
            text: 'Accès FTP bien mis à jour !',
            isOpen: true,
            icon: informationOutline,
            position: 'top',
          }),
        );
      }
    }
  };

  const submitCreate = async (data: ftpAccessSendType): Promise<void> => {
    const response = await dispatch(addFtpAccess(data));
    if (response.payload) {
      dispatch(
        setToastMessage({
          text: 'Accès FTP bien ajouté !',
          isOpen: true,
          icon: informationOutline,
          position: 'top',
        }),
      );
      setAddNewFtpAccess(false);
    }
  };

  const setIsModalHelpFtpAccessFtpOpen = (value: boolean): void => {
    setModalHelpFtpAccessIsOpen(value);
  };

  return (
    <IonModal className="edit-ftp-access-modal" isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Gestion accès FTP</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setModalHelpFtpAccessIsOpen(true)}>
              <IonIcon icon={helpCircleOutline} />
            </IonButton>
            <IonButton strong={true} onClick={() => setIsOpen(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-item-with-padding">
        {addNewFtpAccess ? (
          <>
            <FormFtpAccessComponent submit={submitCreate} />
            <div className="margin-button">
              <IonButton className="cancel-button" fill="outline" color="primary" onClick={() => setAddNewFtpAccess(false)}>
                Annuler
              </IonButton>
            </div>
          </>
        ) : (
          <>
            {!currentEditFtpAccess ? (
              <>
                <h4>Vos accès FTP</h4>
                {ftpAccess.map(ftp => (
                  <IonItem key={ftp.host}>
                    <IonTitle>{ftp.host}</IonTitle>
                    <IonButton
                      onClick={() => {
                        setSelectedFtpAccess(ftp);
                        setShowActionSheet(true);
                      }}
                      slot="end"
                    >
                      <IonIcon icon={createOutline} />
                    </IonButton>
                  </IonItem>
                ))}
                <div className="ion-text-center" onClick={() => setAddNewFtpAccess(true)}>
                  <IonIcon className="add-icon" icon={addCircleOutline} />
                </div>
              </>
            ) : (
              <>
                <FormFtpAccessComponent submit={submit} ftpAccess={currentEditFtpAccess} />
                <div className="margin-button">
                  <IonButton className="cancel-button" fill="outline" color="primary" onClick={() => setCurrentEditFtpAccess(undefined)}>
                    Annuler
                  </IonButton>
                </div>
              </>
            )}
          </>
        )}

        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[
            {
              text: 'Editer',
              role: 'destructive',
              handler: () => setCurrentEditFtpAccess(selectedFtpAccess),
            },
            {
              text: 'Supprimer',
              role: 'destructive',
              handler: () =>
                presentAlert({
                  header: 'Confirmer la suppression',
                  buttons: [
                    {
                      cssClass: 'alert-button-cancel',
                      text: 'Annuler',
                      role: 'cancel',
                    },
                    {
                      cssClass: 'alert-button-validation',
                      text: 'Confirmer',
                      role: 'confirm',
                      handler: deleteFtpAccess,
                    },
                  ],
                }),
            },
            {
              text: 'Fermer',
              role: 'cancel',
            },
          ]}
        />
      </IonContent>
      <HelpFtpAccessModal isOpen={modalHelpFtpAccessIsOpen} setIsOpen={setIsModalHelpFtpAccessFtpOpen} />
    </IonModal>
  );
};

export default EditFtpAccessModal;
