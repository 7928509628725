import { AnyAction, ListenerEffectAPI, ThunkDispatch } from '@reduxjs/toolkit';
import { setToastMessage } from '../store/app/appSlice';
import { bug } from 'ionicons/icons';
import { Buffer } from 'buffer';
import { manga } from '../store/chaptersRead/types';
import { getScanSourcesListType } from '../store/scanSources/types';
import { Encoding, Filesystem, ReaddirResult, ReadFileResult, WriteFileResult } from '@capacitor/filesystem';
import { ToastMessagePosition } from '../store/app/types';
import { store } from '../store/store';

export const appName = 'Perfect-scans';

export const createToastMessage = (text: string, icon: string, position: ToastMessagePosition, listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>): void => {
  listenerApi.dispatch(
    setToastMessage({
      text: text,
      isOpen: true,
      icon: bug,
      position: position,
    }),
  );
};

/* eslint-disable */
export const naturalCompare = (a: string, b: string) => {
  const ax = [] as any;
  const bx = [] as any;

  // eslint-disable-next-line
  a.replace(/(\d+)|(\D+)/g,
    // eslint-disable-next-line
    function(_, $1, $2): any {
      // eslint-disable-next-line
      const array = [$1 || Infinity, $2 || ""] as any;
      // eslint-disable-next-line
    ax.push(array)
  } );
  // eslint-disable-next-line
  b.replace(/(\d+)|(\D+)/g,
    // eslint-disable-next-line
    function(_, $1, $2): any { bx.push([$1 || Infinity, $2 || ""]) });

  while(ax.length && bx.length) {
    var an = ax.shift();
    var bn = bx.shift();
    var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
    if(nn) return nn;
  }

  return ax.length - bx.length;
}
/* eslint-enable */

export const getChapterPagesSrc = (sourceId: string, chapterPage: string): string => {
  if (chapterPage.startsWith('connector://' + sourceIdException(sourceId))) {
    const payload = decodeURIComponent(chapterPage.split('payload=')[1]);
    const parse = JSON.parse(Buffer.from(payload, 'base64').toString());
    return parse.url;
  } else {
    return chapterPage;
  }
};

const sourceIdException = (sourceId: string): string => {
  if (sourceId === 'manga-origines') {
    return 'mangasorigines';
  }
  if (sourceId === 'x.manga-origines') {
    return 'x.mangasorigines';
  }

  return sourceId;
};

export const chapterRead = (chapterId: string, mangaId: string, mangas: manga[]): boolean => {
  const chapterRead = mangas.find(manga => manga.mangaId === mangaId && manga.chapters.includes(chapterId));
  return !!chapterRead;
};

export const createSearchRequest = (
  sourceId: string,
  page: number,
  title: string | null,
  genre: string | null,
  tag: string | null,
  orderBy: string | null,
  status: string | null,
): getScanSourcesListType => {
  return { id: sourceId, start: page, title: title, genre: genre, tag: tag, orderBy: orderBy, status: status } as getScanSourcesListType;
};

// eslint-disable-next-line
export const getKeyByValue = (object: any, value: string): string | undefined => {
  return Object.keys(object).find(key => object[key] === value);
};

export const createFolderOnAndroid = async (path: string): Promise<void> => {
  const downloadFolder = store.getState().app.downloadFolder;
  return await Filesystem.mkdir({
    path: path,
    directory: downloadFolder,
    recursive: true,
  }).catch(err => {
    console.error(err);
  });
};

export const addChapterInFolderOnAndroid = async (path: string, filename: string, data: string): Promise<WriteFileResult> => {
  const downloadFolder = store.getState().app.downloadFolder;
  return await Filesystem.writeFile({
    path: `${path}/${filename}`,
    data: data,
    directory: downloadFolder,
  });
};

export const addFileInFolderAndroid = async (path: string, filename: string, data: string): Promise<WriteFileResult> => {
  const downloadFolder = store.getState().app.downloadFolder;
  return await Filesystem.writeFile({
    path: `${path}/${filename}`,
    data: data,
    directory: downloadFolder,
    encoding: Encoding.UTF8,
  });
};

export const readFolderContentOnAndroid = async (path: string): Promise<ReaddirResult> => {
  const downloadFolder = store.getState().app.downloadFolder;
  return await Filesystem.readdir({
    path: path,
    directory: downloadFolder,
  });
};

export const readFileContentOnAndroid = async (path: string): Promise<ReadFileResult> => {
  const downloadFolder = store.getState().app.downloadFolder;
  return await Filesystem.readFile({
    path: path,
    directory: downloadFolder,
    encoding: Encoding.UTF8,
  });
};

export const readMediaContentOnAndroid = async (path: string): Promise<ReadFileResult> => {
  const downloadFolder = store.getState().app.downloadFolder;
  return await Filesystem.readFile({
    path: path,
    directory: downloadFolder,
  });
};

export const deleteFolderOnAndroid = async (path: string): Promise<void> => {
  const downloadFolder = store.getState().app.downloadFolder;
  return await Filesystem.rmdir({
    path: path,
    directory: downloadFolder,
    recursive: true,
  });
};

const excludeWords = ['adulte', 'hentai', 'yaoi', 'yuri'];

export const safeSearchWords = (genres: { [key: string]: string }, isAdult: boolean): [string, string][] => {
  return isAdult
    ? Object.entries(genres)
    : Object.entries(genres).filter(([value]) => {
        if (!excludeWords.includes(value.toLowerCase())) {
          return value;
        }
      });
};
