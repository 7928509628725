import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonLoading, IonPage, IonSlide, IonSlides, isPlatform } from '@ionic/react';
import HeaderComponent from '../components/HeaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { useHistory } from 'react-router';
import DisplayPage from '../components/DisplayPage';
import { setHeaderVisible, setTabBarVisible, setToastMessage } from '../store/app/appSlice';

import './ReaderPage.scss';
import { viewerTopBottom } from '../store/app/types';
import { checkmarkCircle, informationCircleOutline } from 'ionicons/icons';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen';
import { addFtpChapterRead, getFtpScansAction, getNextFtpPage } from '../store/ftpManga/actions';
import { resetFtpPages } from '../store/ftpManga/ftpMangaSlice';

interface ViewerTypes {
  pages: string[];
  onClickMedia: () => Promise<void>;
  // eslint-disable-next-line
  loadNextPage: (event: any) => Promise<void>;
}

const ViewerTopBottom: React.FC<ViewerTypes> = ({ pages, onClickMedia, loadNextPage }: ViewerTypes) => {
  return (
    <>
      {pages.map(mediaUrl => (
        <span key={mediaUrl} onClick={() => onClickMedia()} className="container-img">
          <DisplayPage sourceId={'ftp'} mediaUrl={mediaUrl} />
        </span>
      ))}
      <IonInfiniteScroll position="bottom" onIonInfinite={loadNextPage} threshold="600px">
        <IonInfiniteScrollContent loadingSpinner="bubbles" className="loading" loadingText="Chargement..."></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

const ViewerLeft: React.FC<ViewerTypes> = ({ pages, onClickMedia, loadNextPage }: ViewerTypes) => {
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    zoom: true,
    pagination: false,
  };
  return (
    <IonSlides className="ion-slides" pager={true} options={slideOpts} onIonSlideReachEnd={loadNextPage}>
      {pages.map(mediaUrl => (
        <IonSlide key={mediaUrl} onClick={() => onClickMedia()} className="container-img">
          <DisplayPage sourceId={'ftp'} mediaUrl={mediaUrl} />
        </IonSlide>
      ))}
      <IonSlide>
        <div>
          <p>Prochain chapitre</p>
        </div>
      </IonSlide>
    </IonSlides>
  );
};

const ReaderFtpPage: React.FC = () => {
  const history = useHistory();
  // eslint-disable-next-line
  const chapterName = queryString.parse(location.search).chapterName;
  // eslint-disable-next-line
  const [chapterIdLoaded, setChapterIdLoaded] = useState<string>(queryString.parse(location.search).chapterName as string);
  const dispatch = useDispatch<AppDispatch>();
  const pageList = useSelector((state: RootState) => state.ftpManga.scans);
  const chapterList = useSelector((state: RootState) => state.scanSources.chaptersList);
  const headerVisible = useSelector((state: RootState) => state.app.headerVisible);
  const tabBarVisible = useSelector((state: RootState) => state.app.tabBarVisible);
  const [pages, setPages] = useState<string[] | undefined>(undefined);
  const [viewer] = useState<string>(useSelector((state: RootState) => state.app.viewer));
  const connectedFtp = useSelector((state: RootState) => state.app.connectedFtp);

  const mangaName = queryString.parse(location.search).mangaName;
  const selectedManga = useSelector((state: RootState) => state.ftpManga.manga?.find(item => item.mangaName === mangaName));

  useEffect(() => {
    dispatch(setTabBarVisible(false));
    dispatch(setHeaderVisible(false));
    if (chapterIdLoaded && chapterName && typeof chapterName === 'string' && connectedFtp && selectedManga) {
      dispatch(getFtpScansAction({ id: connectedFtp?._id, mangaName: selectedManga.mangaName, chapterName }));
    }

    dispatch(
      setToastMessage({
        text: "Pour faire appraitre le menu, cliquez sur l'image",
        isOpen: true,
        icon: informationCircleOutline,
        position: 'bottom',
      }),
    );

    if (isPlatform('capacitor')) {
      AndroidFullScreen.immersiveMode();
    }

    return () => {
      if (isPlatform('capacitor')) {
        AndroidFullScreen.showSystemUI();
      }
      dispatch(resetFtpPages());
      setPages(undefined);
    };
  }, []);

  useEffect(() => {
    setPages(pageList);
  }, [pageList]);

  useEffect(() => {
    return () => {
      dispatch(setTabBarVisible(true));
      dispatch(setHeaderVisible(true));
    };
  }, []);

  // eslint-disable-next-line
  const loadNextPage = async (event: any): Promise<void> => {
    if (selectedManga && chapterList) {
      if (mangaName && typeof mangaName === 'string' && connectedFtp) {
        await dispatch(addFtpChapterRead({ id: connectedFtp._id, mangaName: selectedManga.mangaName, chapterName: chapterIdLoaded }));
      }
      const currentChapterIndex = selectedManga.chaptersList
        .map(function (e) {
          return e;
        })
        .indexOf(chapterIdLoaded);

      const nextIndex = currentChapterIndex + 1;
      if (selectedManga.chaptersList[nextIndex] !== undefined && connectedFtp) {
        const newChapterIdLoaded = selectedManga.chaptersList[nextIndex];
        await dispatch(getNextFtpPage({ id: connectedFtp._id, mangaName: selectedManga.mangaName, chapterName: selectedManga.chaptersList[nextIndex] }));
        setChapterIdLoaded(newChapterIdLoaded);
      }
    }
    event.target.complete();
  };

  // eslint-disable-next-line
  const loadNextPageLeft = async (): Promise<void> => {
    onClickMedia();
    if (selectedManga && chapterList) {
      if (mangaName && typeof mangaName === 'string' && connectedFtp) {
        await dispatch(addFtpChapterRead({ id: connectedFtp._id, mangaName: selectedManga.mangaName, chapterName: chapterIdLoaded }));
      }
      const currentChapterIndex = selectedManga.chaptersList
        .map(function (e) {
          return e;
        })
        .indexOf(chapterIdLoaded);

      const nextIndex = currentChapterIndex + 1;
      if (selectedManga.chaptersList[nextIndex] !== undefined && connectedFtp) {
        const newChapterIdLoaded = selectedManga.chaptersList[nextIndex];
        await history.push(`?chapterName=${newChapterIdLoaded}&mangaName=${mangaName}`);
        window.location.reload();
      } else {
        dispatch(
          setToastMessage({
            text: "Pas d'autres chapitres",
            isOpen: true,
            icon: checkmarkCircle,
            position: 'top',
          }),
        );
      }
    }
  };

  const onClickMedia = async (): Promise<void> => {
    if (isPlatform('capacitor')) {
      if (tabBarVisible) {
        AndroidFullScreen.immersiveMode();
      } else {
        AndroidFullScreen.showSystemUI();
      }
    }
    dispatch(setTabBarVisible(!tabBarVisible));
    dispatch(setHeaderVisible(!headerVisible));
  };

  return (
    <IonPage className="reader">
      <HeaderComponent title={selectedManga?.chaptersList?.find(chapter => chapter === chapterIdLoaded) ?? ''} />
      <IonContent fullscreen className="bg-reader">
        {pages && pages.length !== 0 ? (
          <>
            {viewer === viewerTopBottom ? (
              <ViewerTopBottom pages={pages} onClickMedia={onClickMedia} loadNextPage={loadNextPage} />
            ) : (
              <ViewerLeft pages={pages} onClickMedia={onClickMedia} loadNextPage={loadNextPageLeft} />
            )}
          </>
        ) : (
          <IonLoading cssClass="my-custom-class" isOpen={true} message={'Chargement...'} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default ReaderFtpPage;
