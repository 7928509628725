import React, { useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './UpdateInfosModal.scss';
import { closeOutline } from 'ionicons/icons';

const UpdateInfosModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(!(localStorage.getItem('updateInfos') && localStorage.getItem('updateInfos') === process.env.REACT_APP_VERSION));
  const onCLoseModal = async (): Promise<void> => {
    localStorage.setItem('updateInfos', process.env.REACT_APP_VERSION as string);
    setIsOpen(false);
  };

  return (
    <IonModal className="update-infos-modal" isOpen={isOpen} onDidDismiss={onCLoseModal}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Note de mise à jour</IonTitle>
          <IonButtons slot="end">
            <IonButtons slot="end">
              <IonButton strong={true} onClick={onCLoseModal}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h1>Version 3.1.1</h1>
        <ul>
          <li>
            {
              // eslint-disable-next-line max-len
              'Optimisation du chargement des images sur les sources Izoka et izoka Hentai'
            }
          </li>
          <li>
            {
              // eslint-disable-next-line max-len
              'Suprresion de la mise à jour auto des favoris. Tirer vers le base pour la mise à jour manuel'
            }
          </li>
        </ul>
        <h1>Version 3</h1>
        <ul>
          <li>
            {
              // eslint-disable-next-line max-len
              'Ajout de sources personalisé : Izoka et Izoka Hentai.'
            }
          </li>
          <li>{'Retour de la source ReaperScans !!!'}</li>
        </ul>
        <h1>Version 2.2.1</h1>
        <ul>
          <li>
            {
              // eslint-disable-next-line max-len
              "Ajout de la fonction speedMode. Cela permet de grandement accelerer l'affichage de la liste des manga lors de la recherche. En contre parti, vous n'avez pas acc-s au nombres de chapitres disponible dans la recherche"
            }
          </li>
          <li>{'Retour de la source ReaperScans !!!'}</li>
        </ul>
        <h1>Version 2.2.0</h1>
        <ul>
          <li>{'Retrait de la source ReaperScans :/'}</li>
          <li>{'Fix de bugs mineurs'}</li>
        </ul>
        <h1>Version 2.1.9</h1>
        <ul>
          <li>{"Fix d'un beug sur la mise à jour des favoris"}</li>
        </ul>
        <h1>Version 2.1.8</h1>
        <ul>
          <li>{"Fix d'un beug d'affichage sur la liste des sources"}</li>
        </ul>
        <h1>Version 2.1.7</h1>
        <ul>
          <li>{'Ajout de sources : MangaFr - LelManga - GedeComix'}</li>
          <li>{"Ajout d'un message informant si un manga est déjà en favoris sur la liste des mangas des sources"}</li>
          <li>{"Fix d'un beug lors de la récuperation de la liste des notifications des favoris"}</li>
        </ul>
        <h1>Version 2.1.6</h1>
        <ul>
          <li>{"Fix d'un beug lors de l'édition des favoris"}</li>
        </ul>
        <h1>Version 2.1.5</h1>
        <ul>
          <li>{"Ajout au lancement de l'app une popup d'info d'une nouvelle mise à jour disponible"}</li>
          <li>Retrait des sources mangaorigines/xmangaorigines (down :/)</li>
          <li>{"Ajout d'une alerte pour informer que le manga dans vos favoris n'est plus disponible"}</li>
        </ul>
        <h1>Version 2.1.4</h1>
        <ul>
          <li>Optimisation de la mise à jour des notifications</li>
        </ul>
        <h1>Version 2.1.2</h1>
        <ul>
          <li>Optimisation de la mise à jour des favoris</li>
          <li>{"Limitation de téléchargement des chapitres à 10 pour éviter une surcharge du serveur et éviter de faire planter l'app"}</li>
          <li>{"Correction d'un beug lors de la reprise d'une lecture en cours"}</li>
        </ul>
        <h1>Version 2.0.0</h1>
        <ul>
          <li>{"Ajout du mode FTP. Cela permet de se connecter à un serveur FTP (nas par exemple) et lire vos manga directement dans l'app"}</li>
          <li>{'[En test interne] Possibilité de télécharger les mangas sur son serveur FTP'}</li>
          <li>{'Refonte visuel diverses'}</li>
        </ul>
        <h1>Version 1.2.1</h1>
        <ul>
          <li>{'Ajout du choix du dossier pour les télécharements de manga sur android'}</li>
        </ul>
        <h1>Version 1.2.0</h1>
        <ul>
          <li>{"Ajout du choix d'affichage des mangas par cover ou par liste"}</li>
          <li>{"Refonte visuel de l'édition des favoris"}</li>
          <li>{'Déplacement du bouton raffraichir les favoris dans le menu'}</li>
          <li>{'Refonte visuel diverses'}</li>
        </ul>
        <h1>Version 1.1.0</h1>
        <ul>
          <li>{"Ajout de la modal d'infos de mise à jour"}</li>
          <li>{"Ajout de l'option mot de passe oublié"}</li>
          <li>{'Ajout du centre de notifications qui vous informe des derniers chapitres disponibles par rapport à vos favoris'}</li>
          <li>Lorsque vous cliquez sur une image dans vos favoris et si vous avez déjà lu un chapitre, vous pouvez directement choisir de continuer la lecture</li>
          <li>Suppression temporaire de la recherche sur toutes les sources en même temps. Ayant pris un petit serveur, la charge est trop lourde et le fait crash</li>
          <li>Correction des modals qui ne se fermaient pas au clique retour sur android</li>
          <li>{"Correction d'un beug lors de l'ajout en favoris/création d'un tag"}</li>
          <li>{"Correction d'un beug qui ne gardait pas en mémoire le choix du thème et du lecteur"}</li>
          <li>Optimisation des performances</li>
          <li>Quelques refontes visuel</li>
        </ul>
      </IonContent>
    </IonModal>
  );
};

export default UpdateInfosModal;
