import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonLoading, IonPage, IonSlide, IonSlides, isPlatform } from '@ionic/react';
import HeaderComponent from '../components/HeaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { getChaptersList, getNextPage, getPagesList } from '../store/scanSources/actions';
import { useHistory, useParams } from 'react-router';
import DisplayPage from '../components/DisplayPage';
import { setHeaderVisible, setTabBarVisible, setToastMessage } from '../store/app/appSlice';

import './ReaderPage.scss';
import { addChapterRead } from '../store/chaptersRead/actions';
import { resetPages } from '../store/scanSources/ScanSourcesSlice';
import { viewerTopBottom } from '../store/app/types';
import { checkmarkCircle, informationCircleOutline } from 'ionicons/icons';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen';

interface ParamTypes {
  sourceId: string;
}

interface ViewerTypes {
  pages: string[][];
  onClickMedia: () => Promise<void>;
  sourceId: string;
  // eslint-disable-next-line
  loadNextPage: (event: any) => Promise<void>;
}

const ViewerTopBottom: React.FC<ViewerTypes> = ({ pages, onClickMedia, sourceId, loadNextPage }: ViewerTypes) => {
  return (
    <>
      {pages.map(page =>
        page.map(mediaUrl => (
          <span key={mediaUrl} onClick={() => onClickMedia()} className="container-img">
            <DisplayPage sourceId={sourceId} mediaUrl={mediaUrl} />
          </span>
        )),
      )}
      <IonInfiniteScroll position="bottom" onIonInfinite={loadNextPage} threshold="600px">
        <IonInfiniteScrollContent loadingSpinner="bubbles" className="loading" loadingText="Chargement..."></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

const ViewerLeft: React.FC<ViewerTypes> = ({ pages, onClickMedia, sourceId, loadNextPage }: ViewerTypes) => {
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    zoom: true,
    pagination: false,
  };
  return (
    <IonSlides className="ion-slides" pager={true} options={slideOpts} onIonSlideReachEnd={loadNextPage}>
      {pages.map(page =>
        page.map(mediaUrl => (
          <IonSlide key={mediaUrl} onClick={() => onClickMedia()} className="container-img">
            <DisplayPage sourceId={sourceId} mediaUrl={mediaUrl} />
          </IonSlide>
        )),
      )}
      <IonSlide>
        <div>
          <p>Prochain chapitre</p>
        </div>
      </IonSlide>
    </IonSlides>
  );
};

const ReaderPage: React.FC = () => {
  const history = useHistory();
  const { sourceId } = useParams<ParamTypes>();
  // eslint-disable-next-line
  const mangaId = queryString.parse(location.search).mangaId;
  // eslint-disable-next-line
  const [chapterIdLoaded, setChapterIdLoaded] = useState<string>(queryString.parse(location.search).chaptersId as string);
  // eslint-disable-next-line
  const [chaptersLoaded, setChaptersLoaded] = useState<string[]>([queryString.parse(location.search).chaptersId as string]);
  const dispatch = useDispatch<AppDispatch>();
  const pageList = useSelector((state: RootState) => state.scanSources.pageList);
  const selectedManga = useSelector((state: RootState) => state.scanSources.chaptersList?.find(item => item.mangaId === mangaId));
  const chapterList = useSelector((state: RootState) => state.scanSources.chaptersList);
  const headerVisible = useSelector((state: RootState) => state.app.headerVisible);
  const tabBarVisible = useSelector((state: RootState) => state.app.tabBarVisible);
  const [pages, setPages] = useState<string[][] | undefined>(undefined);
  const [viewer] = useState<string>(useSelector((state: RootState) => state.app.viewer));

  useEffect(() => {
    dispatch(setTabBarVisible(false));
    dispatch(setHeaderVisible(false));
    if (chapterIdLoaded && mangaId && typeof mangaId === 'string') {
      if (!selectedManga) {
        dispatch(getChaptersList({ sourceId, mangaId }));
      }
      dispatch(getPagesList({ sourceId, mangaId, chaptersId: chapterIdLoaded }));
    }

    dispatch(
      setToastMessage({
        text: "Pour faire appraitre le menu, cliquez sur l'image",
        isOpen: true,
        icon: informationCircleOutline,
        position: 'bottom',
      }),
    );

    if (isPlatform('capacitor')) {
      AndroidFullScreen.immersiveMode();
    }

    return () => {
      if (isPlatform('capacitor')) {
        AndroidFullScreen.showSystemUI();
      }
      dispatch(resetPages());
      setPages(undefined);
    };
  }, []);

  useEffect(() => {
    setPages(pageList);
  }, [pageList]);

  useEffect(() => {
    return () => {
      dispatch(setTabBarVisible(true));
      dispatch(setHeaderVisible(true));
    };
  }, []);

  // eslint-disable-next-line
  const loadNextPage = async (event: any): Promise<void> => {
    if (selectedManga && chapterList) {
      if (mangaId && typeof mangaId === 'string') {
        await dispatch(addChapterRead({ mangaId: mangaId, source: sourceId, chapterId: chapterIdLoaded }));
      }
      const currentChapterIndex = selectedManga.chapters
        .map(function (e) {
          return e.id;
        })
        .indexOf(chapterIdLoaded);

      const nextIndex = currentChapterIndex - 1;
      if (selectedManga.chapters[nextIndex] !== undefined && !chaptersLoaded.includes(selectedManga.chapters[nextIndex].id)) {
        const newChapterIdLoaded = selectedManga.chapters[nextIndex].id;
        const mangaIdString = mangaId as string;
        await dispatch(getNextPage({ sourceId, mangaId: mangaIdString, chaptersId: selectedManga.chapters[nextIndex].id }));
        setChapterIdLoaded(newChapterIdLoaded);
        setChaptersLoaded(chaptersLoaded => [...chaptersLoaded, newChapterIdLoaded]);
      }
    }
    event.target.complete();
  };

  // eslint-disable-next-line
  const loadNextPageLeft = async (): Promise<void> => {
    onClickMedia();
    if (selectedManga && chapterList) {
      if (mangaId && typeof mangaId === 'string') {
        await dispatch(addChapterRead({ mangaId: mangaId, source: sourceId, chapterId: chapterIdLoaded }));
      }
      const currentChapterIndex = selectedManga.chapters
        .map(function (e) {
          return e.id;
        })
        .indexOf(chapterIdLoaded);
      const nextIndex = currentChapterIndex - 1;
      if (selectedManga.chapters[nextIndex] !== undefined && !chaptersLoaded.includes(selectedManga.chapters[nextIndex].id)) {
        const newChapterIdLoaded = selectedManga.chapters[nextIndex].id;
        await history.push(`?chaptersId=${newChapterIdLoaded}&mangaId=${mangaId}`);
        window.location.reload();
      } else {
        dispatch(
          setToastMessage({
            text: "Pas d'autres chapitres",
            isOpen: true,
            icon: checkmarkCircle,
            position: 'top',
          }),
        );
      }
    }
  };

  const onClickMedia = async (): Promise<void> => {
    if (isPlatform('capacitor')) {
      if (tabBarVisible) {
        AndroidFullScreen.immersiveMode();
      } else {
        AndroidFullScreen.showSystemUI();
      }
    }
    dispatch(setTabBarVisible(!tabBarVisible));
    dispatch(setHeaderVisible(!headerVisible));
  };

  return (
    <IonPage className="reader">
      <HeaderComponent title={selectedManga?.chapters?.find(chapter => chapter.id === chapterIdLoaded)?.title ?? ''} />
      <IonContent fullscreen className="bg-reader">
        {pages && pages.length !== 0 ? (
          <>
            {viewer === viewerTopBottom ? (
              <ViewerTopBottom pages={pages} onClickMedia={onClickMedia} sourceId={sourceId} loadNextPage={loadNextPage} />
            ) : (
              <ViewerLeft pages={pages} onClickMedia={onClickMedia} sourceId={sourceId} loadNextPage={loadNextPageLeft} />
            )}
          </>
        ) : (
          <IonLoading cssClass="my-custom-class" isOpen={true} message={'Chargement...'} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default ReaderPage;
