import { createSlice } from '@reduxjs/toolkit';
import { scanSourcesState } from './types';
import { getChaptersList, getDownloadChapters, getNextPage, getPagesList, getScanSourcesList, getSources, searchAllSources } from './actions';

export const defaultScanSourcesState: scanSourcesState = {
  sources: undefined,
  sourceList: [],
  chaptersList: [],
  pageList: [],
  searchInSources: {},
  downloadManga: [],
};

const scanSourcesSlice = createSlice({
  name: 'scanSources',
  initialState: defaultScanSourcesState,
  reducers: {
    resetSourceList: (state, { payload }) => {
      const scan = state.sourceList?.find(item => item.source === payload);
      if (scan) {
        scan.mangaList = [];
      }
    },
    resetPages: state => {
      state.pageList = undefined;
    },
    resetSearchInSources: state => {
      state.searchInSources = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(getSources.fulfilled, (state, { payload }) => {
      state.sources = payload;
    });
    builder.addCase(searchAllSources.fulfilled, (state, { payload }) => {
      state.searchInSources = { ...state.searchInSources, [payload.source]: payload.mangaList };
      return state;
    });
    builder.addCase(getScanSourcesList.fulfilled, (state, { payload }) => {
      if (!state.sourceList) {
        state.sourceList = [payload];
        return;
      }

      const scan = state.sourceList?.find(item => item.source === payload.source);
      if (scan) {
        scan.mangaList = [...scan.mangaList, ...payload.mangaList];
        scan.page = payload.page;
        scan.searchTitle = payload.searchTitle;
        scan.searchGenre = payload.searchGenre;
        scan.searchTag = payload.searchTag;
        scan.searchOrderBy = payload.searchOrderBy;
        scan.searchStatus = payload.searchStatus;
      } else {
        state.sourceList.push(payload);
      }
    });
    builder.addCase(getChaptersList.fulfilled, (state, { payload }) => {
      if (!state.chaptersList) {
        state.chaptersList = [payload];
        return state;
      }

      const manga = state.chaptersList?.find(item => item.mangaId === payload.mangaId);
      if (manga) {
        manga.chapters = payload.chapters;
      } else {
        state.chaptersList.push(payload);
      }
      return state;
    });
    builder.addCase(getPagesList.fulfilled, (state, { payload }) => {
      state.pageList = [payload];
    });
    builder.addCase(getNextPage.fulfilled, (state, { payload }) => {
      state.pageList?.push(payload);
    });
    builder.addCase(getDownloadChapters.fulfilled, (state, { payload }) => {
      state.downloadManga.push(payload);
    });
  },
});

export const { resetSourceList, resetPages, resetSearchInSources } = scanSourcesSlice.actions;

export default scanSourcesSlice.reducer;
