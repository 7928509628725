import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import {
  IonContent,
  IonItem,
  IonPage,
  IonCard,
  IonCardContent,
  IonLoading,
  IonButton,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonProgressBar,
  isPlatform,
  IonLabel,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { getChaptersList, getDownloadChapters } from '../store/scanSources/actions';
import { useLocation, useParams } from 'react-router';
import { chapters, mangaChaptersList } from '../store/scanSources/types';

import './ChaptersPage.scss';
import { checkmarkCircle, ellipsisHorizontalOutline, filterOutline, informationOutline } from 'ionicons/icons';
import { removeFavorite } from '../store/favorites/actions';
import { addChapterInFolderOnAndroid, addFileInFolderAndroid, appName, createFolderOnAndroid, naturalCompare, readFileContentOnAndroid } from '../utils/helpers';
import { setToastMessage } from '../store/app/appSlice';
import TagsModal from '../components/TagsModal';
import { addChaptersRead, removeChaptersRead } from '../store/chaptersRead/actions';
import ChapterListComponent from '../components/ChapterListComponent';
import MarkAsReadBarComponent from '../components/MarkAsReadBarComponent';
import DownloadBarComponent from '../components/DownloadBarComponent';
import ChapterMenuComponent from '../components/ChapterMenuComponent';
import PopoverComponent, { textsPopover } from '../components/PopoverComponent';
import { ftpAccessType } from '../store/app/types';
import { uploadChaptersFtpAction } from '../store/ftpManga/actions';

interface ParamTypes {
  sourceId: string;
}

const initialState = [] as string[];

const ChaptersPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const chaptersList = useSelector((state: RootState) => state.scanSources.chaptersList);
  const theme = useSelector((state: RootState) => state.app.theme);
  const favorites = useSelector((state: RootState) => state.favorites.favorites);
  const chaptersRead = useSelector((state: RootState) => state.chapterRead.mangas);
  const { sourceId } = useParams<ParamTypes>();
  const location = useLocation();
  const mangaId = queryString.parse(location.search).mangaId;
  const [selectedManga, setSelectedManga] = useState<mangaChaptersList | undefined>(undefined);
  const [showBar, setShowBar] = useState<boolean>(false);
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const [markAsReadInProgress, setMarkAsReadInProgress] = useState<boolean>(false);
  const [currentNumberDownload, setCurrentNumberDownload] = useState<number>(1);
  const [downloadList, setDownloadList] = useState<string[]>(initialState);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [modalTagsIsOpen, setModalTagsIsOpen] = useState<boolean>(false);
  const [markAsReadIsActive, setMarkAsReadIsActive] = useState<boolean>(false);
  const [reverseOrderChapter, setReverseOrderChapter] = useState<boolean>(true);
  const [chapters, setChapters] = useState<chapters[]>([]);
  const [selectedFtpAccessForUpload, setSelectedFtpAccessForUpload] = useState<ftpAccessType>();

  useEffect(() => {
    if (mangaId && typeof mangaId === 'string') {
      if (chaptersList && chaptersList.find(item => item.mangaId === mangaId)) {
        setSelectedManga(chaptersList.find(item => item.mangaId === mangaId));
        return;
      }

      dispatch(getChaptersList({ sourceId, mangaId }));
    }
  }, []);

  useEffect(() => {
    if (chaptersList) {
      setSelectedManga(chaptersList.find(item => item.mangaId === mangaId));
    }
  }, [chaptersList]);

  useEffect(() => {
    if (selectedManga) {
      if (!reverseOrderChapter) {
        return setChapters(selectedManga.chapters);
      }

      setChapters(
        [...selectedManga.chapters]
          .slice()
          // eslint-disable-next-line
          .sort((a: any, b: any) => b.title - a.title)
          .reverse(),
      );
    }
  }, [reverseOrderChapter, selectedManga]);

  const updateNumberDownload = (number: number): Promise<void> => {
    return new Promise(resolve => {
      setCurrentNumberDownload(number);
      return resolve();
    });
  };

  const removeToFavorite = (mangaId: string): void => {
    const favorite = favorites.find(item => item.mangaId === mangaId);
    if (!favorite) {
      return;
    }

    dispatch(removeFavorite(favorite._id));
  };

  const activeDownload = (): void => {
    setShowBar(!showBar);
  };

  const updateShowBar = (): void => {
    setMarkAsReadIsActive(!markAsReadIsActive);
  };

  const updateFtpAccessForUpload = (ftpAccess: ftpAccessType): void => {
    setSelectedFtpAccessForUpload(ftpAccess);
  };

  const updateDownloadList = async (id: string): Promise<void> => {
    if (!downloadList.some(item => item === id)) {
      if (showBar) {
        if (downloadList.length < 10) {
          setDownloadList([...downloadList, id]);
        }
      } else {
        setDownloadList([...downloadList, id]);
      }
    } else {
      const array = downloadList.filter(item => {
        return item !== id;
      });
      setDownloadList(array);
    }
  };

  const selectAll = async (): Promise<void> => {
    if (selectedManga) {
      const array = selectedManga.chapters.map(chapter => {
        return chapter.id;
      });
      setDownloadList(array);
    }
  };

  const deselectAll = async (): Promise<void> => {
    setDownloadList(initialState);
  };

  const markAsRead = async (isRead: boolean): Promise<void> => {
    if (mangaId && typeof mangaId === 'string') {
      setMarkAsReadInProgress(true);
      setMarkAsReadIsActive(false);
      if (isRead) {
        await dispatch(
          addChaptersRead({
            mangaId: mangaId,
            source: sourceId,
            chapters: downloadList,
          }),
        );
      } else {
        await dispatch(
          removeChaptersRead({
            mangaId: mangaId,
            source: sourceId,
            chapters: downloadList,
          }),
        );
      }

      setDownloadList(initialState);
      setMarkAsReadInProgress(false);
    }
  };

  const downloadOnFtp = async (): Promise<void> => {
    if (!selectedFtpAccessForUpload) {
      return;
    }
    setShowBar(false);
    setDownloadInProgress(true);
    for (let i = 0; i < downloadList.length; i++) {
      await updateNumberDownload(i + 1);
      if (mangaId && typeof mangaId === 'string') {
        await dispatch(uploadChaptersFtpAction({ chaptersId: downloadList[i], mangaId: mangaId, sourceId: sourceId, id: selectedFtpAccessForUpload._id }));
      }
    }
    setDownloadInProgress(false);
    setDownloadList([]);
    dispatch(
      setToastMessage({
        text: 'Téléchargement terminé',
        isOpen: true,
        icon: checkmarkCircle,
        position: 'bottom',
      }),
    );
  };

  const download = async (): Promise<void> => {
    if (selectedFtpAccessForUpload) {
      await downloadOnFtp();
      return;
    }
    await updateNumberDownload(1);
    setShowBar(false);
    setDownloadInProgress(true);
    const chaptersName = [];
    const mangaName = selectedManga?.mangaName
      .replace(/[&/\\#,+()$~%'":*<>_{}]/g, '_')
      .replace(/[\r\n]+/gm, '-')
      .replaceAll(' ', '-');
    for (let i = 0; i < downloadList.length; i++) {
      await updateNumberDownload(i + 1);
      if (mangaId && typeof mangaId === 'string') {
        const data = await dispatch(getDownloadChapters({ chaptersId: downloadList[i], mangaId: mangaId, sourceId: sourceId }));
        const chapterName = selectedManga?.chapters
          .find(item => decodeURI(item.id) === data.payload.chapterId)
          ?.title.replace(/[&/\\#,+()$~%'":_*<>{}]/g, '-')
          .replace(/[\r\n]+/gm, '-')
          .replaceAll(' ', '-')
          .replaceAll(' ', '-');
        chaptersName.push(chapterName);
        const folderPath = `${appName}/${sourceId}/${mangaName}/${chapterName}`;
        try {
          await createFolderOnAndroid(folderPath);
        } catch (e) {
          console.error(e);
        }

        //addChaptersscan
        for (let j = 0; j < data.payload.chapterScans.length; j++) {
          try {
            await addChapterInFolderOnAndroid(
              folderPath,
              data.payload.chapterScans[j].filename
                .replace(/[&/\\#,+()$~%'":*<>_{}]/g, '-')
                .replaceAll(' ', '-')
                .replace(/[\r\n]+/gm, '-'),
              `data:image/gif;base64,${data.payload.chapterScans[j].imgBase64}`,
            ).then(() => new Promise(resolve => setTimeout(resolve, 1000)));
          } catch (e) {
            console.error(e);
          }
        }
      }
    }

    //create or update json infos
    try {
      const mangaInfosFile = await readFileContentOnAndroid(`${appName}/${sourceId}/${mangaName}/index.json`);
      if (!mangaInfosFile.data) {
        await addFileInFolderAndroid(`${appName}/${sourceId}/${mangaName}`, 'index.json', JSON.stringify({ mangaName: mangaName, sourceId: sourceId, chapters: chaptersName, chaptersRead: [] }));
      } else {
        const jsonMangaInfosFile = JSON.parse(mangaInfosFile.data);
        jsonMangaInfosFile.chapters = [...jsonMangaInfosFile.chapters, ...chaptersName];
        await addFileInFolderAndroid(`${appName}/${sourceId}/${mangaName}`, 'index.json', JSON.stringify(jsonMangaInfosFile));
      }
    } catch (e) {
      try {
        await addFileInFolderAndroid(
          `${appName}/${sourceId}/${mangaName}`,
          'index.json',
          JSON.stringify({
            mangaName: mangaName,
            sourceId: sourceId,
            chapters: chaptersName,
            chaptersRead: [],
          }),
        );
      } catch (e) {
        console.error(e);
      }
      console.error(e);
    }

    setDownloadInProgress(false);
    setDownloadList([]);
    dispatch(
      setToastMessage({
        text: 'Téléchargement terminé',
        isOpen: true,
        icon: checkmarkCircle,
        position: 'bottom',
      }),
    );
  };

  const setIsModalTagsOpen = (value: boolean): void => {
    setModalTagsIsOpen(value);
  };

  const getNextChapterToRead = (): string | undefined => {
    if (!selectedManga) {
      return;
    }
    const manga = chaptersRead.find(manga => manga.mangaId === selectedManga.mangaId && manga.source === selectedManga.source);
    if (manga && selectedManga.chapters.length - manga.chapters.length > 0 && selectedManga.chapters.length !== manga.chapters.length) {
      const chapters = [...selectedManga.chapters]
        .slice()
        // eslint-disable-next-line
        .sort((a: any, b: any) => b.title - a.title)
        .reverse();
      const mangaChaptersOrdered = [...manga.chapters].sort(naturalCompare);
      const index = chapters.findIndex((chapter: chapters) => chapter && chapter.id === mangaChaptersOrdered[manga.chapters.length - 1]);

      if (chapters[index + 1] !== undefined) {
        return `/sources/${manga.source}/chapters?chaptersId=${chapters[index + 1].id}&mangaId=${manga.mangaId}`;
      }
    }
  };

  const displayCover = (cover: string): string => {
    if (!cover) {
      return 'assets/img/none.jpg';
    }

    return cover;
  };

  return (
    <IonPage className="chapters-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Chapitres</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton id="click-trigger-chapters">
              <IonIcon icon={informationOutline} />
            </IonButton>
            <IonButton onClick={() => setShowActionSheet(true)} expand="block">
              <IonIcon icon={ellipsisHorizontalOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {selectedManga ? (
          <>
            <div
              className="cover-background"
              style={{
                background:
                  theme === 'dark'
                    ? `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1)), url(${selectedManga.cover ? selectedManga.cover : 'assets/img/none.jpg'})`
                    : `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 1)), url(${selectedManga.cover ? selectedManga.cover : 'assets/img/none.jpg'})`,
              }}
            >
              <div className="container-img">
                <img className="img-card" src={displayCover(selectedManga.cover)} />
                <h1>{selectedManga.mangaName}</h1>
                <IonLabel>Chapitres - {chapters.length}</IonLabel>
              </div>
            </div>
            {getNextChapterToRead() && (
              <div className="ion-text-center">
                <IonButton fill="outline" routerLink={getNextChapterToRead()} color="primary">
                  Reprendre
                </IonButton>
              </div>
            )}
            <IonCard>
              <IonCardContent>
                {selectedManga.description && (
                  <>
                    <h1>Description</h1>
                    <p className="description">{selectedManga.description}</p>
                    <br />
                  </>
                )}
                <h1>Source</h1>
                <p className="mb-15">{sourceId}</p>
                {selectedManga.genres.length !== 0 && (
                  <>
                    <h1>Genre(s)</h1>
                    <p className="mb-15">{selectedManga.genres.join(', ')}</p>
                  </>
                )}
              </IonCardContent>
            </IonCard>
            <span className="container-chapters ">
              <span className="span-chapters">
                <IonItem class="chapter">
                  <IonIcon icon={filterOutline} className={!reverseOrderChapter ? 'rotate' : ''} slot="end" onClick={() => setReverseOrderChapter(!reverseOrderChapter)} />
                </IonItem>
              </span>
              {chapters.map(chapter => (
                <span key={chapter.id} className="span-chapters">
                  <ChapterListComponent
                    downloadList={downloadList}
                    chapter={chapter}
                    sourceId={sourceId}
                    mangaId={mangaId as string}
                    showBar={showBar}
                    markAsReadIsActive={markAsReadIsActive}
                    updateDownloadList={updateDownloadList}
                  />
                </span>
              ))}
              {(showBar || markAsReadIsActive) && <IonItem />}
            </span>
            {markAsReadIsActive && <MarkAsReadBarComponent markAsReadList={downloadList} deselectAll={deselectAll} selectAll={selectAll} markAsRead={markAsRead} setShowBar={updateShowBar} />}
            {showBar && <DownloadBarComponent downloadList={downloadList} deselectAll={deselectAll} download={download} setShowBar={setShowBar} />}
          </>
        ) : (
          <IonLoading cssClass="my-custom-class" isOpen={true} message={'Chargement...'} />
        )}
        <IonLoading cssClass="my-custom-class" isOpen={downloadInProgress || markAsReadInProgress} message={downloadInProgress ? `Téléchargement en cours...` : 'Chargement...'} />
        {downloadInProgress && (
          <div className="progress-bar">
            <IonProgressBar value={currentNumberDownload / downloadList.length} />
          </div>
        )}
        <ChapterMenuComponent
          setShowActionSheet={setShowActionSheet}
          setModalTagsIsOpen={setModalTagsIsOpen}
          setMarkAsReadIsActive={setMarkAsReadIsActive}
          activeDownload={activeDownload}
          showActionSheet={showActionSheet}
          markAsReadIsActive={markAsReadIsActive}
          removeToFavorite={removeToFavorite}
          selectedManga={selectedManga}
          favorites={favorites}
          updateFtpAccessForUpload={updateFtpAccessForUpload}
        />
        <TagsModal setIsOpen={setIsModalTagsOpen} manga={selectedManga} isOpen={modalTagsIsOpen} />
        <PopoverComponent text={isPlatform('capacitor') ? textsPopover.chaptersPageAndroid : textsPopover.chaptersPage} trigger="click-trigger-chapters" />
      </IonContent>
    </IonPage>
  );
};

export default ChaptersPage;
