import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { checkPasswordLost, LoginUser, RegisterUser, updatePasswordLost } from '../users/types';
import { getRequestApi, getRequestApiWithAuth, postRequestApi, postRequestApiWithAuth } from '../axiosApiRequest';
import { errorType, ftpAccessSendType, ftpAccessUpdateType } from './types';

export const logoutAction = createAsyncThunk('app/logout', async () => {
  try {
    return await getRequestApiWithAuth('/auth/logout');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getAppProdVersion = createAsyncThunk('app/getAppProdVersion', async () => {
  try {
    return await getRequestApi('/app/version');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const registerUser = createAsyncThunk('app/registerUser', async (data: RegisterUser) => {
  try {
    return await postRequestApi('/users/signup', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const login = createAsyncThunk('app/login', async (data: LoginUser) => {
  try {
    return await postRequestApi('/auth/signin', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getCurrentUser = createAsyncThunk('app/getCurrentUser', async () => {
  try {
    return await getRequestApiWithAuth('/users/me');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const deleteUser = createAsyncThunk('app/deleteUser', async () => {
  try {
    return await postRequestApiWithAuth('/users/delete', {});
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const refreshToken = createAsyncThunk('app/refreshToken', async () => {
  try {
    const token = localStorage.getItem('token');
    return await postRequestApiWithAuth('/auth/refresh_token', { token: token });
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const passwordLostAction = createAsyncThunk('app/passwordLost', async (email: string) => {
  try {
    return await postRequestApi('/users/password-lost', { email });
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const checkPasswordLostAction = createAsyncThunk('app/checkPasswordLost', async (data: checkPasswordLost) => {
  try {
    return await postRequestApi('/users/password-lost/check', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const updatePasswordLostAction = createAsyncThunk('app/updatePasswordLost', async (data: updatePasswordLost) => {
  try {
    return await postRequestApi('/users/password-lost/update', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getFtpAccess = createAsyncThunk('app/getFtpAccess', async () => {
  try {
    return await getRequestApiWithAuth('/ftp/me');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const addFtpAccess = createAsyncThunk('app/addFtpAccess', async (data: ftpAccessSendType) => {
  try {
    return await postRequestApiWithAuth('/ftp/add', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const updateSpeedModeAction = createAsyncThunk('app/updateSpeedMode', async (data: { speedMode: boolean }) => {
  try {
    return await postRequestApiWithAuth('/users/update/speed-mode', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const updateFtpAccess = createAsyncThunk('app/updateFtpAccess', async (data: ftpAccessUpdateType) => {
  try {
    return await postRequestApiWithAuth('/ftp/update', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const deleteFtpAccessAction = createAsyncThunk('app/deleteFtpAccessAction', async (data: { id: string }) => {
  try {
    return await postRequestApiWithAuth('/ftp/delete', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});
