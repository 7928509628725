import React from 'react';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { appsOutline, heartCircleOutline, personCircleOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import RoutingMenu from '../RoutingMenu';

const TabBar: React.FC = () => {
  const tabBarVisible = useSelector((state: RootState) => state.app.tabBarVisible);
  const isFullyLogged = useSelector((state: RootState) => state.app.isFullyLogged);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <RoutingMenu />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" style={{ display: tabBarVisible ? 'inherit' : 'none' }}>
        <IonTabButton className="tab-bar-button-footer" tab="favorites" href="/favorites">
          <IonIcon icon={heartCircleOutline} />
          <IonLabel>Favoris</IonLabel>
        </IonTabButton>
        <IonTabButton className="tab-bar-button-footer" tab="sources" href="/sources">
          <IonIcon icon={appsOutline} />
          <IonLabel>Sources</IonLabel>
        </IonTabButton>
        <IonTabButton className="tab-bar-button-footer" tab="account" href={isFullyLogged ? '/account' : '/'}>
          <IonIcon icon={personCircleOutline} />
          <IonLabel>{isFullyLogged ? 'Compte' : 'Connexion'}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default TabBar;
