import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { IonContent, IonItem, IonPage, IonIcon, IonToolbar, IonTitle, IonButtons, IonBackButton, IonHeader, IonLabel } from '@ionic/react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useLocation } from 'react-router';

import './ChaptersPage.scss';
import { eyeOffOutline, eyeOutline, filterOutline } from 'ionicons/icons';

const ChaptersFtpPage: React.FC = () => {
  const theme = useSelector((state: RootState) => state.app.theme);
  const location = useLocation();
  const mangaName = queryString.parse(location.search).mangaName;
  const [reverseOrderChapter, setReverseOrderChapter] = useState<boolean>(false);
  const selectedManga = useSelector((state: RootState) => state.ftpManga.manga?.find(item => item.mangaName === mangaName));
  const [chapters, setChapters] = useState<string[]>([]);

  useEffect(() => {
    if (selectedManga) {
      if (!reverseOrderChapter) {
        return setChapters(selectedManga.chaptersList);
      }

      setChapters(
        [...selectedManga.chaptersList]
          .slice()
          // eslint-disable-next-line
          .sort((a: any, b: any) => b.title - a.title)
          .reverse(),
      );
    }
  }, [reverseOrderChapter, selectedManga]);

  return (
    <IonPage className="chapters-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Chapitres</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {selectedManga && (
          <>
            <div
              className="cover-background"
              style={{
                background:
                  theme === 'dark'
                    ? `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1)), url(${selectedManga.cover ? selectedManga.cover : 'assets/img/none.jpg'})`
                    : `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 1)), url(${selectedManga.cover ? selectedManga.cover : 'assets/img/none.jpg'})`,
              }}
            >
              <div className="container-img">
                <img className="img-card" src={selectedManga.cover ? selectedManga.cover : 'assets/img/none.jpg'} />
                <h1>{selectedManga.mangaName}</h1>
                <IonLabel>Chapitres - {selectedManga.chaptersList.length}</IonLabel>
              </div>
            </div>
            <span className="container-chapters">
              <span className="span-chapters">
                <IonItem class="chapter">
                  <IonIcon icon={filterOutline} className={!reverseOrderChapter ? 'rotate' : ''} slot="end" onClick={() => setReverseOrderChapter(!reverseOrderChapter)} />
                </IonItem>
              </span>
              {chapters.map(chapter => (
                <span key={chapter} className="span-chapters">
                  <IonItem className={'chapter'} routerLink={`chapters/read?mangaName=${selectedManga.mangaName}&chapterName=${chapter}`}>
                    {selectedManga.chaptersRead.includes(chapter) ? <IonIcon icon={eyeOutline} /> : <IonIcon icon={eyeOffOutline} />}
                    &ensp; <IonLabel>{chapter}</IonLabel>
                  </IonItem>
                </span>
              ))}
            </span>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ChaptersFtpPage;
