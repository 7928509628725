import React, { useState } from 'react';
import { InputChangeEventDetail, IonButton, IonContent, IonInput, IonItem, IonLabel, IonPage } from '@ionic/react';
import HeaderComponent from '../components/HeaderComponent';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { login } from '../store/app/actions';
import './LoginPage.scss';
import PasswordLostModal from '../components/PasswordLostModal';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setUsername(e.detail.value);
    }
  };

  const handlePasswordChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setPassword(e.detail.value);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>): void => {
    e.preventDefault();
    if (username && password) {
      dispatch(login({ username, password }));
    }
  };

  return (
    <IonPage className="login-page">
      <HeaderComponent title="Connexion" />
      <IonContent fullscreen className="wallpaper ion-item-with-padding" style={{ backgroundImage: 'url(assets/img/wallpaper.jpg)' }}>
        <div className="container-buttons-wallpaper">
          <div className="content-wallpaper">
            <h2 className="ion-text-center">{'Connexion'}</h2>
            <IonItem>
              <IonLabel position="floating">Pseudo</IonLabel>
              <IonInput value={username} onIonChange={e => handleUsernameChange(e)} />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Mot de passe</IonLabel>
              <IonInput value={password} type="password" onIonChange={e => handlePasswordChange(e)} />
            </IonItem>
            <div className="margin-button">
              <IonButton className="register-button mb-20" expand="block" color="primary" onClick={e => handleSubmit(e)}>
                Valider
              </IonButton>
              <IonButton id="open-password-lost-modal" className="register-button mb-20" expand="block" fill="outline">
                Mot de passe oublié
              </IonButton>
            </div>
            <PasswordLostModal />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
