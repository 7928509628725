import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonReorder, IonReorderGroup, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import './SearchModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { closeOutline, createOutline, informationOutline, trashOutline } from 'ionicons/icons';
import { removeTag, updateTagsNameAction, updateTagsOrderAction } from '../store/tags/actions';
import './TagsModal.scss';
import { tag } from '../store/tags/types';
import PopoverComponent, { textsPopover } from './PopoverComponent';

const TagsUpdateModal: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const tags = useSelector((state: RootState) => state.tags.tags);
  const [tagsOrder, setTagsOrder] = useState<tag[]>();
  const favorites = useSelector((state: RootState) => state.favorites.favorites);
  const modal = useRef<HTMLIonModalElement>(null);
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    if (tags) {
      setTagsOrder([...tags].sort((a, b) => a.order - b.order));
    }
  }, [tags]);

  const deleteTag = async (tagId: string): Promise<void> => {
    dispatch(removeTag(tagId));
  };

  // eslint-disable-next-line
  const changeTagName = async (event: any, id: string): Promise<void> => {
    const name = event[0];
    dispatch(updateTagsNameAction({ name, id }));
  };

  const doReorder = async (event: CustomEvent): Promise<void> => {
    const newOrder = await event.detail.complete(tagsOrder);
    setTagsOrder(newOrder);

    const newTagsOrder = newOrder.map((id: string, index: number) => {
      return {
        id: id,
        order: index,
      };
    });
    dispatch(updateTagsOrderAction(newTagsOrder));
  };

  return (
    <IonModal className="tags-delete-modal" ref={modal} trigger="open-tags-delete-modal" onDidDismiss={() => modal.current?.dismiss()}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edition des tags</IonTitle>
          <IonButtons slot="end">
            <IonButton id="click-trigger-tags-update">
              <IonIcon icon={informationOutline} />
            </IonButton>
            <IonButton strong={true} onClick={() => modal.current?.dismiss()}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-item-with-padding">
        <IonItem>
          <IonLabel className="ion-text-wrap ion-text-center">Seul les tags sans favoris peuvent être supprimés.</IonLabel>
        </IonItem>
        <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
          {tagsOrder &&
            tagsOrder.map(tag => (
              <IonItem key={tag.name}>
                <IonLabel>{tag.name}</IonLabel>
                <IonButton
                  color="secondary"
                  onClick={() =>
                    presentAlert({
                      header: 'Modifier le nom du tag',
                      buttons: [
                        {
                          cssClass: 'alert-button-cancel',
                          text: 'Annuler',
                          role: 'cancel',
                        },
                        {
                          cssClass: 'alert-button-validation',
                          text: 'Confirmer',
                          role: 'confirm',
                          handler: e => changeTagName(e, tag._id),
                        },
                      ],
                      inputs: [
                        {
                          placeholder: 'Nom du tag',
                          value: tag.name,
                        },
                      ],
                    })
                  }
                >
                  <IonIcon icon={createOutline} />
                </IonButton>
                {favorites.filter(favorite => favorite.tags.some(tagFilter => tagFilter._id === tag._id)).length === 0 && (
                  <IonButton
                    color="secondary"
                    onClick={() =>
                      presentAlert({
                        header: 'Confirmer la suppresion',
                        buttons: [
                          {
                            cssClass: 'alert-button-cancel',
                            text: 'Annuler',
                            role: 'cancel',
                          },
                          {
                            cssClass: 'alert-button-validation',
                            text: 'Confirmer',
                            role: 'confirm',
                            handler: () => deleteTag(tag._id),
                          },
                        ],
                      })
                    }
                  >
                    <IonIcon icon={trashOutline} />
                  </IonButton>
                )}
                <IonReorder slot="end" />
              </IonItem>
            ))}
        </IonReorderGroup>
        <PopoverComponent text={textsPopover.tagsUpdateModal} trigger="click-trigger-tags-update" />
      </IonContent>
    </IonModal>
  );
};

export default TagsUpdateModal;
