import React, { useRef } from 'react';
import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './UpdateInfosModal.scss';
import { closeOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const HelpModal: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const theme = useSelector((state: RootState) => state.app.theme);

  return (
    <IonModal className="help-modal" ref={modal} trigger="open-help-modal" onDidDismiss={() => modal.current?.dismiss()}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Aide</IonTitle>
          <IonButtons slot="end">
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => modal.current?.dismiss()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonAccordionGroup>
          <IonAccordion value="first">
            <IonItem slot="header" color={theme === 'dark' ? 'light' : ''}>
              <IonLabel>{"Mode de l'application"}</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              Vous avez deux mode pour l&apos;application
              <ul>
                <li>
                  Sources
                  <br />
                  Vous lisez les mangas directement via les sources disponible
                </li>
                <br />
                <li>
                  FTP
                  <br />
                  Vous pouvez vous connecter à un serveur FTP pour lire vos propres mangas directement dans l&apos;application
                </li>
              </ul>
            </div>
          </IonAccordion>
          <IonAccordion value="speed">
            <IonItem slot="header" color={theme === 'dark' ? 'light' : ''}>
              <IonLabel>{'Speed mode'}</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              {"Le speed mode accelère l'affichage de la liste des mangas."}
              <br />
              {"En contre parti, vous n'avez pas l'affichage du nombre de chapitre disponible"}
            </div>
          </IonAccordion>
          <IonAccordion value="second">
            <IonItem slot="header" color={theme === 'dark' ? 'light' : ''}>
              <IonLabel>Favoris</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              Vos favoris sont gardés en mémoire.
              <br />
              <br />
              N&apos;ésitez pas à les raffraichir pour voir si de nouveaux chapitres sont disponible !
              <br />
              <br />
              Nombre à gauche, se qu&apos;il vous reste à lire.
              <br />
              <br />
              Nombre à droite, Le nombre de chapitres disponible.
            </div>
          </IonAccordion>
          <IonAccordion value="tags">
            <IonItem slot="header" color={theme === 'dark' ? 'light' : ''}>
              <IonLabel>Tags</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              Vous pouvez créer des tags lors de la l&apos;ajout d&apos;un manga en favori.
              <br />
              <br />
              Les tags servent à trier les manga sur votre pages de favoris.
              <br />
              <br />
              Un favori peu avoir plusieurs tags.
              <br />
              <br />
              Pour supprimer un tag il faut que celui-ci n&apos;est aucun favori
            </div>
          </IonAccordion>
          <IonAccordion value="download">
            <IonItem slot="header" color={theme === 'dark' ? 'light' : ''}>
              <IonLabel>Téléchargement</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              [Test en interne] Vous pouvez télécharger les manga via les sources directement sur l&apos;un de vos serveurs FTP enregistrés.
              <br />
              <br />
              [Android]: Vous pouvez télécharger les chapitres pour les lire hors ligne. Vous pouvez choisir le dossier où télécharger les manga.{' '}
              <ul>
                <li>
                  Dossier in app : Les mangas seront enregsitrés dans l&apos;app.
                  <br />
                  <br />
                  <u>Inconvénients</u>
                  <br />
                  <br />- Si l&apos;app est supprimée, les mangas seront également supprimés
                  <br />
                  <br />- Téléchargement plus long
                </li>
                <br />
                <li>
                  Dossier documents: Les mangas seront téléchargés dans vos documents. <br />
                  <br />
                  <u>Inconvénient</u> <br />
                  <br /> - Les mangas seront également présent dans votre galerie photo
                </li>
              </ul>
            </div>
          </IonAccordion>
          <IonAccordion value="third">
            <IonItem slot="header" color={theme === 'dark' ? 'light' : ''}>
              <IonLabel>Afficher les titres des manga</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              Vous pouvez masquer ou afficher les titres des manga sur la liste de vos favoris ainsi que sur la liste des manga des sources
            </div>
          </IonAccordion>
          <IonAccordion value="ftp">
            <IonItem slot="header" color={theme === 'dark' ? 'light' : ''}>
              <IonLabel>Gestion des accès FTP</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              Vous pouvez ajouter un ou plusieurs accès FTP
              <h1>Identifiants</h1>
              <p>Pour se conneter à votre serveur FTP/SFTP il vous faut renseigner :</p>
              <ul>
                <li>Host</li>
                <li>Port</li>
                <li>Username</li>
                <li>Password</li>
              </ul>
              <p>{"Il est recommander d'utiliser la methode SFTP via le port 22"}</p>
              <p>{"Il faut que l'utilisateur ai les droits en lecture ET écriture."}</p>
              <p>{"L'écriture concerne uniquement l'ajout d'un fichier JSON afin de vous offrir un suivi des manga lus/non lus"}</p>
              <h1>{"Format d'images"}</h1>
              <p>Les formats compatibles sont</p>
              <ul>
                <li>png</li>
                <li>jpg</li>
                <li>jpeg</li>
                <li>webp</li>
                <li>cbr</li>
              </ul>
              <h1>{"L'arborescence"}</h1>
              <p>Afin de pouvoir récuperer correctement les manga il faut respecter le format suivant</p>
              <ul>
                <li>
                  covers
                  <ul>
                    <li>{'{name_manga}.jpg/png/jpeg/gif/webp'}</li>
                  </ul>
                </li>

                <li>
                  manga
                  <ul>
                    <li>
                      {'{name_manga}'}
                      <ul>
                        <li>
                          001
                          <ul>
                            <li>{'01.jpg/png/jpeg/gif/webp/cbr'}</li>
                            <li>{'02.jpg/png/jpeg/gif/webp/cbr'}</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
      </IonContent>
    </IonModal>
  );
};

export default HelpModal;
