import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { postRequestApiWithAuth } from '../axiosApiRequest';
import { errorType } from '../app/types';
import { uploadChaptersFtp } from './types';

export const getFtpAccessData = createAsyncThunk('app/getFtpAccessData', async (id: string) => {
  try {
    return await postRequestApiWithAuth('/ftp/data', { id });
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getFtpScansAction = createAsyncThunk('app/getFtpScansAction', async (data: { id: string; mangaName: string; chapterName: string }) => {
  try {
    return await postRequestApiWithAuth('/ftp/scans', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const getNextFtpPage = createAsyncThunk('app/getNextFtpPage', async (data: { id: string; mangaName: string; chapterName: string }) => {
  try {
    return await postRequestApiWithAuth('/ftp/scans', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const addFtpChapterRead = createAsyncThunk('app/addFtpChapterRead', async (data: { id: string; mangaName: string; chapterName: string }) => {
  try {
    return await postRequestApiWithAuth('/ftp/chapter/read/add', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const uploadChaptersFtpAction = createAsyncThunk('app/uploadChaptersFtpAction', async (data: uploadChaptersFtp) => {
  try {
    return await postRequestApiWithAuth(`/ftp/upload/chapters`, data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});
