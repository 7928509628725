import React from 'react';
import { IonBadge, IonCard, IonCardContent, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';
import RoutingItem from './RoutingItem';

import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { ftpMangaType } from '../store/ftpManga/types';

interface GridSourceCoverProps {
  mangaList: ftpMangaType[];
}

export const GridMangaFtpSourceCover: React.FC<GridSourceCoverProps> = ({ mangaList }: GridSourceCoverProps) => {
  const titleDisplay = useSelector((state: RootState) => state.app.titleDisplay);
  return (
    <IonGrid className="grid-cover">
      <IonRow>
        {mangaList.map((item: ftpMangaType) => (
          <IonCol size="3" size-lg="3" size-sm="4" size-xs="6" size-xl="2" key={item.mangaName}>
            <IonCard className="manga-card">
              {item.chaptersList && (
                <IonBadge color="dark" className="badge-cover">
                  {item.chaptersList.length}
                </IonBadge>
              )}
              <RoutingItem route={`/sources/izoka/manga?mangaId=${item.mangaId}`}>
                <IonCardContent className="card-content">
                  <img className="img" src={item.cover ? item.cover : 'assets/img/none.jpg'} />
                  {titleDisplay && <h2 className="description-container">{item.mangaName}</h2>}
                </IonCardContent>
              </RoutingItem>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export const GridMangaFtpCover: React.FC<GridSourceCoverProps> = ({ mangaList }: GridSourceCoverProps) => {
  const titleDisplay = useSelector((state: RootState) => state.app.titleDisplay);
  return (
    <IonGrid className="grid-cover">
      <IonRow>
        {mangaList.map((item: ftpMangaType) => (
          <IonCol size="3" size-lg="3" size-sm="4" size-xs="6" size-xl="2" key={item.mangaName}>
            <IonCard className="manga-card">
              <IonBadge color="dark" className="badge-read">
                {item.chaptersList.length - item.chaptersRead.length}
              </IonBadge>
              <IonBadge color="dark" className="badge-cover">
                {item.chaptersList.length}
              </IonBadge>
              <RoutingItem route={`manga/chapters?mangaName=${item.mangaName}`}>
                <IonCardContent className="card-content">
                  <img className="img" src={item.cover ? item.cover : 'assets/img/none.jpg'} />
                  {titleDisplay && <h2 className="description-container">{item.mangaName}</h2>}
                </IonCardContent>
              </RoutingItem>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export const GridMangaFtpList: React.FC<GridSourceCoverProps> = ({ mangaList }: GridSourceCoverProps) => {
  return (
    <span className="grid-cover ion-item-with-padding">
      {mangaList.map((item: ftpMangaType) => (
        <IonItem className="ion-item-no-padding-start" key={item.mangaName} routerLink={`manga/chapters?mangaName=${item.mangaName}`}>
          <img src={item.cover ? item.cover : 'assets/img/none.jpg'} alt={item.mangaName} className="cover-mini" />
          <IonLabel className="ion-text-wrap">
            <IonText>
              <h2>{item.mangaName}</h2>
            </IonText>
            <p>A lire : {item.chaptersList.length - item.chaptersRead.length}</p>
            <p>Chapitres : {item.chaptersList.length}</p>
          </IonLabel>
        </IonItem>
      ))}
    </span>
  );
};
