import React, { useRef } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonModal, IonText, IonTitle, IonToolbar } from '@ionic/react';
import './PasswordListModal.scss';
import { bug, closeOutline, information } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { passwordLostAction } from '../store/app/actions';
import { setToastMessage } from '../store/app/appSlice';

const PasswordLostModal: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);

  const handleSubmit = async (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();
    let email = '';
    if (input.current?.value && typeof input.current.value === 'string') {
      email = input.current.value;
      const result = await dispatch(passwordLostAction(email));
      if (result.payload.success) {
        dispatch(
          setToastMessage({
            text: 'Vérifiez vos mails pour réinitialiser votre mot de passe',
            isOpen: true,
            icon: information,
            position: 'bottom',
          }),
        );
        modal.current?.dismiss();
      } else {
        dispatch(
          setToastMessage({
            text: "Le mail n'existe pas",
            isOpen: true,
            icon: bug,
            position: 'bottom',
          }),
        );
      }
    }
  };

  return (
    <IonModal className="password-lost-modal" ref={modal} trigger="open-password-lost-modal" onDidDismiss={() => modal.current?.dismiss()}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Réinitialiser le mode passe</IonTitle>
          <IonButtons slot="end">
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => modal.current?.dismiss()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonText>Veuillez entrer votre email</IonText>
        <IonItem>
          <IonInput ref={input} type="email" />
        </IonItem>
        <div className="margin-button">
          <IonButton className="register-button" expand="block" color="primary" onClick={e => handleSubmit(e)}>
            Valider
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default PasswordLostModal;
