import { createSlice } from '@reduxjs/toolkit';
import { TagsState } from './types';
import { addTag, getUserTags, removeTag, updateTagsNameAction, updateTagsOrderAction } from './actions';

export const defaultTagsState: TagsState = {
  tags: [],
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: defaultTagsState,
  reducers: {
    resetTags: state => {
      state.tags = [];
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserTags.fulfilled, (state, { payload }) => {
      state.tags = payload;
    });
    builder.addCase(updateTagsOrderAction.fulfilled, (state, { payload }) => {
      state.tags = payload;
    });
    builder.addCase(updateTagsNameAction.fulfilled, (state, { payload }) => {
      state.tags = payload;
    });
    builder.addCase(addTag.fulfilled, (state, { payload }) => {
      state.tags.push(payload);
      return payload.tags;
    });
    builder.addCase(removeTag.fulfilled, (state, { payload }) => {
      state.tags = state.tags.filter(item => item._id !== payload._id);
    });
  },
});

export const { resetTags } = tagsSlice.actions;

export default tagsSlice.reducer;
