// Create the middleware instance and methods
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { resetConnectedFtp, setAppLoading, setAppMode, setIsFullyLogged, setReinitializeApp } from './appSlice';
import { getCurrentUser, refreshToken, registerUser, login, logoutAction, deleteUser, getFtpAccess, updateFtpAccess, deleteFtpAccessAction, addFtpAccess } from './actions';
import { bug } from 'ionicons/icons';
import { createToastMessage } from '../../utils/helpers';
import { getUserFavorites } from '../favorites/actions';
import { getUserChaptersRead } from '../chaptersRead/actions';
import { resetFavorite } from '../favorites/favoriteSlice';
import { resetTags } from '../tags/tagsSlice';
import { getUserTags } from '../tags/actions';
import { RootState } from '../store';
import { reinitializeChapterRead } from '../chaptersRead/chapterReadSlice';
import { reinitializeNotifications } from '../notifications/notificationsSlice';
import { getUserNotificationAction, updateNotificationAction } from '../notifications/actions';
import { resetFtp } from '../ftpManga/ftpMangaSlice';
import moment from 'moment';

const appMiddleware = createListenerMiddleware();

////////FULFILLED///////

appMiddleware.startListening({
  actionCreator: registerUser.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(getCurrentUser());
  },
});

appMiddleware.startListening({
  actionCreator: logoutAction.fulfilled,
  effect: async (action, listenerApi) => {
    await listenerApi.dispatch(setReinitializeApp());
    await listenerApi.dispatch(resetFavorite());
    await listenerApi.dispatch(resetTags());
    await listenerApi.dispatch(resetFtp());
    window.location.reload();
  },
});

appMiddleware.startListening({
  actionCreator: deleteUser.fulfilled,
  effect: async (action, listenerApi) => {
    await listenerApi.dispatch(setReinitializeApp());
    await listenerApi.dispatch(resetFavorite());
    await listenerApi.dispatch(resetTags());
    await listenerApi.dispatch(resetFtp());
    window.location.reload();
  },
});

appMiddleware.startListening({
  actionCreator: refreshToken.fulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    if (state.app.appMode !== 'ftp') {
      await listenerApi.dispatch(getUserFavorites());
      await listenerApi.dispatch(getUserChaptersRead());
      await listenerApi.dispatch(getUserTags());
      await listenerApi.dispatch(getUserNotificationAction());
      // if (!state.notifications.lastUpdate) {
      //   listenerApi.dispatch(updateNotificationAction());
      // } else {
      //   const duration = moment.duration(moment(moment().format()).diff(state.notifications.lastUpdate));
      //   const hours = duration.asHours();
      //   if (hours >= 3) {
      //     listenerApi.dispatch(updateNotificationAction());
      //   }
      // }
      await listenerApi.dispatch(getFtpAccess());
    } else {
      await listenerApi.dispatch(getFtpAccess());
    }
  },
});

appMiddleware.startListening({
  actionCreator: setAppMode,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    if (state.app.appMode !== 'ftp') {
      await listenerApi.dispatch(getUserFavorites());
      await listenerApi.dispatch(getUserChaptersRead());
      await listenerApi.dispatch(getUserTags());
      await listenerApi.dispatch(getUserNotificationAction());
      await listenerApi.dispatch(getFtpAccess());
    } else {
      await listenerApi.dispatch(getFtpAccess());
    }
  },
});

appMiddleware.startListening({
  actionCreator: login.fulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState;

    await listenerApi.dispatch(getCurrentUser());

    if (state.app.appMode === 'ftp') {
      await listenerApi.dispatch(getFtpAccess());
    } else {
      await listenerApi.dispatch(getFtpAccess());
      await listenerApi.dispatch(getUserFavorites());
      await listenerApi.dispatch(getUserChaptersRead());
      await listenerApi.dispatch(getUserTags());
      await listenerApi.dispatch(getUserNotificationAction());
      /* if (!state.notifications.lastUpdate) {
        listenerApi.dispatch(updateNotificationAction());
      } else {
        const duration = moment.duration(moment(moment().format()).diff(state.notifications.lastUpdate));
        const hours = duration.asHours();
        if (hours >= 3) {
          listenerApi.dispatch(updateNotificationAction());
        }
      }*/
    }
    await listenerApi.dispatch(setIsFullyLogged(true));
    listenerApi.dispatch(setAppLoading(false));
  },
});

appMiddleware.startListening({
  actionCreator: updateFtpAccess.fulfilled,
  effect: async (action, listenerApi) => {
    await listenerApi.dispatch(resetFtp());
    setTimeout(() => {
      listenerApi.dispatch(resetConnectedFtp());
    }, 1000);
  },
});

appMiddleware.startListening({
  actionCreator: deleteFtpAccessAction.fulfilled,
  effect: async (action, listenerApi) => {
    await listenerApi.dispatch(resetFtp());
    setTimeout(() => {
      listenerApi.dispatch(resetConnectedFtp());
    }, 1000);
  },
});

////////REJECT///////

appMiddleware.startListening({
  actionCreator: registerUser.rejected,
  effect: async (action, listenerApi) => {
    if (action.error.message) {
      createToastMessage(action.error.message, bug, 'top', listenerApi);
    }
  },
});

appMiddleware.startListening({
  actionCreator: deleteUser.rejected,
  effect: async (action, listenerApi) => {
    if (action.error.message) {
      createToastMessage(action.error.message, bug, 'top', listenerApi);
    }
  },
});

appMiddleware.startListening({
  actionCreator: updateFtpAccess.rejected,
  effect: async (action, listenerApi) => {
    if (action.error.message) {
      createToastMessage(action.error.message, bug, 'top', listenerApi);
    }
  },
});

appMiddleware.startListening({
  actionCreator: addFtpAccess.rejected,
  effect: async (action, listenerApi) => {
    if (action.error.message) {
      createToastMessage(action.error.message, bug, 'top', listenerApi);
    }
  },
});

appMiddleware.startListening({
  actionCreator: logoutAction.rejected,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setReinitializeApp());
    listenerApi.dispatch(reinitializeChapterRead());
    listenerApi.dispatch(resetFavorite());
    listenerApi.dispatch(reinitializeNotifications());
    listenerApi.dispatch(resetFtp());
    listenerApi.dispatch(setAppLoading(false));
    listenerApi.dispatch(setIsFullyLogged(false));
    localStorage.removeItem('token');
  },
});

appMiddleware.startListening({
  actionCreator: login.rejected,
  effect: async (action, listenerApi) => {
    if (action.error.message) {
      createToastMessage(action.error.message, bug, 'top', listenerApi);
    }
  },
});

appMiddleware.startListening({
  actionCreator: refreshToken.rejected,
  effect: async (action, listenerApi) => {
    if (action.error.message) {
      listenerApi.dispatch(setReinitializeApp());
      listenerApi.dispatch(reinitializeChapterRead());
      listenerApi.dispatch(resetFavorite());
      listenerApi.dispatch(reinitializeNotifications());
      listenerApi.dispatch(setAppLoading(false));
      listenerApi.dispatch(setIsFullyLogged(false));
      localStorage.removeItem('token');
    }
  },
});

export default appMiddleware;
