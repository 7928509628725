import { chapters } from '../store/scanSources/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { chapterRead } from '../utils/helpers';
import { IonAvatar, IonCheckbox, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

import '../pages/ChaptersPage.scss';

interface ChapterListProps {
  chapter: chapters;
  mangaId: string;
  sourceId: string;
  showBar: boolean;
  markAsReadIsActive: boolean;
  downloadList: string[];
  updateDownloadList: (id: string, readAction: boolean) => void;
}

const ChapterListComponent: React.FC<ChapterListProps> = ({ chapter, mangaId, sourceId, showBar, downloadList, updateDownloadList, markAsReadIsActive }: ChapterListProps) => {
  const chaptersRead = useSelector((state: RootState) => state.chapterRead.mangas);
  const isChapterRead = chapterRead(chapter.id, mangaId as string, chaptersRead);

  return (
    <IonItem
      key={chapter.id}
      className={isChapterRead ? 'read chapter' : 'chapter'}
      routerLink={showBar || markAsReadIsActive ? undefined : `/sources/${sourceId}/chapters?chaptersId=${chapter.id}&mangaId=${mangaId}`}
    >
      {(showBar || markAsReadIsActive) && (
        <IonCheckbox
          className="checkbox"
          disabled={showBar && downloadList.length === 10 && !downloadList.some(item => item === chapter.id)}
          checked={downloadList.some(item => item === chapter.id)}
          onClick={() => updateDownloadList(chapter.id, markAsReadIsActive)}
        />
      )}
      {chapter.src && (
        <IonAvatar slot="start">
          <img src={chapter.src} />
        </IonAvatar>
      )}
      {isChapterRead ? <IonIcon icon={eyeOutline} /> : <IonIcon icon={eyeOffOutline} />}
      &ensp; <IonLabel>{chapter.title}</IonLabel>
    </IonItem>
  );
};

export default ChapterListComponent;
