import React, { useState } from 'react';
import { IonContent, IonItem, IonLabel, IonPage, IonInput, IonButton, InputChangeEventDetail, IonCheckbox, IonIcon, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react';
import { registerUser } from '../store/app/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import './RegisterPage.scss';
import { informationOutline } from 'ionicons/icons';
import PopoverComponent, { textsPopover } from '../components/PopoverComponent';

const RegisterPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isAdult, setIsAdult] = useState(true);

  const handleUsernameChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setUsername(e.detail.value);
    }
  };

  const handlePasswordChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setPassword(e.detail.value);
    }
  };

  const handleEmailChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setEmail(e.detail.value);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>): void => {
    e.preventDefault();
    if (username && password && email) {
      dispatch(registerUser({ username: username.trim(), password, email: email.trim(), isAdult }));
    }
  };

  return (
    <IonPage className="register-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Inscription</IonTitle>
          <IonButtons slot="end">
            <IonButton id="click-trigger-register">
              <IonIcon icon={informationOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="wallpaper ion-item-with-padding" style={{ backgroundImage: 'url(assets/img/wallpaper.jpg)' }}>
        <div className="container-buttons-wallpaper">
          <div className="content-wallpaper">
            <h2 className="ion-text-center">{"Plus qu'une étape pour profiter des manga à volontés !"}</h2>
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput value={email} type="email" onIonChange={e => handleEmailChange(e)} />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Pseudo</IonLabel>
              <IonInput value={username} onIonChange={e => handleUsernameChange(e)} />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Mot de passe</IonLabel>
              <IonInput value={password} type="password" onIonChange={e => handlePasswordChange(e)} />
            </IonItem>
            <IonItem>
              <IonLabel>Adulte ?</IonLabel>
              <IonCheckbox checked={isAdult} onIonChange={e => setIsAdult(e.detail.checked)} />
            </IonItem>
            <div className="margin-button">
              <IonButton className="register-button" expand="block" color="primary" onClick={e => handleSubmit(e)}>
                Valider
              </IonButton>
            </div>
            <PopoverComponent text={textsPopover.registerPage} trigger="click-trigger-register" />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RegisterPage;
