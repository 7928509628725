import React, { useRef } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './SearchModal.scss';
import { checkmarkOutline, closeOutline, informationOutline } from 'ionicons/icons';
import PopoverComponent, { textsPopover } from './PopoverComponent';

interface SearchAllSourceModalTypes {
  search: (title: string) => void;
}

const SearchAllSourceModal: React.FC<SearchAllSourceModalTypes> = ({ search }: SearchAllSourceModalTypes) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);

  const confirm = async (): Promise<void> => {
    let title = '';
    if (input.current?.value && typeof input.current.value === 'string') {
      title = input.current.value;
    }
    search(title);
    modal.current?.dismiss();
  };

  return (
    <IonModal className="search-all-source-modal" ref={modal} trigger="open-search-all-source-modal" onDidDismiss={() => modal.current?.dismiss()}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Recherche toutes sources</IonTitle>
          <IonButtons slot="end">
            <IonButtons slot="end">
              <IonButton id="click-trigger-search-all-source">
                <IonIcon icon={informationOutline} />
              </IonButton>
              <IonButton strong={true} onClick={() => confirm()}>
                <IonIcon icon={checkmarkOutline} />
              </IonButton>
              <IonButton strong={true} onClick={() => modal.current?.dismiss()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h4>Par titre</h4>
        <IonItem>
          <IonInput ref={input} type="text" />
        </IonItem>
        <PopoverComponent text={textsPopover.searchAllSourceModal} trigger="click-trigger-search-all-source" />
      </IonContent>
    </IonModal>
  );
};

export default SearchAllSourceModal;
