import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestApiWithAuth, postRequestApiWithAuth } from '../axiosApiRequest';
import axios from 'axios';
import { errorType } from '../app/types';
import { ChaptersReadManySend, ChaptersReadSend } from './types';

export const getUserChaptersRead = createAsyncThunk('app/getChaptersRead', async () => {
  try {
    return await getRequestApiWithAuth('/chapter/read/me');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const addChapterRead = createAsyncThunk('app/addChapterRead', async (data: ChaptersReadSend) => {
  try {
    return await postRequestApiWithAuth('/chapter/read/add', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const addChaptersRead = createAsyncThunk('app/addChaptersRead', async (data: ChaptersReadManySend) => {
  try {
    return await postRequestApiWithAuth('/chapter/read/add/many', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const removeChaptersRead = createAsyncThunk('app/removeChaptersRead', async (data: ChaptersReadManySend) => {
  try {
    return await postRequestApiWithAuth('/chapter/read/delete/many', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});
