import React, { useEffect, useState } from 'react';
import { IonBadge, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonSlides, IonSlide, IonText, useIonAlert, IonActionSheet } from '@ionic/react';
import { chapters, mangaList } from '../store/scanSources/types';
import { favorites } from '../store/favorites/types';
import RoutingItem from './RoutingItem';
import './GridCover.scss';
import { createOutline } from 'ionicons/icons';
import TagsModal from './TagsModal';
import { removeFavorite } from '../store/favorites/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { getChaptersList } from '../store/scanSources/actions';
import { useHistory } from 'react-router';
import { setAppLoading } from '../store/app/appSlice';
import { naturalCompare } from '../utils/helpers';

interface GridSourceCoverProps {
  mangaList: mangaList[];
  source: string;
}

export const GridSourceCover: React.FC<GridSourceCoverProps> = ({ mangaList, source }: GridSourceCoverProps) => {
  const titleDisplay = useSelector((state: RootState) => state.app.titleDisplay);
  const favorites = useSelector((state: RootState) => state.favorites.favorites);
  return (
    <IonGrid className="grid-cover">
      <IonRow>
        {mangaList.map((item: mangaList) => (
          <IonCol size="3" size-lg="3" size-sm="4" size-xs="6" size-xl="2" key={item.id}>
            <IonCard className="manga-card">
              {item.totalChapters !== 0 && (
                <IonBadge color="dark" className="badge-cover">
                  {item.totalChapters}
                </IonBadge>
              )}
              {favorites && favorites.find(favorite => favorite.source === source && favorite.mangaId === item.id) && (
                <div className="in-favorite">
                  <div className="text">En favoris</div>
                </div>
              )}
              <RoutingItem route={`/sources/${source}/manga?mangaId=${item.id}`}>
                <IonCardContent className="card-content">
                  <img className="img" src={item.src ? item.src : 'assets/img/none.jpg'} />
                  {titleDisplay && <h2 className="description-container">{item.title}</h2>}
                </IonCardContent>
              </RoutingItem>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export const GridSourceList: React.FC<GridSourceCoverProps> = ({ mangaList, source }: GridSourceCoverProps) => {
  const favorites = useSelector((state: RootState) => state.favorites.favorites);
  return (
    <span className="grid-cover ion-item-with-padding">
      {mangaList.map((item: mangaList) => (
        <IonItem className="ion-item-no-padding-start" key={item.id} routerLink={`/sources/${source}/manga?mangaId=${item.id}`}>
          <img src={item.src ? item.src : 'assets/img/none.jpg'} alt={item.title} className="cover-mini" />
          <IonLabel className="ion-text-wrap">
            <IonText>
              <h2>{item.title}</h2>
            </IonText>
            {item.totalChapters !== 0 && <p>Chapitres: {item.totalChapters}</p>}
            {item.genres && <p>Genres : {item.genres.join(', ')}</p>}
            {favorites && favorites.find(favorite => favorite.source === source && favorite.mangaId === item.id) && <p className="in-favorite-2">Dans vos favoris</p>}
          </IonLabel>
        </IonItem>
      ))}
    </span>
  );
};

interface GridFavoritesProps {
  // eslint-disable-next-line
  setIndexOnSlide: (value: any) => Promise<void>;
  slidesEl: React.MutableRefObject<HTMLIonSlidesElement>;
  favoritesOrdered: [favorites[]];
}

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  autoHeight: true,
  pagination: false,
};

export const GridFavoritesCover: React.FC<GridFavoritesProps> = ({ setIndexOnSlide, slidesEl, favoritesOrdered }: GridFavoritesProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const mangasChapterReadMangas = useSelector((state: RootState) => state.chapterRead.mangas);
  const titleDisplay = useSelector((state: RootState) => state.app.titleDisplay);
  const sources = useSelector((state: RootState) => state.scanSources.sources);

  const countNumberToRead = (favorite: favorites): number => {
    const manga = mangasChapterReadMangas.find(manga => manga.mangaId === favorite.mangaId && manga.source === favorite.source);
    if (manga) {
      return parseInt(favorite.totalChapters) - manga.chapters.length;
    }

    return parseInt(favorite.totalChapters);
  };

  const getNextChapterToRead = async (favorite: favorites): Promise<void> => {
    const manga = mangasChapterReadMangas.find(manga => manga.mangaId === favorite.mangaId && manga.source === favorite.source);
    if (manga && parseInt(favorite.totalChapters) - manga.chapters.length > 0 && parseInt(favorite.totalChapters) !== manga.chapters.length) {
      await dispatch(setAppLoading(true));
      const chaptersList = await dispatch(getChaptersList({ sourceId: favorite.source, mangaId: favorite.mangaId }));
      const chapters = [...chaptersList.payload.chapters]
        .slice()
        // eslint-disable-next-line
        .sort((a: any, b: any) => b.title - a.title)
        .reverse();
      const mangaChaptersOrdered = [...manga.chapters].sort(naturalCompare);
      const index = chapters.findIndex((chapter: chapters) => chapter && chapter.id === mangaChaptersOrdered[manga.chapters.length - 1]);

      if (chapters[index + 1] !== undefined) {
        history.push(`/sources/${manga.source}/chapters?chaptersId=${chapters[index + 1].id}&mangaId=${manga.mangaId}`);
      }
      await dispatch(setAppLoading(false));
    }
  };

  const displayCover = (sourceId: string, cover?: string): string => {
    if (!cover) {
      return 'assets/img/none.jpg';
    }

    return cover;
  };

  return (
    <>
      {favoritesOrdered && favoritesOrdered[0].length !== 0 && (
        <IonSlides pager={true} options={slideOpts} onIonSlideDidChange={val => setIndexOnSlide(val)} ref={slidesEl}>
          {favoritesOrdered.map((items: favorites[], key) => (
            <IonSlide key={key}>
              <IonGrid className="grid-cover">
                <IonRow>
                  {items.map((item: favorites) => (
                    <IonCol size="3" size-lg="3" size-sm="4" size-xs="6" size-xl="2" key={item.mangaId}>
                      <IonCard
                        className="manga-card"
                        routerLink={
                          sources && sources.find(source => source.id === item.source) && (countNumberToRead(item) === 0 || countNumberToRead(item) === parseInt(item.totalChapters))
                            ? `/sources/${item.source}/manga?mangaId=${item.mangaId}`
                            : undefined
                        }
                      >
                        {countNumberToRead(item) > 0 && (
                          <IonBadge color="dark" className="badge-read">
                            {countNumberToRead(item)}
                          </IonBadge>
                        )}
                        <IonBadge color="dark" className="badge-cover">
                          {item.totalChapters}
                        </IonBadge>
                        <IonItem
                          onClick={() =>
                            sources && !sources.find(source => source.id === item.source)
                              ? presentAlert({
                                  header: "La source de ce manga n'est plus disponible",
                                  buttons: [
                                    {
                                      text: 'Fermer',
                                      role: 'confirm',
                                      cssClass: 'alert-button-cancel',
                                    },
                                  ],
                                })
                              : countNumberToRead(item) > 0 && parseInt(item.totalChapters) !== countNumberToRead(item)
                              ? presentAlert({
                                  header: 'Action',
                                  buttons: [
                                    {
                                      text: 'Fiche',
                                      role: 'confirm',
                                      cssClass: 'alert-button-fiche',
                                      handler: () => history.push(`/sources/${item.source}/manga?mangaId=${item.mangaId}`),
                                    },
                                    {
                                      text: 'Reprendre',
                                      role: 'confirm',
                                      cssClass: 'alert-button-read',
                                      handler: () => getNextChapterToRead(item),
                                    },
                                  ],
                                })
                              : {}
                          }
                          lines="none"
                          detail={false}
                          className="routing-ion-item"
                        >
                          <IonCardContent className="card-content">
                            <img className="img" src={displayCover(item.source, item.cover)} />
                            {titleDisplay && <h2 className="description-container">{item.mangaName}</h2>}
                          </IonCardContent>
                        </IonItem>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </IonSlide>
          ))}
        </IonSlides>
      )}
    </>
  );
};

interface DisplayListFavoritesProps {
  // eslint-disable-next-line
  setIndexOnSlide: (value: any) => Promise<void>;
  slidesEl: React.MutableRefObject<HTMLIonSlidesElement>;
  favoritesOrdered: [favorites[]];
  editionMode?: boolean;
}

export const DisplayListFavorites: React.FC<DisplayListFavoritesProps> = ({ editionMode, favoritesOrdered, setIndexOnSlide, slidesEl }: DisplayListFavoritesProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [presentAlert] = useIonAlert();
  const [modalTagsIsOpen, setModalTagsIsOpen] = useState<boolean>(false);
  const [selectedFavorite, setSelectedFavorite] = useState<favorites | undefined>(undefined);
  const mangasChapterReadMangas = useSelector((state: RootState) => state.chapterRead.mangas);
  const [editCurrentFavorite, setEditCurrentFavorite] = useState<favorites>();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const history = useHistory();
  const sources = useSelector((state: RootState) => state.scanSources.sources);

  const countNumberToRead = (favorite: favorites): number => {
    const manga = mangasChapterReadMangas.find(manga => manga.mangaId === favorite.mangaId && manga.source === favorite.source);
    if (manga) {
      return parseInt(favorite.totalChapters) - manga.chapters.length;
    }

    return parseInt(favorite.totalChapters);
  };

  const openModalTags = (favorite: favorites): void => {
    setSelectedFavorite(favorite);
  };

  useEffect(() => {
    if (selectedFavorite) {
      setIsModalTagsOpen(true);
    }
  }, [selectedFavorite]);

  const setIsModalTagsOpen = (value: boolean): void => {
    setModalTagsIsOpen(value);
  };

  const removeToFavorite = (favorite: favorites): void => {
    dispatch(removeFavorite(favorite._id));
  };

  const getNextChapterToRead = async (favorite: favorites): Promise<void> => {
    const manga = mangasChapterReadMangas.find(manga => manga.mangaId === favorite.mangaId && manga.source === favorite.source);
    if (manga && parseInt(favorite.totalChapters) - manga.chapters.length > 0 && parseInt(favorite.totalChapters) !== manga.chapters.length) {
      await dispatch(setAppLoading(true));
      const chaptersList = await dispatch(getChaptersList({ sourceId: favorite.source, mangaId: favorite.mangaId }));
      const chapters = [...chaptersList.payload.chapters]
        .slice()
        // eslint-disable-next-line
        .sort((a: any, b: any) => b.title - a.title)
        .reverse();

      const index = chapters.findIndex((chapter: chapters) => chapter.id === manga.chapters[manga.chapters.length - 1]);

      if (chapters[index + 1] !== null) {
        history.push(`/sources/${manga.source}/chapters?chaptersId=${chapters[index + 1].id}&mangaId=${manga.mangaId}`);
      }
      await dispatch(setAppLoading(false));
    }
  };

  const displayCover = (sourceId: string, cover?: string): string => {
    if (!cover) {
      return 'assets/img/none.jpg';
    }

    return cover;
  };

  return (
    <>
      {favoritesOrdered && favoritesOrdered[0].length !== 0 && (
        <IonSlides className={editionMode ? 'mb-60' : ''} pager={true} options={slideOpts} onIonSlideDidChange={val => setIndexOnSlide(val)} ref={slidesEl}>
          {favoritesOrdered.map((items: favorites[], key) => (
            <IonSlide key={key} className="ion-item-with-padding">
              <div className="w-100">
                {items.map((favorite: favorites) => (
                  <IonItem
                    key={favorite._id}
                    className="ion-item-no-padding-start w-100"
                    routerLink={
                      sources &&
                      sources.find(source => source.id === favorite.source) &&
                      !editionMode &&
                      (countNumberToRead(favorite) === 0 || countNumberToRead(favorite) === parseInt(favorite.totalChapters))
                        ? `/sources/${favorite.source}/manga?mangaId=${favorite.mangaId}`
                        : undefined
                    }
                    onClick={() =>
                      sources && !sources.find(source => source.id === favorite.source) && !editionMode
                        ? presentAlert({
                            header: "La source de ce manga n'est plus disponible",
                            buttons: [
                              {
                                text: 'Fermer',
                                role: 'confirm',
                                cssClass: 'alert-button-cancel',
                              },
                            ],
                          })
                        : !editionMode && countNumberToRead(favorite) > 0 && parseInt(favorite.totalChapters) !== countNumberToRead(favorite)
                        ? presentAlert({
                            header: 'Action',
                            buttons: [
                              {
                                text: 'Fiche',
                                role: 'confirm',
                                cssClass: 'alert-button-fiche',
                                handler: () => history.push(`/sources/${favorite.source}/manga?mangaId=${favorite.mangaId}`),
                              },
                              {
                                text: 'Reprendre',
                                role: 'confirm',
                                cssClass: 'alert-button-read',
                                handler: () => getNextChapterToRead(favorite),
                              },
                            ],
                          })
                        : {}
                    }
                  >
                    <img src={displayCover(favorite.source, favorite.cover)} alt={favorite.mangaName} className="cover-mini" />
                    <IonLabel className="ion-text-wrap">
                      <IonText>
                        <h2>{favorite.mangaName}</h2>
                      </IonText>
                      <p>Tags : {favorite.tags.map(u => u.name).join(' - ')}</p>
                      <p>Source : {favorite.source}</p>
                      {!editionMode && (
                        <>
                          <p>A lire : {countNumberToRead(favorite)}</p>
                          <p>Chapitres : {favorite.totalChapters}</p>
                        </>
                      )}
                    </IonLabel>
                    {editionMode && (
                      <IonButton
                        color="secondary"
                        slot="end"
                        onClick={() => {
                          setEditCurrentFavorite(favorite);
                          setShowActionSheet(true);
                        }}
                      >
                        <IonIcon icon={createOutline} />
                      </IonButton>
                    )}
                  </IonItem>
                ))}
              </div>
            </IonSlide>
          ))}
        </IonSlides>
      )}
      {editionMode && (
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[
            {
              text: 'Editer les tags',
              role: 'destructive',
              handler: () => openModalTags(editCurrentFavorite as favorites),
            },
            {
              text: 'Supprimer des favoris',
              role: 'destructive',
              handler: () =>
                presentAlert({
                  header: 'Confirmer la suppression',
                  buttons: [
                    {
                      cssClass: 'alert-button-cancel',
                      text: 'Annuler',
                      role: 'cancel',
                    },
                    {
                      cssClass: 'alert-button-validation',
                      text: 'Confirmer',
                      role: 'confirm',
                      handler: () => removeToFavorite(editCurrentFavorite as favorites),
                    },
                  ],
                }),
            },
            {
              text: 'Fermer',
              role: 'cancel',
            },
          ]}
        />
      )}
      <TagsModal favorite={selectedFavorite} setIsOpen={setIsModalTagsOpen} isOpen={modalTagsIsOpen} />
    </>
  );
};
