import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { IonToast } from '@ionic/react';
import { closeToastMessage } from '../store/app/appSlice';

const ToastMessage: React.FC = () => {
  const toastMessage = useSelector((state: RootState) => state.app.toastMessage);
  const dispatch = useDispatch<AppDispatch>();

  const closeToastMessageHandler = (): void => {
    dispatch(closeToastMessage());
  };

  return (
    <IonToast isOpen={toastMessage.isOpen} icon={toastMessage.icon} position={toastMessage.position} onDidDismiss={() => closeToastMessageHandler()} message={toastMessage.text} duration={3000} />
  );
};

export default ToastMessage;
