import React, { useState } from 'react';
import { IonContent, IonItem, IonLabel, IonPage, IonSelectOption, IonSelect, useIonAlert, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, isPlatform } from '@ionic/react';
import { deleteUser, logoutAction, updateSpeedModeAction } from '../store/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import './AccountPage.scss';
import { themeType, viewerLeft, viewerTopBottom } from '../store/app/types';
import { setAppLoading, setAppMode, setDisplayTitle, setDownloadFolder, setTheme, setViewer } from '../store/app/appSlice';
import TagsUpdateModal from '../components/TagsUpdateModal';
import { Directory } from '@capacitor/filesystem';
import EditFtpAccessModal from '../components/EditFtpAccessModal';
import HelpModal from '../components/HelpModal';

const AccountPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [presentAlert] = useIonAlert();
  const downloadFolder = useSelector((state: RootState) => state.app.downloadFolder);
  const theme = useSelector((state: RootState) => state.app.theme);
  const titleDisplay = useSelector((state: RootState) => state.app.titleDisplay);
  const user = useSelector((state: RootState) => state.app.currentUser);
  const viewer = useSelector((state: RootState) => state.app.viewer);
  const appMode = useSelector((state: RootState) => state.app.appMode);
  const [modalEditFtpIsOpen, setModalEditFtpIsOpen] = useState<boolean>(false);

  const selectViewer = async (type: string): Promise<void> => {
    await dispatch(setViewer(type));
  };

  const selectTheme = async (theme: themeType): Promise<void> => {
    await dispatch(setTheme(theme));
  };

  const selectDisplayTitle = async (displayTitle: boolean): Promise<void> => {
    await dispatch(setDisplayTitle(displayTitle));
  };

  const selectSpeedMode = async (speedMode: boolean): Promise<void> => {
    await dispatch(updateSpeedModeAction({ speedMode }));
  };

  const deleteAccount = async (): Promise<void> => {
    await dispatch(deleteUser());
  };

  const setIsModalEditFtpOpen = (value: boolean): void => {
    setModalEditFtpIsOpen(value);
  };

  return (
    <IonPage className="account-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Mon Compte</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="wallpaper" style={{ backgroundImage: 'url(assets/img/wallpaper.jpg)' }}>
        <div className="container-buttons-wallpaper">
          <div className="margin-button content-wallpaper">
            <h2 className="ion-text-center">Hey {user.username} !</h2>
            <span className="action-list ion-item-with-padding">
              <IonItem id="open-help-modal">Aide</IonItem>
              <IonItem>
                <IonLabel>Theme</IonLabel>
                <IonSelect value={theme} interface="action-sheet" placeholder="Selection du theme" cancelText="Fermer" onIonChange={e => selectTheme(e.detail.value)}>
                  <IonSelectOption value="dark">Sombre</IonSelectOption>
                  <IonSelectOption value="light">Clair</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Type de lecteur</IonLabel>
                <IonSelect value={viewer} interface="action-sheet" placeholder="Selection type de lecteur" cancelText="Fermer" onIonChange={e => selectViewer(e.detail.value)}>
                  <IonSelectOption value={viewerTopBottom}>Haut en bas</IonSelectOption>
                  <IonSelectOption value={viewerLeft}>Gauche à droite</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Afficher les titres des manga</IonLabel>
                <IonSelect value={titleDisplay} interface="action-sheet" cancelText="Fermer" onIonChange={e => selectDisplayTitle(e.detail.value)}>
                  <IonSelectOption value={true}>Afficher</IonSelectOption>
                  <IonSelectOption value={false}>Ne pas afficher</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Speed mode</IonLabel>
                <IonSelect value={user.speedMode} interface="action-sheet" cancelText="Fermer" onIonChange={e => selectSpeedMode(e.detail.value)}>
                  <IonSelectOption value={true}>Activer</IonSelectOption>
                  <IonSelectOption value={false}>Désactiver</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>{"Mode de l'application"}</IonLabel>
                <IonSelect value={appMode} interface="action-sheet" cancelText="Fermer" onIonChange={e => dispatch(setAppMode(e.detail.value))}>
                  <IonSelectOption value="sources">Sources</IonSelectOption>
                  <IonSelectOption value="ftp">FTP</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem onClick={() => setModalEditFtpIsOpen(true)}>Gestion des accès FTP</IonItem>
              <IonItem id="open-tags-delete-modal">Edition des tags</IonItem>
              {isPlatform('capacitor') && (
                <IonItem>
                  <IonLabel>Dossier de téléchargement</IonLabel>
                  <IonSelect value={downloadFolder} interface="action-sheet" cancelText="Fermer" onIonChange={e => dispatch(setDownloadFolder(e.detail.value))}>
                    <IonSelectOption value={Directory.Data}>Dossier in app</IonSelectOption>
                    <IonSelectOption value={Directory.Documents}>Dossier Documents</IonSelectOption>
                  </IonSelect>
                </IonItem>
              )}
              <IonItem
                onClick={() => {
                  dispatch(setAppLoading(true));
                  dispatch(logoutAction());
                }}
              >
                Déconnexion
              </IonItem>
              <br />
              <IonItem
                onClick={() =>
                  presentAlert({
                    header: 'Confirmer la suppresion du compte ?',
                    buttons: [
                      {
                        text: 'Annuler',
                        role: 'cancel',
                      },
                      {
                        text: 'Confirmer',
                        role: 'confirm',
                        handler: deleteAccount,
                      },
                    ],
                  })
                }
              >
                Supprimer le compte
              </IonItem>
            </span>
          </div>
        </div>
        <TagsUpdateModal />
        <EditFtpAccessModal setIsOpen={setIsModalEditFtpOpen} isOpen={modalEditFtpIsOpen} />
        <HelpModal />
      </IonContent>
    </IonPage>
  );
};

export default AccountPage;
