import { sources } from '../store/scanSources/types';
import React, { useEffect, useRef, useState } from 'react';
import { IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './SearchModal.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { safeSearchWords } from '../utils/helpers';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

interface SearchModalTypes {
  updateList: (title: string, genre: string | null, tag: string | null, orderBy: string | null, status: string | null) => void;
  selectedSource?: sources;
  genre: string | null;
  tag: string | null;
  title: string | null;
  orderBy: string | null;
  status: string | null;
}

const SearchModal: React.FC<SearchModalTypes> = ({ updateList, selectedSource, genre, tag, orderBy, status }: SearchModalTypes) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [selectedGenre, setSelectedGenre] = useState<string | null>(genre);
  const [selectedTag, setSelectedTag] = useState<string | null>(tag);
  const [selectedOrderBy, setSelectedOrderBy] = useState<string | null>(orderBy);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(status);
  const user = useSelector((state: RootState) => state.app.currentUser);

  useEffect(() => {
    setSelectedTag(tag);
    setSelectedOrderBy(orderBy);
    setSelectedStatus(status);
    setSelectedGenre(genre);
  }, [updateList]);

  const confirm = async (): Promise<void> => {
    let title = '';
    if (input.current?.value && typeof input.current.value === 'string') {
      title = input.current.value;
    }
    updateList(title, selectedGenre, selectedTag, selectedOrderBy, selectedStatus);
    modal.current?.dismiss();
  };

  const selectGenre = (genre: string): void => {
    setSelectedGenre(selectedGenre === genre ? null : genre);
  };

  const selectTag = (tag: string): void => {
    setSelectedTag(selectedTag === tag ? null : tag);
  };

  const selectOrderBy = (orderBy: string): void => {
    setSelectedOrderBy(selectedOrderBy === orderBy ? null : orderBy);
  };

  const selectStatus = (status: string): void => {
    setSelectedStatus(selectedStatus === status ? null : status);
  };

  return (
    <IonModal className="search-modal" ref={modal} trigger="open-search-modal" onDidDismiss={() => modal.current?.dismiss()}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Recherche</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={() => confirm()}>
              <IonIcon icon={checkmarkOutline} />
            </IonButton>
            <IonButton strong={true} onClick={() => modal.current?.dismiss()}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h4>Par titre</h4>
        <IonItem>
          <IonInput ref={input} type="text" />
        </IonItem>
        {selectedSource?.status && (
          <>
            <h4>Status</h4>
            {Object.entries(selectedSource.status).map(([key, value]) => {
              return (
                <IonBadge key={key} className={selectedStatus === key ? 'badge selected' : 'badge'} onClick={() => selectStatus(key)}>
                  {value}
                </IonBadge>
              );
            })}
          </>
        )}
        {selectedSource?.orderBy && (
          <>
            <h4>Trié par</h4>
            {Object.entries(selectedSource.orderBy).map(([key, value]) => {
              return (
                <IonBadge key={key} className={selectedOrderBy === key ? 'badge selected' : 'badge'} onClick={() => selectOrderBy(key)}>
                  {value.orderBy}
                </IonBadge>
              );
            })}
          </>
        )}
        {selectedSource?.tags && (
          <>
            <h4>Par Tags</h4>
            {Object.entries(selectedSource.tags).map(([key, value]) => {
              return (
                <IonBadge key={key} className={selectedTag === key ? 'badge selected' : 'badge'} onClick={() => selectTag(key)}>
                  {value}
                </IonBadge>
              );
            })}
          </>
        )}
        {selectedSource?.genres && (
          <>
            <h4>Par genre</h4>
            {safeSearchWords(selectedSource?.genres, user.isAdult).map(([key, value]) => {
              return (
                <IonBadge key={key} className={selectedGenre === key ? 'badge selected' : 'badge'} onClick={() => selectGenre(key)}>
                  {value}
                </IonBadge>
              );
            })}
          </>
        )}
      </IonContent>
    </IonModal>
  );
};

export default SearchModal;
