import React from 'react';
import { IonButton, IonIcon, IonItem, useIonAlert } from '@ionic/react';
import { checkmarkCircleOutline, closeOutline, ellipseOutline } from 'ionicons/icons';
import '../pages/ChaptersPage.scss';

interface MarkAsReadBarComponentProps {
  markAsReadList: string[];
  deselectAll: () => void;
  selectAll: () => void;
  markAsRead: (isRead: boolean) => void;
  setShowBar: () => void;
}

const MarkAsReadBarComponent: React.FC<MarkAsReadBarComponentProps> = ({ markAsReadList, deselectAll, selectAll, markAsRead, setShowBar }: MarkAsReadBarComponentProps) => {
  const [presentAlert] = useIonAlert();
  return (
    <IonItem className="download-bar">
      {markAsReadList.length !== 0 && (
        <IonButton color="secondary" onClick={deselectAll} slot="end">
          <IonIcon icon={ellipseOutline} />
        </IonButton>
      )}
      <IonButton color="secondary" onClick={selectAll} slot="end">
        <IonIcon icon={checkmarkCircleOutline} />
      </IonButton>
      <IonButton
        color="secondary"
        slot="end"
        onClick={() =>
          presentAlert({
            header: 'Marquer la selection comme lu ?',
            buttons: [
              {
                cssClass: 'alert-button-cancel',
                text: 'Annuler',
                role: 'cancel',
              },
              {
                cssClass: 'alert-button-validation',
                text: 'OK',
                role: 'confirm',
                handler: () => markAsRead(true),
              },
            ],
          })
        }
      >
        lu
      </IonButton>
      <IonButton
        color="secondary"
        slot="end"
        onClick={() =>
          presentAlert({
            header: 'Marquer la selection comme non lu ?',
            buttons: [
              {
                cssClass: 'alert-button-cancel',
                text: 'Annuler',
                role: 'cancel',
              },
              {
                cssClass: 'alert-button-validation',
                text: 'OK',
                role: 'confirm',
                handler: () => markAsRead(false),
              },
            ],
          })
        }
      >
        non lu
      </IonButton>
      <IonButton color="secondary" slot="end" onClick={setShowBar}>
        <IonIcon icon={closeOutline} />
      </IonButton>
    </IonItem>
  );
};

export default MarkAsReadBarComponent;
