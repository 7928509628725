import React, { useEffect, useState } from 'react';
import { appName, deleteFolderOnAndroid, naturalCompare, readFileContentOnAndroid, readFolderContentOnAndroid } from '../utils/helpers';
import {
  IonActionSheet,
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonProgressBar,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { mangaInfos } from '../store/scanSources/types';
import { checkmarkCircle, checkmarkCircleOutline, closeOutline, ellipseOutline, ellipsisHorizontalOutline, eyeOffOutline, eyeOutline, folderOpenOutline, trashOutline } from 'ionicons/icons';
import './DownloadMangaPage.scss';
import { setDownloadFolder, setToastMessage } from '../store/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { Directory } from '@capacitor/filesystem';

interface mangaList {
  sourceId: string;
  mangaName: string;
  chapters: string[];
}

interface deleteList {
  mangaName: string;
  chapters: string[];
}

const initialState = [] as deleteList[];

const DownloadMangaPage: React.FC = () => {
  const isFullyLogged = useSelector((state: RootState) => state.app.isFullyLogged);
  const dispatch = useDispatch<AppDispatch>();
  const [downloadedManga, setDownloadedManga] = useState<mangaList[]>([]);
  const [mangasInfos, setMangasInfos] = useState<mangaInfos[]>([]);
  const [deleteList, setDeleteList] = useState<deleteList[]>(initialState);
  const [deleteIsActive, setDeleteIsActive] = useState<boolean>(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showActionSheetFolder, setShowActionSheetFolder] = useState(false);
  const [presentAlert] = useIonAlert();
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentNumberDelete, setCurrentNumberDelete] = useState<number>(1);

  const fetchData = async (): Promise<void> => {
    const array = [];
    const mangaInfosArray = [];
    let dir;
    try {
      dir = await readFolderContentOnAndroid(appName);
    } catch (e) {
      console.error(e);
    }

    if (dir) {
      const files = dir.files.filter(data => data != 'index.json').sort(naturalCompare);
      for (let i = 0; i < files.length; i++) {
        const sourceId = files[i];
        let dirSource;
        try {
          dirSource = await readFolderContentOnAndroid(`${appName}/${sourceId}`);
        } catch (e) {
          console.error(e);
        }

        if (dirSource) {
          for (let j = 0; j < dirSource.files.length; j++) {
            const mangaName = dirSource.files[j];
            let dirChapters;

            try {
              const mangaInfos = await readFileContentOnAndroid(`${appName}/${sourceId}/${mangaName}/index.json`);
              if (mangaInfos) {
                mangaInfosArray.push(JSON.parse(mangaInfos.data));
              }
            } catch (e) {
              console.error(e);
            }

            try {
              dirChapters = await readFolderContentOnAndroid(`${appName}/${sourceId}/${mangaName}`);
              array.push({
                sourceId: sourceId,
                mangaName: mangaName,
                chapters: dirChapters.files.filter(data => data != 'index.json').reverse(),
              });
            } catch (e) {
              console.error(e);
            }
          }
        }
      }
    }
    setDownloadedManga(array);
    setMangasInfos(mangaInfosArray);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addDeleteChapters = async (mangaName: string): Promise<void> => {
    const manga = mangasInfos.find(manga => manga.mangaName === mangaName);
    if (!manga) {
      return;
    }
    if (deleteList.some(item => mangaName === item.mangaName)) {
      const array = deleteList.filter(item => item.mangaName !== mangaName);
      setDeleteList(array);
    } else {
      const newDeleteList = deleteList.filter(item => item.mangaName !== mangaName);
      setDeleteList([...newDeleteList, { mangaName, chapters: manga.chapters }]);
    }
  };

  const addDeleteChapter = async (chapter: string, mangaName: string): Promise<void> => {
    if (deleteList.some(item => item.chapters.includes(chapter) && mangaName === item.mangaName)) {
      const manga = deleteList.find(item => mangaName === item.mangaName);
      if (manga) {
        const chapters = manga.chapters.filter(item => !item.includes(chapter));
        const newDeleteList = deleteList.filter(item => item.mangaName !== mangaName);
        setDeleteList([...newDeleteList, { mangaName, chapters: chapters }]);
      }
    } else {
      const mangaChapters = deleteList.find(item => item.mangaName === mangaName)?.chapters || [];
      const newDeleteList = deleteList.filter(item => item.mangaName !== mangaName);
      setDeleteList([...newDeleteList, { mangaName, chapters: [...mangaChapters, chapter] }]);
    }
  };

  const updateNumberDelete = (number: number): Promise<void> => {
    return new Promise(resolve => {
      setCurrentNumberDelete(number);
      return resolve();
    });
  };

  const deleteAll = async (): Promise<void> => {
    await updateNumberDelete(1);
    setDeleteIsActive(false);
    setDeleteInProgress(true);
    for (let i = 0; i < deleteList.length; i++) {
      const mangaInfo = mangasInfos.find(manga => manga.mangaName === deleteList[i].mangaName);

      if (!mangaInfo) {
        return;
      }
      if (mangaInfo.chapters === deleteList[i].chapters) {
        await updateNumberDelete(currentNumberDelete + deleteList[i].chapters.length);
        try {
          await deleteFolderOnAndroid(`${appName}/${mangaInfo.sourceId}/${deleteList[i].mangaName}`);
        } catch (e) {
          console.error(e);
        }
      } else {
        for (let j = 0; j < deleteList[i].chapters.length; j++) {
          const chapter = deleteList[i].chapters[j];
          await updateNumberDelete(currentNumberDelete + 1);
          try {
            await deleteFolderOnAndroid(`${appName}/${mangaInfo.sourceId}/${deleteList[i].mangaName}/${chapter}`);
          } catch (e) {
            console.error(e);
          }
        }
      }
    }
    await fetchData();
    setDeleteInProgress(false);
    setDeleteList(initialState);
    dispatch(
      setToastMessage({
        text: 'Suppression terminé',
        isOpen: true,
        icon: checkmarkCircle,
        position: 'bottom',
      }),
    );
  };

  const deselectAll = async (): Promise<void> => {
    setDeleteList(initialState);
  };

  const selectAll = async (): Promise<void> => {
    if (mangasInfos) {
      const array = mangasInfos.map(manga => {
        return { mangaName: manga.mangaName, chapters: manga.chapters };
      });
      setDeleteList(array);
    }
  };

  const countChaptersToDelete = (): number => {
    const total = [];

    for (let i = 0; i < deleteList.length; i++) {
      total.push(...deleteList[i].chapters);
    }
    return total.length;
  };

  return (
    <IonPage className="download-manga-page">
      <IonToolbar>
        <IonTitle>{isFullyLogged ? 'Téléchargments' : 'Lecture hors ligne'}</IonTitle>
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonButtons slot="end">
          <IonButton onClick={() => setShowActionSheetFolder(true)} expand="block">
            <IonIcon icon={folderOpenOutline} />
          </IonButton>
          {mangasInfos.length !== 0 && (
            <IonButton onClick={() => setShowActionSheet(true)} expand="block">
              <IonIcon icon={ellipsisHorizontalOutline} />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
      <IonContent fullscreen>
        {mangasInfos.length === 0 && !isLoading && (
          <div className="avatar">
            <IonAvatar>
              <img src="assets/img/sad.webp" />
            </IonAvatar>
            <h2>Aucun manga téléchargé</h2>
          </div>
        )}
        <MangaList
          deleteIsActive={deleteIsActive}
          downloadedManga={downloadedManga}
          mangasInfos={mangasInfos}
          addDeleteChapters={addDeleteChapters}
          addDeleteChapter={addDeleteChapter}
          deleteList={deleteList}
        />
        <IonActionSheet
          isOpen={showActionSheetFolder}
          onDidDismiss={() => setShowActionSheetFolder(false)}
          buttons={[
            {
              text: 'Dossier in app',
              role: 'destructive',
              handler: () => {
                dispatch(setDownloadFolder(Directory.Data));
                window.location.reload();
              },
            },
            {
              text: 'Dossier documents',
              role: 'destructive',
              handler: () => {
                dispatch(setDownloadFolder(Directory.Documents));
                window.location.reload();
              },
            },
            {
              text: 'Fermer',
              role: 'cancel',
            },
          ]}
        />
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[
            {
              text: 'Supprimer',
              role: 'destructive',
              handler: () => setDeleteIsActive(!deleteIsActive),
            },
            {
              text: 'Fermer',
              role: 'cancel',
            },
          ]}
        />
        {deleteIsActive && (
          <IonItem className="delete-bar">
            {deleteList.length !== 0 && (
              <IonButton color="secondary" onClick={deselectAll} slot="end">
                <IonIcon icon={ellipseOutline} />
              </IonButton>
            )}
            <IonButton color="secondary" onClick={selectAll} slot="end">
              <IonIcon icon={checkmarkCircleOutline} />
            </IonButton>
            <IonButton
              color="secondary"
              slot="end"
              onClick={() =>
                presentAlert({
                  header: 'Etes vous sûre de vouloir supprimer la selection ?',
                  buttons: [
                    {
                      text: 'Annuler',
                      role: 'cancel',
                    },
                    {
                      text: 'OK',
                      role: 'confirm',
                      handler: deleteAll,
                    },
                  ],
                })
              }
            >
              <IonIcon icon={trashOutline} />
            </IonButton>
            <IonButton color="secondary" slot="end" onClick={() => setDeleteIsActive(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonItem>
        )}
        <IonLoading cssClass="my-custom-class" isOpen={deleteInProgress} message={`Suppression en cours...`} />
        {deleteInProgress && (
          <div className="progress-bar">
            <IonProgressBar value={currentNumberDelete / countChaptersToDelete()} />
          </div>
        )}
        <IonLoading cssClass="my-custom-class" isOpen={isLoading} message={'Chargement...'} />
      </IonContent>
    </IonPage>
  );
};

interface ChapterListProps {
  deleteIsActive: boolean;
  downloadedManga: mangaList[];
  mangasInfos: mangaInfos[];
  addDeleteChapters: (mangaName: string) => void;
  addDeleteChapter: (chapter: string, mangaName: string) => void;
  deleteList: deleteList[];
}

const MangaList: React.FC<ChapterListProps> = ({ downloadedManga, addDeleteChapters, deleteIsActive, mangasInfos, deleteList, addDeleteChapter }: ChapterListProps) => {
  return (
    <>
      {downloadedManga.map(item => (
        <span className="ion-item-with-padding" key={item.mangaName}>
          <IonItem>
            {deleteIsActive && <IonCheckbox slot="start" className="checkbox" onClick={() => addDeleteChapters(item.mangaName)} />}
            <IonLabel className="ion-text-wrap">
              <IonText>
                <h3>{item.mangaName}</h3>
              </IonText>
              <p>Source : {item.sourceId}</p>
            </IonLabel>
          </IonItem>
          <span className="container-chapters">
            {item.chapters.sort().map(chapter => (
              <span key={chapter} className="span-chapters">
                <IonItem
                  key={chapter}
                  className={mangasInfos.find(manga => manga.chaptersRead.includes(chapter) && manga.mangaName === item.mangaName) ? 'read' : ''}
                  routerLink={
                    deleteIsActive ? undefined : `/offline/read?path=${appName}/${item.sourceId}/${item.mangaName}/${chapter}&chapter=${chapter}&mangaName=${item.mangaName}&source=${item.sourceId}`
                  }
                >
                  {deleteIsActive && <IonCheckbox className="checkbox" checked={deleteList.some(item => item.chapters.includes(chapter))} onClick={() => addDeleteChapter(chapter, item.mangaName)} />}
                  {mangasInfos.find(manga => manga.chaptersRead.includes(chapter) && manga.mangaName === item.mangaName) ? <IonIcon icon={eyeOutline} /> : <IonIcon icon={eyeOffOutline} />}
                  &ensp; <IonLabel>{chapter}</IonLabel>
                </IonItem>
              </span>
            ))}
          </span>
        </span>
      ))}
    </>
  );
};

export default DownloadMangaPage;
