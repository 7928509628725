import React, { useEffect, useState } from 'react';
import { InputChangeEventDetail, IonAvatar, IonButton, IonContent, IonInput, IonItem, IonLabel, IonLoading, IonPage } from '@ionic/react';
import HeaderComponent from '../components/HeaderComponent';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { checkPasswordLostAction, updatePasswordLostAction } from '../store/app/actions';
import './LoginPage.scss';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { setToastMessage } from '../store/app/appSlice';
import { information } from 'ionicons/icons';

const EditLostPassword: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const key = queryString.parse(location.search).key as string;
  const email = queryString.parse(location.search).email as string;
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkPasswordLostKey = async (): Promise<void> => {
      const lostPasswordIsValid = await dispatch(checkPasswordLostAction({ email, key }));
      if (lostPasswordIsValid.payload.success) {
        setIsLoading(false);
        return;
      }
      history.push('/');
    };

    if (key && email) {
      checkPasswordLostKey();
      return;
    }

    history.push('/');
  }, []);

  const handlePasswordChange = (e: CustomEvent<InputChangeEventDetail>): void => {
    if (e.detail.value) {
      setPassword(e.detail.value);
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();
    if (password) {
      const editPassword = await dispatch(updatePasswordLostAction({ email, key, password }));

      if (editPassword.payload.success) {
        await dispatch(
          setToastMessage({
            text: 'Mot de passe modifié avec succès.',
            isOpen: true,
            icon: information,
            position: 'bottom',
          }),
        );
        history.push('/login');
        return;
      }

      await dispatch(
        setToastMessage({
          text: "Une erreur c'est produite...",
          isOpen: true,
          icon: information,
          position: 'bottom',
        }),
      );
    }
  };

  return (
    <IonPage className="login-page">
      <HeaderComponent title="Modifier votre mot de passe" />
      <IonContent fullscreen>
        {!isLoading ? (
          <>
            <div className="avatar">
              <IonAvatar>
                <img src="assets/img/naruto2.png" />
              </IonAvatar>
            </div>
            <IonItem>
              <IonLabel position="floating">Mot de passe</IonLabel>
              <IonInput value={password} type="password" onIonChange={e => handlePasswordChange(e)} />
            </IonItem>
            <div className="margin-button">
              <IonButton className="register-button" expand="block" color="primary" onClick={e => handleSubmit(e)}>
                Valider
              </IonButton>
            </div>
          </>
        ) : (
          <IonLoading cssClass="my-custom-class" isOpen={true} message={`Chargement...`} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default EditLostPassword;
