import React from 'react';
import { IonActionSheet, isPlatform, useIonAlert } from '@ionic/react';
import { favorites } from '../store/favorites/types';
import { mangaChaptersList } from '../store/scanSources/types';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { ftpAccessType } from '../store/app/types';

interface ChapterMenuComponentProps {
  setShowActionSheet: (isShow: boolean) => void;
  setModalTagsIsOpen: (modalTagOpen: boolean) => void;
  setMarkAsReadIsActive: (markAsReadOpen: boolean) => void;
  updateFtpAccessForUpload: (ftpAccess: ftpAccessType) => void;
  activeDownload: () => void;
  showActionSheet: boolean;
  markAsReadIsActive: boolean;
  removeToFavorite: (mangaId: string) => void;
  selectedManga: mangaChaptersList | undefined;
  favorites: favorites[];
}

const ChapterMenuComponent: React.FC<ChapterMenuComponentProps> = ({
  setMarkAsReadIsActive,
  setModalTagsIsOpen,
  setShowActionSheet,
  showActionSheet,
  activeDownload,
  favorites,
  selectedManga,
  removeToFavorite,
  markAsReadIsActive,
  updateFtpAccessForUpload,
}: ChapterMenuComponentProps) => {
  const [presentAlert] = useIonAlert();
  const ftpAccess = useSelector((state: RootState) => state.app.ftpAccess);
  const ftpAccessButton = ftpAccess.map(ftp => {
    return {
      cssClass: 'alert-button-validation',
      text: ftp.host,
      role: 'cancel',
      handler: () => uploadToFtp(ftp),
    };
  });

  const uploadToFtp = async (ftpAccess: ftpAccessType): Promise<void> => {
    updateFtpAccessForUpload(ftpAccess);
    activeDownload();
  };

  if (isPlatform('capacitor')) {
    return (
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[
          {
            text: favorites.some(item => item.mangaId === selectedManga?.mangaId) ? 'Supprimer des favoris' : 'Ajouter aux favoris',
            role: 'destructive',
            handler: () =>
              favorites.some(item => item.mangaId === selectedManga?.mangaId)
                ? presentAlert({
                    header: 'Confirmer la suppression',
                    buttons: [
                      {
                        text: 'Annuler',
                        cssClass: 'alert-button-cancel',
                        role: 'cancel',
                      },
                      {
                        text: 'Confirmer',
                        cssClass: 'alert-button-validation',
                        role: 'confirm',
                        handler: () => removeToFavorite(selectedManga?.mangaId ? selectedManga?.mangaId : ''),
                      },
                    ],
                  })
                : setModalTagsIsOpen(true),
          },
          {
            text: 'Marquer comme lu/non lu',
            role: 'destructive',
            handler: () => setMarkAsReadIsActive(!markAsReadIsActive),
          },
          {
            text: 'Télécharger',
            role: 'destructive',
            handler: activeDownload,
          },
          {
            text: 'Fermer',
            role: 'cancel',
          },
        ]}
      />
    );
  }

  return (
    <IonActionSheet
      isOpen={showActionSheet}
      onDidDismiss={() => setShowActionSheet(false)}
      buttons={
        ftpAccess.length !== 0
          ? [
              {
                text: favorites.some(item => item.mangaId === selectedManga?.mangaId) ? 'Supprimer des favoris' : 'Ajouter aux favoris',
                role: 'destructive',
                handler: () =>
                  favorites.some(item => item.mangaId === selectedManga?.mangaId)
                    ? presentAlert({
                        header: 'Confirmer la suppression',
                        buttons: [
                          {
                            cssClass: 'alert-button-cancel',
                            text: 'Annuler',
                            role: 'cancel',
                          },
                          {
                            cssClass: 'alert-button-validation',
                            text: 'Confirmer',
                            role: 'confirm',
                            handler: () => removeToFavorite(selectedManga?.mangaId ? selectedManga?.mangaId : ''),
                          },
                        ],
                      })
                    : setModalTagsIsOpen(true),
              },
              {
                text: 'Marquer comme lu/non lu',
                role: 'destructive',
                handler: () => setMarkAsReadIsActive(!markAsReadIsActive),
              },
              {
                text: 'Télécharger sur un FTP',
                role: 'destructive',
                handler: () => {
                  presentAlert({
                    header: 'Choisissez le serveur ftp',
                    buttons: ftpAccessButton,
                  });
                },
              },
              {
                text: 'Fermer',
                role: 'cancel',
              },
            ]
          : [
              {
                text: 'Télécharger',
                role: 'destructive',
                handler: activeDownload,
              },
              {
                text: favorites.some(item => item.mangaId === selectedManga?.mangaId) ? 'Supprimer des favoris' : 'Ajouter aux favoris',
                role: 'destructive',
                handler: () =>
                  favorites.some(item => item.mangaId === selectedManga?.mangaId)
                    ? presentAlert({
                        header: 'Confirmer la suppression',
                        buttons: [
                          {
                            cssClass: 'alert-button-cancel',
                            text: 'Annuler',
                            role: 'cancel',
                          },
                          {
                            cssClass: 'alert-button-validation',
                            text: 'Confirmer',
                            role: 'confirm',
                            handler: () => removeToFavorite(selectedManga?.mangaId ? selectedManga?.mangaId : ''),
                          },
                        ],
                      })
                    : setModalTagsIsOpen(true),
              },
              {
                text: 'Marquer comme lu/non lu',
                role: 'destructive',
                handler: () => setMarkAsReadIsActive(!markAsReadIsActive),
              },
              {
                text: 'Fermer',
                role: 'cancel',
              },
            ]
      }
    />
  );
};

export default ChapterMenuComponent;
