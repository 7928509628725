import React, { useEffect, useRef, useState } from 'react';
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
  IonSpinner,
  IonTabButton,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { getSources, searchAllSources } from '../store/scanSources/actions';
import { sources } from '../store/scanSources/types';
import './SourcePage.scss';
import { arrowForwardOutline, chevronForwardOutline, closeOutline, searchOutline } from 'ionicons/icons';
import SearchAllSourceModal from '../components/SearchAllSourceModal';
import { createSearchRequest } from '../utils/helpers';
import { resetSearchInSources } from '../store/scanSources/ScanSourcesSlice';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  autoHeight: true,
  pagination: false,
};

const SourcesPage: React.FC = () => {
  // eslint-disable-next-line
  const scrollRef = React.useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const sources = useSelector((state: RootState) => state.scanSources.sources);
  const searchInSources = useSelector((state: RootState) => state.scanSources.searchInSources);
  const [sourcesFormatted, setSourcesFormatted] = useState<{ [key: string]: sources[] } | undefined>(undefined);
  const [currentSearchSource, setCurrentSearchSource] = useState<string | undefined>(undefined);
  const [searchTitle, setSearchTitle] = useState<string | undefined>(undefined);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const slidesEl = useRef(document.createElement('ion-slides'));
  const [index, setIndex] = useState(0);

  useEffect(() => {
    dispatch(getSources());
  }, []);

  useEffect(() => {
    const array: { [key: string]: sources[] } = {};
    if (sources) {
      sources.forEach(source => {
        if (!array[source.type]) {
          array[source.type] = [source];
        } else {
          array[source.type].push(source);
        }
      });
      setSourcesFormatted(array);
    }
  }, [sources]);

  const Search = async (title: string): Promise<void> => {
    if (title && sourcesFormatted) {
      setSearchLoading(true);
      await dispatch(resetSearchInSources());
      setSearchTitle(title);
      for (const key of Object.keys(sourcesFormatted)) {
        for (const source of sourcesFormatted[key]) {
          const sourceId = source.id;
          setCurrentSearchSource(sourceId);
          const search = createSearchRequest(sourceId, 0, title, null, null, null, null);
          await dispatch(searchAllSources(search));
        }
      }
      setCurrentSearchSource(undefined);
      setSearchLoading(false);
    }
  };

  const resetSearchTitle = async (): Promise<void> => {
    if (searchLoading) {
      return;
    }
    await dispatch(resetSearchInSources());
    setSearchTitle(undefined);
  };

  const handleChange = (newValue: number): void => {
    setIndex(newValue);
    slidesEl.current.slideTo(newValue);
  };

  // eslint-disable-next-line
  const setIndexOnSlide = async (event: any): Promise<void> => {
    let indexData = 0;
    // eslint-disable-next-line
    await event.target.getActiveIndex().then((value: any) => (indexData = value));
    scrollToTop();
    setIndex(indexData);
  };

  const scrollToTop = (): void => {
    try {
      // eslint-disable-next-line
      scrollRef.current.getScrollElement().then((element: any) => {
        scrollRef.current.scrollToTop(300);
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <IonPage className="source-page">
      <IonToolbar>
        <IonTitle>Liste des sources</IonTitle>
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonButtons slot="end">
          <IonButton id="open-search-all-source-modal" expand="block" className="search-button">
            <IonIcon icon={searchOutline} id="open-search-modal" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent fullscreen className="wallpaper" style={{ backgroundImage: 'url(assets/img/wallpaper.jpg)' }} ref={scrollRef}>
        {sourcesFormatted && (
          <IonItemDivider className="divider" sticky>
            {Object.keys(sourcesFormatted).map((sourceType, indexSource) => (
              <IonTabButton key={indexSource} className="tab-bar-button" selected={index === indexSource} tab={sourceType} onClick={() => handleChange(indexSource)}>
                <IonLabel>{sourceType}</IonLabel>
              </IonTabButton>
            ))}
          </IonItemDivider>
        )}
        {(searchTitle || (searchInSources && searchInSources[0])) && (
          <IonToolbar>
            <IonRow>
              <IonCol className="container-badge">
                <IonBadge className="badge" onClick={() => resetSearchTitle()}>
                  <span className="badge-text">Recherche {searchTitle}</span> <IonIcon className="badge-icon" icon={closeOutline} />
                </IonBadge>
              </IonCol>
            </IonRow>
          </IonToolbar>
        )}
        <div className="container-buttons-wallpaper">
          <div className="margin-button content-wallpaper">
            {sourcesFormatted && (
              <IonSlides pager={true} options={slideOpts} onIonSlideDidChange={val => setIndexOnSlide(val)} ref={slidesEl}>
                {Object.keys(sourcesFormatted).map(key => (
                  <IonSlide key={key} className="ion-item-with-padding">
                    <div className="w-100">
                      {sourcesFormatted[key].map(item => (
                        <div key={item.id}>
                          <IonItem className="ion-item-no-padding-end" routerLink={`/sources/${item.id}`}>
                            <IonLabel className="ion-text-wrap">
                              <IonText>
                                <h2>{item.name}</h2>
                              </IonText>
                              <p>{item.infos?.join(' - ')}</p>
                            </IonLabel>
                            <IonIcon slot="end" icon={chevronForwardOutline} />
                            {currentSearchSource === item.id && <IonSpinner name="lines" />}
                          </IonItem>
                          {searchInSources && searchInSources[item.id] && (
                            <>
                              {searchInSources[item.id].map(search => (
                                <IonItem key={search.id} className="ion-item-no-padding-start ion-item-no-padding-end" routerLink={`/sources/${item.id}/manga?mangaId=${search.id}`}>
                                  <img src={search.src ? search.src : 'assets/img/none.jpg'} className="cover-mini" />
                                  <IonLabel className="ion-text-wrap">
                                    <IonLabel className="ion-text-wrap">{search.title}</IonLabel>
                                  </IonLabel>
                                  <span>{search.totalChapters}</span>
                                  <IonIcon slot="end" icon={arrowForwardOutline}></IonIcon>
                                </IonItem>
                              ))}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </IonSlide>
                ))}
              </IonSlides>
            )}
          </div>
        </div>
        <SearchAllSourceModal search={Search} />
      </IonContent>
    </IonPage>
  );
};

export default SourcesPage;
