import { createSlice } from '@reduxjs/toolkit';
import { ftpMangaState } from './types';
import { addFtpChapterRead, getFtpAccessData, getFtpScansAction, getNextFtpPage } from './actions';

export const defaultFtpMangaState: ftpMangaState = {
  manga: [],
  scans: [],
};

export const ftpMangaSlice = createSlice({
  name: 'ftpManga',
  initialState: defaultFtpMangaState,
  reducers: {
    resetFtp: state => {
      state.scans = [];
      state.manga = [];
    },
    resetFtpPages: state => {
      state.scans = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getFtpAccessData.fulfilled, (state, { payload }) => {
      state.manga = payload.data;
      return state;
    });
    builder.addCase(getFtpScansAction.fulfilled, (state, { payload }) => {
      state.scans = [...state.scans, ...payload];
      return state;
    });
    builder.addCase(getNextFtpPage.fulfilled, (state, { payload }) => {
      state.scans = [...state.scans, ...payload];
      return state;
    });
    builder.addCase(addFtpChapterRead.fulfilled, (state, { payload }) => {
      const chaptersRead = state.manga.find(manga => manga.mangaName === payload.mangaName);
      if (chaptersRead) {
        chaptersRead.chaptersRead = payload.chaptersRead;
        return;
      }
    });
  },
});

export const { resetFtpPages, resetFtp } = ftpMangaSlice.actions;

export default ftpMangaSlice.reducer;
