import React, { useEffect, useRef, useState } from 'react';
import {
  IonActionSheet,
  IonAvatar,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTabButton,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import { GridFavoritesCover, DisplayListFavorites } from '../components/GridCover';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import './FavoritesPage.scss';
import { closeOutline, ellipsisHorizontalOutline, gridOutline, informationOutline, listOutline, notificationsOutline } from 'ionicons/icons';
import { setAppLoading, setDisplayFavoritesListType } from '../store/app/appSlice';
import PopoverComponent, { textsPopover } from '../components/PopoverComponent';
import { useHistory } from 'react-router';
import { tag } from '../store/tags/types';
import NotificationsModal from '../components/NotificationsModal';
import { updateNotificationAction, updateReadNNotificationsAction } from '../store/notifications/actions';
import { favorites } from '../store/favorites/types';
import UpdateInfosModal from '../components/UpdateInfosModal';

const FavoritesPage: React.FC = () => {
  // eslint-disable-next-line
  const scrollRef = React.useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const favorites = useSelector((state: RootState) => state.favorites.favorites);
  const displayFavoritesListType = useSelector((state: RootState) => state.app.displayFavoritesListType);
  const isLoading = useSelector((state: RootState) => state.app.isLoading);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);
  const updateNotificationInProgress = useSelector((state: RootState) => state.notifications.updateInProgress);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const [tagsOrder, setTagsOrder] = useState<tag[]>();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [favoriteEdition, setFavoriteEdition] = useState(false);
  const [favoritesOrdered, setFavoritesOrdered] = useState<[favorites[]]>([[]]);
  const [index, setIndex] = useState(0);
  const history = useHistory();
  const slidesEl = useRef(document.createElement('ion-slides'));

  useEffect(() => {
    const groupe: [favorites[]] = [[]];
    const tagsWithFavorite = tags.filter(tag =>
      favorites.some(favorite => {
        return favorite.tags.some(favoriteTag => favoriteTag._id === tag._id);
      }),
    );
    [...tagsWithFavorite]
      .sort((a, b) => a.order - b.order)
      .reduce((group, tag, index) => {
        const favoriteByTag = favorites.filter(favorite => favorite.tags.some(tagFavorite => tagFavorite._id === tag._id));
        groupe[index] = groupe[index] ?? [];
        groupe[index].push(...favoriteByTag);
        return group;
      }, []);
    setFavoritesOrdered(groupe);
  }, [tags, favorites]);

  useEffect(() => {
    if (tags && tags.length !== 0) {
      const tagsWithFavorite = tags.filter(tag =>
        favorites.some(favorite => {
          return favorite.tags.some(favoriteTag => favoriteTag._id === tag._id);
        }),
      );
      setTagsOrder([...tagsWithFavorite].sort((a, b) => a.order - b.order));
    }
  }, [tags]);

  const refreshFavorites = async (): Promise<void> => {
    await dispatch(setAppLoading(true));
    await dispatch(updateNotificationAction());
    await dispatch(setAppLoading(false));
  };

  const handleChange = (newValue: number): void => {
    setIndex(newValue);
    slidesEl.current.slideTo(newValue);
  };

  // eslint-disable-next-line
  const setIndexOnSlide = async (event: any): Promise<void> => {
    let indexData = 0;
    // eslint-disable-next-line
    await event.target.getActiveIndex().then((value: any) => (indexData = value));
    scrollToTop();
    setIndex(indexData);
  };

  const scrollToTop = (): void => {
    try {
      // eslint-disable-next-line
      scrollRef.current.getScrollElement().then((element: any) => {
        scrollRef.current.scrollToTop(300);
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <IonPage className="favorites-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{favoriteEdition && favorites.length !== 0 ? 'Edition des favoris' : 'Favoris'}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton id="click-trigger-favorite-home">
              <IonIcon icon={informationOutline} />
            </IonButton>
            <IonButton onClick={() => dispatch(setDisplayFavoritesListType(displayFavoritesListType === 'cover' ? 'list' : 'cover'))} expand="block">
              <IonIcon icon={displayFavoritesListType === 'cover' ? listOutline : gridOutline} />
            </IonButton>
            <div className="relative">
              <IonButton id="open-notifications-modal" onClick={() => (notifications.some(notification => notification.unread) ? dispatch(updateReadNNotificationsAction()) : {})} expand="block">
                <IonIcon icon={notificationsOutline} />
              </IonButton>
              {notifications.some(notification => notification.unread) && <IonBadge className="badge-notifications">{notifications.filter(notification => notification.unread).length}</IonBadge>}
            </div>
            {favorites.length !== 0 && (
              <IonButton onClick={() => setShowActionSheet(true)} expand="block">
                <IonIcon icon={ellipsisHorizontalOutline} />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen ref={scrollRef}>
        {favorites.length !== 0 && tagsOrder && tagsOrder.length !== 0 && (
          <IonItemDivider className="divider" sticky>
            {tagsOrder.map((tag, indexTag) => (
              <IonTabButton key={tag._id} className="tab-bar-button" selected={index === indexTag} tab={tag.name} onClick={() => handleChange(indexTag)}>
                <IonLabel>{tag.name}</IonLabel>
              </IonTabButton>
            ))}
          </IonItemDivider>
        )}
        {favorites.length === 0 && !isLoading && (
          <div className="avatar">
            <IonAvatar>
              <img src="assets/img/sad.webp" />
            </IonAvatar>
            <h2>Aucun favoris</h2>
          </div>
        )}

        <IonRefresher slot="fixed" onIonRefresh={refreshFavorites}>
          <IonRefresherContent />
        </IonRefresher>

        {favoriteEdition && favorites.length !== 0 ? (
          <DisplayListFavorites editionMode={true} favoritesOrdered={favoritesOrdered} slidesEl={slidesEl} setIndexOnSlide={setIndexOnSlide} />
        ) : displayFavoritesListType === 'cover' ? (
          <GridFavoritesCover slidesEl={slidesEl} setIndexOnSlide={setIndexOnSlide} favoritesOrdered={favoritesOrdered} />
        ) : (
          <DisplayListFavorites favoritesOrdered={favoritesOrdered} slidesEl={slidesEl} setIndexOnSlide={setIndexOnSlide} />
        )}
        {isPlatform('capacitor') ? (
          <IonActionSheet
            isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            buttons={[
              {
                text: 'Rafraichir les favoris',
                role: 'destructive',
                handler: refreshFavorites,
              },
              {
                text: favoriteEdition ? "Annuler l'édition" : 'Editer les favoris',
                role: 'destructive',
                handler: () => setFavoriteEdition(!favoriteEdition),
              },
              {
                text: 'Mangas téléchargés',
                role: 'destructive',
                handler: () => history.push('/offline'),
              },
              {
                text: 'Fermer',
                role: 'cancel',
              },
            ]}
          />
        ) : (
          <IonActionSheet
            isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            buttons={[
              {
                text: 'Rafraichir les favoris',
                role: 'destructive',
                handler: refreshFavorites,
              },
              {
                text: favoriteEdition ? "Annuler l'édition" : 'Editer les favoris',
                role: 'destructive',
                handler: () => setFavoriteEdition(!favoriteEdition),
              },
              {
                text: 'Fermer',
                role: 'cancel',
              },
            ]}
          />
        )}
        {favoriteEdition && favorites.length !== 0 && (
          <IonItem className="bottom-bar">
            <IonButton color="secondary" slot="end" onClick={() => setFavoriteEdition(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonItem>
        )}
        <PopoverComponent text={textsPopover.favoritesPage} trigger="click-trigger-favorite-home" />
        <NotificationsModal />
        <UpdateInfosModal />
        {updateNotificationInProgress && (
          <IonItem text-center className="update-in-progress">
            <IonLabel>Mise à jour des favoris en cours...</IonLabel>
          </IonItem>
        )}
      </IonContent>
    </IonPage>
  );
};

export default FavoritesPage;
