import { configureStore } from '@reduxjs/toolkit';
import { appSlice } from './app/appSlice';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import appMiddleware from './app/middleware';
import scanSourcesSlice from './scanSources/ScanSourcesSlice';
import favoriteSlice from './favorites/favoriteSlice';
import favoriteMiddleware from './favorites/middleware';
import chapterReadSlice from './chaptersRead/chapterReadSlice';
import tagsSlice from './tags/tagsSlice';
import notificationsSlice from './notifications/notificationsSlice';
import notificationsMiddleware from './notifications/middleware';
import ftpMangaSlice from './ftpManga/ftpMangaSlice';

const persistConfig = {
  key: 'app',
  storage: storage,
  blacklist: ['toastMessage'],
  whitelist: ['token', 'viewer', 'apiUrl', 'currentUser', 'theme', 'titleDisplay', 'displayMangaListType', 'displayFavoritesListType', 'downloadFolder', 'appMode', 'ftpAccess', 'connectedFtp'],
};

const persistConfigScanSources = {
  key: 'scanSources',
  storage: storage,
  whitelist: ['sources'],
};

const persistConfigFavorites = {
  key: 'favorite',
  storage: storage,
};

const persistConfigTags = {
  key: 'tags',
  storage: storage,
  whitelist: ['tags'],
};

const persistConfigChapterRead = {
  key: 'chapterRead',
  storage: storage,
};

const persistConfigNotifications = {
  key: 'notifications',
  storage: storage,
  whitelist: ['lastUpdate'],
};

const persistConfigFtpMangaSlice = {
  key: 'ftpManga',
  storage: storage,
  whitelist: ['manga'],
};

const reducers = combineReducers({
  app: persistReducer(persistConfig, appSlice.reducer),
  scanSources: persistReducer(persistConfigScanSources, scanSourcesSlice),
  favorites: persistReducer(persistConfigFavorites, favoriteSlice),
  chapterRead: persistReducer(persistConfigChapterRead, chapterReadSlice),
  tags: persistReducer(persistConfigTags, tagsSlice),
  notifications: persistReducer(persistConfigNotifications, notificationsSlice),
  ftpManga: persistReducer(persistConfigFtpMangaSlice, ftpMangaSlice),
});

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend(appMiddleware.middleware)
      .prepend(notificationsMiddleware.middleware)
      .prepend(favoriteMiddleware.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
