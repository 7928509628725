import React from 'react';
import { getChapterPagesSrc } from '../utils/helpers';

interface DisplayPageProps {
  sourceId: string;
  mediaUrl: string;
}

const DisplayPage: React.FC<DisplayPageProps> = ({ sourceId, mediaUrl }: DisplayPageProps) => {
  return (
    <div className="swiper-zoom-container">
      <img alt="img" src={sourceId === 'allporncomic' || sourceId === 'mangafr' ? getChapterPagesSrc(sourceId, mediaUrl) : mediaUrl} height="auto" width="100%" />
    </div>
  );
};

export default DisplayPage;
