import { createSlice } from '@reduxjs/toolkit';
import { FavoriteState } from './types';
import { addFavorite, addTagFavorite, deleteTagFavorite, getUserFavorites, refreshUserFavorites, removeFavorite } from './actions';

export const defaultFavoriteState: FavoriteState = {
  favorites: [],
};

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState: defaultFavoriteState,
  reducers: {
    resetFavorite: state => {
      state.favorites = [];
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserFavorites.fulfilled, (state, { payload }) => {
      state.favorites = payload;
    });
    builder.addCase(refreshUserFavorites.fulfilled, (state, { payload }) => {
      state.favorites = payload;
    });
    builder.addCase(addFavorite.fulfilled, (state, { payload }) => {
      state.favorites.push(payload);
    });
    builder.addCase(removeFavorite.fulfilled, (state, { payload }) => {
      state.favorites = state.favorites.filter(item => item._id !== payload._id);
    });
    builder.addCase(addTagFavorite.fulfilled, (state, { payload }) => {
      const favorite = state.favorites?.find(item => item._id === payload._id);
      if (favorite) {
        favorite.tags = payload.tags;
      } else {
        state.favorites.push(payload);
      }
    });
    builder.addCase(deleteTagFavorite.fulfilled, (state, { payload }) => {
      const favorite = state.favorites?.find(item => item._id === payload._id);
      if (favorite) {
        favorite.tags = payload.tags;
      } else {
        state.favorites.push(payload);
      }
    });
  },
});

export const { resetFavorite } = favoriteSlice.actions;

export default favoriteSlice.reducer;
