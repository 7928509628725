import React from 'react';
import { IonContent, IonPopover, IonText } from '@ionic/react';

export const textsPopover = {
  chaptersPageAndroid:
    // eslint-disable-next-line max-len
    'Vous pouvez ajouter le manga en favoris pour le retrouver sur votre page d&apos;accueil.<br /><br />Vous pouvez télécharger les chapitres pour les lire hors ligne. Ils seront téléchargés dans l&apos;app.',
  chaptersPage: 'Vous pouvez ajouter le manga en favoris pour le retrouver sur votre page d&apos;accueil.',
  favoritesPage:
    // eslint-disable-next-line max-len
    'Vos favoris sont gardés en mémoire. <br /><br /> N&apos;ésitez pas à les raffraichir pour voir si de nouveaux chapitres sont disponible !<br /><br />Nombre à gauche, se qu&apos;il vous reste à lire <br /><br />Nombre à droite, Le nombre de chapitres disponible.',
  searchAllSourceModal: 'Vous allez lancer une recherche sur toutes les sources, cela peut prendre un peu de temps.',
  tagModal: 'Choisissez un ou plusieurs tags pour trier vos favoris sur la page d&apos;accueil. <br /><br />Un favoris doit avoir obligatoirement un tag !',
  registerPage: 'Si adulte n&apos;est pas coché, les scans à caractère sexuel ne seront pas disponnible.',
  tagsUpdateModal: 'Vous pouver modifier le noms des tags, les supprimer et également changer l&apos;ordre en glissant les items (cliquez et glisser sur l&apos;icone de droite)',
  mangaFtpPage: 'Ajoutez ou connectez vous à un serveur FTP. Veuillez vous référer a la documentation pour ajouter un accès.',
};

interface PopoverComponentProps {
  text: string;
  trigger: string;
}

const PopoverComponent: React.FC<PopoverComponentProps> = ({ text, trigger }: PopoverComponentProps) => {
  return (
    <IonPopover trigger={trigger} triggerAction="click">
      <IonContent class="ion-padding">
        <IonText dangerouslySetInnerHTML={{ __html: text }} />
      </IonContent>
    </IonPopover>
  );
};

export default PopoverComponent;
