import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationsState } from './types';
import { getUserNotificationAction, updateNotificationAction, updateReadNNotificationsAction } from './actions';
import moment from 'moment';

export const defaultNotificationsState: NotificationsState = {
  notifications: [],
  updateInProgress: false,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: defaultNotificationsState,
  reducers: {
    reinitializeNotifications: state => {
      state.notifications = [];
      return state;
    },
    setUpdateInProgress: (state, action: PayloadAction<boolean>) => {
      state.updateInProgress = action.payload;
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(updateNotificationAction.fulfilled, (state, { payload }) => {
      state.notifications = payload.userNotifications;
      state.lastUpdate = moment().format();
    });
    builder.addCase(getUserNotificationAction.fulfilled, (state, { payload }) => {
      state.notifications = payload;
    });
    builder.addCase(updateReadNNotificationsAction.fulfilled, (state, { payload }) => {
      state.notifications = payload;
    });
  },
});

export const { reinitializeNotifications, setUpdateInProgress } = notificationsSlice.actions;

export default notificationsSlice.reducer;
