import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appModeType, AppState, displayMangaListType, downloadFolderType, ftpAccessType, ResetAppState, themeType, ToastMessage, viewerTopBottom } from './types';
import { defaultCurrentUser } from '../users/reducer';
import {
  getCurrentUser,
  refreshToken,
  registerUser,
  login,
  deleteUser,
  passwordLostAction,
  checkPasswordLostAction,
  updatePasswordLostAction,
  addFtpAccess,
  getFtpAccess,
  deleteFtpAccessAction,
  updateFtpAccess,
  getAppProdVersion,
  updateSpeedModeAction,
} from './actions';
import { Directory } from '@capacitor/filesystem';

const defaultToastMessage: ToastMessage = {
  text: '',
  isOpen: false,
  icon: '',
  position: 'top',
};

export const defaultAppState: AppState = {
  token: '',
  theme: 'dark',
  currentUser: defaultCurrentUser,
  isFullyLogged: false,
  isLoading: false,
  toastMessage: defaultToastMessage,
  appLoading: true,
  tabBarVisible: true,
  headerVisible: true,
  viewer: viewerTopBottom,
  apiUrl: process.env.REACT_APP_API_PROD_URL || 'https://nas-scan.herokuapp.com',
  titleDisplay: true,
  displayMangaListType: 'cover',
  displayFavoritesListType: 'cover',
  downloadFolder: Directory.Data,
  appMode: 'sources',
  ftpAccess: [],
  connectedFtp: undefined,
  appProdVersion: undefined,
};

export const resetState: ResetAppState = {
  token: '',
  currentUser: defaultCurrentUser,
  isFullyLogged: false,
  isLoading: false,
  toastMessage: defaultToastMessage,
  appLoading: true,
  tabBarVisible: true,
  headerVisible: true,
  ftpAccess: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState: defaultAppState,
  reducers: {
    setIsFullyLogged: (state, action: PayloadAction<boolean>) => {
      state.isFullyLogged = action.payload;
      state.isLoading = false;
      state.appLoading = false;
      return state;
    },
    setViewer: (state, action: PayloadAction<string>) => {
      state.viewer = action.payload;
      return state;
    },
    setConnectedFtp: (state, action: PayloadAction<ftpAccessType>) => {
      state.connectedFtp = action.payload;
      return state;
    },
    resetConnectedFtp: state => {
      state.connectedFtp = undefined;
      return state;
    },
    setAppMode: (state, action: PayloadAction<appModeType>) => {
      state.appMode = action.payload;
      return state;
    },
    setDownloadFolder: (state, action: PayloadAction<downloadFolderType>) => {
      state.downloadFolder = action.payload;
    },
    setDisplayMangaListType: (state, action: PayloadAction<displayMangaListType>) => {
      state.displayMangaListType = action.payload;
    },
    setDisplayFavoritesListType: (state, action: PayloadAction<displayMangaListType>) => {
      state.displayFavoritesListType = action.payload;
    },
    setDisplayTitle: (state, action: PayloadAction<boolean>) => {
      state.titleDisplay = action.payload;
      return state;
    },
    setTheme: (state, action: PayloadAction<themeType>) => {
      state.theme = action.payload;
      return state;
    },
    setApiURL: (state, action: PayloadAction<string>) => {
      state.apiUrl = action.payload;
      return state;
    },
    setTabBarVisible: (state, action: PayloadAction<boolean>) => {
      state.tabBarVisible = action.payload;
    },
    setHeaderVisible: (state, action: PayloadAction<boolean>) => {
      state.headerVisible = action.payload;
    },
    closeToastMessage: state => {
      state.toastMessage = defaultToastMessage;
      return state;
    },
    setToastMessage: (state, action: PayloadAction<ToastMessage>) => {
      state.toastMessage = action.payload;
      return state;
    },
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
      state.isLoading = false;
      return state;
    },
    setReinitializeApp: state => {
      state = { ...state, ...resetState };
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAppProdVersion.fulfilled, (state, { payload }) => {
      state.appProdVersion = payload.version;
    });
    builder.addCase(updatePasswordLostAction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updatePasswordLostAction.fulfilled, state => {
      state.isLoading = false;
      return state;
    });
    builder.addCase(updatePasswordLostAction.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(passwordLostAction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(passwordLostAction.fulfilled, state => {
      state.isLoading = false;
      return state;
    });
    builder.addCase(passwordLostAction.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(checkPasswordLostAction.fulfilled, state => {
      return state;
    });
    builder.addCase(deleteUser.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteUser.fulfilled, state => {
      state.isLoading = false;
      state.token = undefined;
    });
    builder.addCase(deleteUser.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(registerUser.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.token = payload.token;
      localStorage.setItem('token', payload.token);
    });
    builder.addCase(registerUser.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(login.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.token = payload.token;
      localStorage.setItem('token', payload.token);
    });
    builder.addCase(login.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      state.isFullyLogged = true;
      state.currentUser = payload;
    });
    builder.addCase(getCurrentUser.rejected, state => {
      state.isLoading = false;
      state.token = undefined;
    });
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => {
      state.token = payload.token;
      state.isFullyLogged = true;
      localStorage.setItem('token', payload.token);
    });
    builder.addCase(refreshToken.rejected, state => {
      state.isLoading = false;
      localStorage.removeItem('token');
      state.token = undefined;
    });
    builder.addCase(addFtpAccess.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(addFtpAccess.fulfilled, (state, { payload }) => {
      state.ftpAccess.push(payload);
      state.isLoading = false;
    });
    builder.addCase(addFtpAccess.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getFtpAccess.fulfilled, (state, { payload }) => {
      state.ftpAccess = payload;
      return state;
    });
    builder.addCase(deleteFtpAccessAction.fulfilled, (state, { payload }) => {
      state.ftpAccess = payload;
      return state;
    });
    builder.addCase(updateFtpAccess.fulfilled, (state, { payload }) => {
      state.ftpAccess = payload;
      return state;
    });
    builder.addCase(updateFtpAccess.rejected, state => {
      return state;
    });
    builder.addCase(updateSpeedModeAction.fulfilled, (state, { payload }) => {
      state.currentUser.speedMode = payload.speedMode;
      return state;
    });
  },
});

export const {
  setDisplayMangaListType,
  setDisplayFavoritesListType,
  setDisplayTitle,
  setTheme,
  setIsFullyLogged,
  setToastMessage,
  closeToastMessage,
  setAppLoading,
  setTabBarVisible,
  setHeaderVisible,
  setViewer,
  setReinitializeApp,
  setApiURL,
  setDownloadFolder,
  setAppMode,
  setConnectedFtp,
  resetConnectedFtp,
} = appSlice.actions;

export default appSlice.reducer;
