import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonPage,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonButton,
  IonBadge,
  IonIcon,
  IonFabButton,
  IonFab,
  IonItemDivider,
  RefresherEventDetail,
  IonRefresherContent,
  IonRefresher,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { getScanSourcesList } from '../store/scanSources/actions';
import { useParams } from 'react-router';
import { getScanSourcesListType, sourceList, sources } from '../store/scanSources/types';

import './ScanListePage.scss';
import { GridSourceCover, GridSourceList } from '../components/GridCover';
import { resetSourceList } from '../store/scanSources/ScanSourcesSlice';
import SearchModal from '../components/SearchModal';
import { arrowUpOutline, closeOutline, gridOutline, listOutline, searchOutline } from 'ionicons/icons';
import { createSearchRequest } from '../utils/helpers';
import { setDisplayMangaListType } from '../store/app/appSlice';

interface ParamTypes {
  sourceId: string;
}

const ScanListPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const sourceList = useSelector((state: RootState) => state.scanSources.sourceList);
  const [scanSourceList, setScanSourceList] = useState<sourceList | undefined>(undefined);
  const { sourceId } = useParams<ParamTypes>();
  // eslint-disable-next-line
  const scrollRef = React.useRef<any>(null);
  const displayMangaListType = useSelector((state: RootState) => state.app.displayMangaListType);
  const sources = useSelector((state: RootState) => state.scanSources.sources);
  const [searchAction, setSearchAction] = useState<boolean>(false);
  const selectedSource = useSelector((state: RootState) => state.scanSources.sources?.find((item: sources) => item.id === sourceId));
  const [page, setPage] = useState<number>(0);
  //search
  const [searchTitle, setSearchTitle] = useState<string | null>(null);
  const [searchGenre, setSearchGenre] = useState<string | null>(null);
  const [searchTag, setSearchTag] = useState<string | null>(null);
  const [searchOrderBy, setSearchOrderBy] = useState<string | null>(null);
  const [searchStatus, setSearchStatus] = useState<string | null>(null);
  const [scrollPosition, setScrollPosition] = useState<string | null>(window.sessionStorage.getItem(`scrollTop_${sourceId}`));

  const saveScrollPosition = (): void => {
    try {
      // eslint-disable-next-line
      scrollRef.current.getScrollElement().then((element: any) => {
        window.sessionStorage.setItem(`scrollTop_${sourceId}`, String(element.scrollTop));
        setScrollPosition(String(element.scrollTop));
      });
    } catch (err) {
      console.error(err);
    }
  };

  const scrollToTop = (): void => {
    try {
      // eslint-disable-next-line
      scrollRef.current.getScrollElement().then((element: any) => {
        window.sessionStorage.setItem(`scrollTop_${sourceId}`, '0');
        setScrollPosition(String(element.scrollTop));
        scrollRef.current.scrollToTop(300);
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (sourceList) {
      setScanSourceList(sourceList.find((item: sourceList) => item.source === sourceId));
      return;
    }

    //init scrollpostion to 0 if no sourcelist
    window.sessionStorage.setItem(`scrollTop_${sourceId}`, '0');
    setScrollPosition('0');
  }, [sourceList]);

  useEffect(() => {
    if (scanSourceList) {
      setPage(scanSourceList.page);
      setSearchGenre(scanSourceList.searchGenre);
      setSearchTag(scanSourceList.searchTag);
      setSearchOrderBy(scanSourceList.searchOrderBy);
      setSearchStatus(scanSourceList.searchStatus);
    } else {
      setPage(selectedSource?.startPageOne ? 1 : 0);
      setSearchGenre(null);
      setSearchTag(null);
      setSearchTitle(null);
      setSearchOrderBy(null);
      setSearchStatus(null);
    }
  }, [scanSourceList]);

  useEffect(() => {
    if (sourceList && sourceList.find((item: sourceList) => item.source === sourceId)) {
      //go to scroll position
      const scrollTo: string | null = window.sessionStorage.getItem(`scrollTop_${sourceId}`);

      if (scrollTo) {
        scrollRef.current.scrollToPoint(0, parseInt(scrollTo));
      }
      return;
    }

    const search = createSearchRequest(sourceId, selectedSource?.startPageOne ? 1 : 0, searchTitle, searchGenre, searchTag, searchOrderBy, searchStatus);
    dispatch(getScanSourcesList(search));
  }, []);

  // eslint-disable-next-line
  const loadData = async (event: any): Promise<void> => {
    if (sourceList) {
      if (scanSourceList) {
        const sendPage = selectedSource?.byPage ? parseInt(page.toString()) + parseInt('1') : scanSourceList.mangaList.length;
        if (selectedSource?.byPage) {
          setPage(parseInt(page.toString()) + parseInt('1'));
        }
        const search = createSearchRequest(sourceId, sendPage, searchTitle, searchGenre, searchTag, searchOrderBy, searchStatus);
        await dispatch(getScanSourcesList(search));
        event.target.complete();
      }
    }
  };

  const updateList = async (title: string, genre: string | null, tag: string | null, orderBy: string | null, status: string | null): Promise<void> => {
    setSearchAction(true);
    await dispatch(resetSourceList(sourceId));
    setScanSourceList(undefined);
    const search = createSearchRequest(sourceId, selectedSource?.startPageOne ? 1 : 0, title, genre, tag, orderBy, status);
    if (title) {
      setSearchTitle(title);
    }
    setSearchGenre(genre);
    setSearchTag(tag);
    await dispatch(getScanSourcesList(search));
    setSearchAction(false);
  };

  const resetSearchGenre = async (): Promise<void> => {
    setSearchGenre(null);
    const search = createSearchRequest(sourceId, selectedSource?.startPageOne ? 1 : 0, searchTitle, null, searchTag, searchOrderBy, searchStatus);
    await resetSearch(search);
  };

  const resetSearchTitle = async (): Promise<void> => {
    setSearchTitle(null);
    const search = createSearchRequest(sourceId, selectedSource?.startPageOne ? 1 : 0, null, searchGenre, searchTag, searchOrderBy, searchStatus);
    await resetSearch(search);
  };

  const resetSearchTag = async (): Promise<void> => {
    setSearchTag(null);
    const search = createSearchRequest(sourceId, selectedSource?.startPageOne ? 1 : 0, searchTitle, searchGenre, null, searchOrderBy, searchStatus);
    await resetSearch(search);
  };

  const resetSearchOrderBy = async (): Promise<void> => {
    setSearchTag(null);
    const search = createSearchRequest(sourceId, selectedSource?.startPageOne ? 1 : 0, searchTitle, searchGenre, searchTag, null, searchStatus);
    await resetSearch(search);
  };

  const resetSearchStatus = async (): Promise<void> => {
    setSearchStatus(null);
    const search = createSearchRequest(sourceId, selectedSource?.startPageOne ? 1 : 0, searchTitle, searchGenre, searchTag, searchOrderBy, null);
    await resetSearch(search);
  };

  const resetSearch = async (search: getScanSourcesListType): Promise<void> => {
    setSearchAction(true);
    setPage(selectedSource?.startPageOne ? 1 : 0);
    await dispatch(resetSourceList(sourceId));
    await dispatch(getScanSourcesList(search));
    setSearchAction(false);
  };

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>): Promise<void> => {
    await setSearchAction(true);
    await dispatch(resetSourceList(sourceId));
    setScanSourceList(undefined);
    const search = createSearchRequest(sourceId, selectedSource?.startPageOne ? 1 : 0, searchTitle, searchGenre, searchTag, searchOrderBy, searchStatus);
    await dispatch(getScanSourcesList(search));
    setSearchAction(false);
    event.detail.complete();
  };

  console.log(scanSourceList);

  return (
    <IonPage className="scan-list-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{sources && sources.find(source => scanSourceList?.source === source.id)?.name}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => dispatch(setDisplayMangaListType(displayMangaListType === 'cover' ? 'list' : 'cover'))} expand="block">
              <IonIcon icon={displayMangaListType === 'cover' ? listOutline : gridOutline} />
            </IonButton>
            <IonButton id="open-search-modal" expand="block" className="search-button">
              <IonIcon icon={searchOutline} id="open-search-modal" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={scrollRef} fullscreen onIonScrollEnd={() => saveScrollPosition()} scrollEvents={true}>
        {(searchGenre || searchTag || searchTitle || searchOrderBy || searchStatus) && (
          <IonItemDivider sticky>
            <div className="container-badge">
              {searchGenre && (
                <IonBadge className="badge" onClick={() => resetSearchGenre()}>
                  <span className="badge-text">{selectedSource?.genres[searchGenre]}</span> <IonIcon className="badge-icon" icon={closeOutline} />
                </IonBadge>
              )}
              {searchTag && (
                <IonBadge className="badge" onClick={() => resetSearchTag()}>
                  <span className="badge-text">{selectedSource?.tags[searchTag]}</span> <IonIcon className="badge-icon" icon={closeOutline} />
                </IonBadge>
              )}
              {searchTitle && (
                <IonBadge className="badge" onClick={() => resetSearchTitle()}>
                  <span className="badge-text">{searchTitle}</span> <IonIcon className="badge-icon" icon={closeOutline} />
                </IonBadge>
              )}
              {searchOrderBy && (
                <IonBadge className="badge" onClick={() => resetSearchOrderBy()}>
                  <span className="badge-text">{selectedSource?.orderBy[searchOrderBy].orderBy}</span> <IonIcon className="badge-icon" icon={closeOutline} />
                </IonBadge>
              )}
              {searchStatus && (
                <IonBadge className="badge" onClick={() => resetSearchStatus()}>
                  <span className="badge-text">{selectedSource?.status[searchStatus]}</span> <IonIcon className="badge-icon" icon={closeOutline} />
                </IonBadge>
              )}
            </div>
          </IonItemDivider>
        )}
        {scanSourceList && scanSourceList.mangaList.length === 0 && !searchAction && (
          <div className="no-result">
            <h1>
              Aucun résultat pour <br />
              votre recherche
            </h1>
          </div>
        )}
        {scanSourceList && !searchAction ? (
          scanSourceList.mangaList && (
            <>
              <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
              {displayMangaListType === 'cover' ? (
                <GridSourceCover mangaList={scanSourceList.mangaList} source={scanSourceList?.source} />
              ) : (
                <GridSourceList mangaList={scanSourceList.mangaList} source={scanSourceList?.source} />
              )}
              <IonInfiniteScroll onIonInfinite={loadData} threshold="500px">
                <IonInfiniteScrollContent loadingSpinner="bubbles" className="loading" loadingText="Chargement..."></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
          )
        ) : (
          <IonLoading cssClass="my-custom-class" isOpen={true} message={'Chargement...'} />
        )}
        {scrollPosition && scrollPosition !== '0' && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={() => scrollToTop()}>
            <IonFabButton>
              <IonIcon icon={arrowUpOutline} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
      <SearchModal title={searchTitle} updateList={updateList} selectedSource={selectedSource} genre={searchGenre} tag={searchTag} orderBy={searchOrderBy} status={searchStatus} />
    </IonPage>
  );
};

export default ScanListPage;
