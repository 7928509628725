import axios from 'axios';
import { store } from './store';

// eslint-disable-next-line
export const getRequestApi = (url: string): Promise<any> => {
  const apiUrl = store.getState().app.apiUrl || '';
  return axios
    .get(`${apiUrl}${url}`, {
      method: 'GET',
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

// eslint-disable-next-line
export const getRequestApiWithAuth = (url: string): Promise<any> => {
  const apiUrl = store.getState().app.apiUrl || '';
  const token = store.getState().app.token;
  return axios
    .get(`${apiUrl}${url}`, {
      method: 'GET',
      withCredentials: true,
      headers: {
        credentials: 'include',
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

// eslint-disable-next-line
export const postRequestApi = (url: string, data: object): Promise<any> => {
  const apiUrl = store.getState().app.apiUrl || '';
  return axios
    .post(`${apiUrl}${url}`, data, {
      method: 'GET',
      withCredentials: true,
      headers: {
        credentials: 'include',
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

// eslint-disable-next-line
export const postRequestApiWithAuth = (url: string, data: object): Promise<any> => {
  const apiUrl = store.getState().app.apiUrl || '';
  const token = localStorage.getItem('token');
  return axios
    .post(`${apiUrl}${url}`, data, {
      method: 'POST',
      withCredentials: true,
      headers: {
        credentials: 'same-origin',
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
