import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestApiWithAuth, postRequestApiWithAuth } from '../axiosApiRequest';
import axios from 'axios';
import { errorType } from '../app/types';
import { tagsSend, updateTagName, updateTagsOrder } from './types';

export const getUserTags = createAsyncThunk('app/getUserTags', async () => {
  try {
    return await getRequestApiWithAuth('/tags/me');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const addTag = createAsyncThunk('app/addTag', async (data: tagsSend) => {
  try {
    return await postRequestApiWithAuth('/tags/add', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const updateTagsOrderAction = createAsyncThunk('app/updateTagOrder', async (data: updateTagsOrder[]) => {
  try {
    return await postRequestApiWithAuth('/tags/update/order', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const updateTagsNameAction = createAsyncThunk('app/updateTagName', async (data: updateTagName) => {
  try {
    return await postRequestApiWithAuth('/tags/update/name', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const removeTag = createAsyncThunk('app/removeTag', async (tagId: string) => {
  try {
    return await postRequestApiWithAuth(`/tags/delete/${tagId}`, {});
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});
