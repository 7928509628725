import { mangaChaptersList } from '../store/scanSources/types';
import React, { useEffect, useRef, useState } from 'react';
import { IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLoading, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './SearchModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { favorites } from '../store/favorites/types';
import { bug, closeOutline, informationOutline } from 'ionicons/icons';
import { addTag } from '../store/tags/actions';
import { addFavorite, addTagFavorite, deleteTagFavorite } from '../store/favorites/actions';
import './TagsModal.scss';
import { setToastMessage } from '../store/app/appSlice';
import { tag } from '../store/tags/types';
import PopoverComponent, { textsPopover } from './PopoverComponent';

interface TagsModalTypes {
  favorite?: favorites;
  manga?: mangaChaptersList;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const TagsModal: React.FC<TagsModalTypes> = ({ favorite, isOpen, setIsOpen, manga }: TagsModalTypes) => {
  const dispatch = useDispatch<AppDispatch>();
  const tags = useSelector((state: RootState) => state.tags.tags);
  const favorites = useSelector((state: RootState) => state.favorites.favorites);
  const input = useRef<HTMLIonInputElement>(null);
  const [currentFavorite, setCurrentFavorite] = useState<favorites | undefined>(favorite);
  const [selectedManga, setSelectedManga] = useState<mangaChaptersList | undefined>(manga);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setCurrentFavorite(favorite);
  }, [favorite]);

  useEffect(() => {
    setSelectedManga(manga);
  }, [manga]);

  const addNewTag = async (): Promise<void> => {
    setIsLoading(true);
    let selectedFavorite = currentFavorite;
    if (manga && !currentFavorite) {
      selectedFavorite = favorites.find(favorite => favorite.mangaId === manga.mangaId);
    }
    if (selectedFavorite) {
      await addNewTagToFavorite(selectedFavorite);
    } else {
      if (manga) {
        await addNewTagAndCreateFavorite(manga);
      }
    }
    setIsLoading(false);
  };

  const addNewTagAndCreateFavorite = async (manga: mangaChaptersList): Promise<void> => {
    setIsLoading(true);
    const tag = await createTag();
    if (typeof tag === 'boolean') {
      return;
    }

    const newFavorite = await dispatch(
      addFavorite({
        source: manga.source,
        mangaId: manga.mangaId,
        mangaName: manga.mangaName,
      }),
    );
    const favoriteUpdate = await dispatch(addTagFavorite({ favoriteId: newFavorite.payload._id, tagId: tag._id }));
    setCurrentFavorite(favoriteUpdate.payload);
    setIsLoading(false);
  };

  const createTag = async (): Promise<tag | boolean> => {
    let name = '';
    if (input.current?.value && typeof input.current.value === 'string') {
      name = input.current.value;
    }
    if (!name) {
      return false;
    }
    const tag = await dispatch(addTag({ name }));
    if (tags.some(tag => tag.name === name)) {
      dispatch(
        setToastMessage({
          text: 'Le tag exist déjà',
          isOpen: true,
          icon: bug,
          position: 'bottom',
        }),
      );
      return false;
    }
    return tag.payload;
  };

  const addNewTagToFavorite = async (favorite: favorites): Promise<void> => {
    setIsLoading(true);
    const tag = await createTag();
    if (typeof tag === 'boolean') {
      return;
    }
    const favoriteUpdate = await dispatch(addTagFavorite({ favoriteId: favorite._id, tagId: tag._id }));
    setCurrentFavorite(favoriteUpdate.payload);
    setIsLoading(false);
  };

  const selectTag = async (tagId: string): Promise<void> => {
    setIsLoading(true);
    if (currentFavorite?.tags.some(tag => tag._id === tagId)) {
      dispatch(
        setToastMessage({
          text: 'Le tag existe déjà',
          isOpen: true,
          icon: bug,
          position: 'bottom',
        }),
      );
      setIsLoading(false);
      return;
    }

    let selectedFavorite = currentFavorite;
    if (manga && !currentFavorite) {
      selectedFavorite = favorites.find(favorite => favorite.mangaId === manga.mangaId);
    }

    if (!selectedFavorite && manga) {
      const newFavorite = await dispatch(
        addFavorite({
          source: manga.source,
          mangaId: manga.mangaId,
          mangaName: manga.mangaName,
        }),
      );
      const favoriteUpdate = await dispatch(addTagFavorite({ favoriteId: newFavorite.payload._id, tagId }));
      setCurrentFavorite(favoriteUpdate.payload);
      setCurrentFavorite(favoriteUpdate.payload);
      setIsLoading(false);
      return;
    }

    if (!currentFavorite) {
      return;
    }
    const favoriteUpdate = await dispatch(addTagFavorite({ favoriteId: currentFavorite._id, tagId }));
    setCurrentFavorite(favoriteUpdate.payload);
    setIsLoading(false);
  };

  const removeTag = async (tagId: string): Promise<void> => {
    if (!currentFavorite || currentFavorite.tags.length === 1) {
      return;
    }
    const favoriteUpdate = await dispatch(deleteTagFavorite({ favoriteId: currentFavorite._id, tagId }));
    setCurrentFavorite(favoriteUpdate.payload);
  };

  return (
    <IonModal className="tags-modal" isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{currentFavorite ? currentFavorite.mangaName : selectedManga?.mangaName}</IonTitle>
          <IonButtons slot="end">
            <IonButton id="click-trigger-tag">
              <IonIcon icon={informationOutline} />
            </IonButton>
            <IonButton strong={true} onClick={() => setIsOpen(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {currentFavorite && currentFavorite.tags.length !== 0 && (
          <>
            <h4>
              Tag{currentFavorite.tags.length > 1 && <span>s</span>} actuel{currentFavorite.tags.length > 1 && <span>s</span>}
            </h4>
            {currentFavorite.tags.map(tag => (
              <IonBadge key={tag.name} className="badge" onClick={() => removeTag(tag._id)}>
                <span className="badge-text">{tag.name}</span> {currentFavorite?.tags.length !== 1 && <IonIcon className="badge-icon" icon={closeOutline} />}
              </IonBadge>
            ))}
          </>
        )}
        <h4>Créer un tag pour le favoris</h4>
        <IonItem>
          <IonInput ref={input} type="text" />
          <IonButton color="secondary" slot="end" onClick={() => addNewTag()}>
            Valider
          </IonButton>
        </IonItem>
        <h4>Ou en choisir un </h4>
        {tags.map(tag => (
          <IonBadge key={tag.name} className="badge" onClick={() => selectTag(tag._id)}>
            <span className="badge-text">{tag.name}</span>
          </IonBadge>
        ))}
        <PopoverComponent text={textsPopover.tagModal} trigger="click-trigger-tag" />
        <IonLoading cssClass="my-custom-class" isOpen={isLoading} message={`Chargement...`} />
      </IonContent>
    </IonModal>
  );
};

export default TagsModal;
