import React from 'react';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { heartCircleOutline, personCircleOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import RoutingMenuFTP from '../RoutingMenuFTP';

const TabBarFtp: React.FC = () => {
  const tabBarVisible = useSelector((state: RootState) => state.app.tabBarVisible);
  const isFullyLogged = useSelector((state: RootState) => state.app.isFullyLogged);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <RoutingMenuFTP />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" style={{ display: tabBarVisible ? 'inherit' : 'none' }}>
        <IonTabButton className="tab-bar-button-footer" tab="manga" href="/manga">
          <IonIcon icon={heartCircleOutline} />
          <IonLabel>Manga FTP</IonLabel>
        </IonTabButton>
        <IonTabButton className="tab-bar-button-footer" tab="account" href={isFullyLogged ? '/account' : '/'}>
          <IonIcon icon={personCircleOutline} />
          <IonLabel>{isFullyLogged ? 'Compte' : 'Connexion'}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default TabBarFtp;
