import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestApiWithAuth, postRequestApiWithAuth } from '../axiosApiRequest';
import axios from 'axios';
import { errorType } from '../app/types';
import { addTagToFavorite, favoritesSend } from './types';

export const getUserFavorites = createAsyncThunk('app/getUserFavorites', async () => {
  try {
    return await getRequestApiWithAuth('/favorite/me');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const refreshUserFavorites = createAsyncThunk('app/refreshUserFavorites', async () => {
  try {
    return await getRequestApiWithAuth('/favorite/refresh');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const refreshFavorites = createAsyncThunk('app/refreshFavorites', async () => {
  try {
    return await getRequestApiWithAuth('/favorite/refresh');
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const addFavorite = createAsyncThunk('app/addFavorite', async (data: favoritesSend) => {
  try {
    return await postRequestApiWithAuth('/favorite/add', data);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const removeFavorite = createAsyncThunk('app/removeFavorite', async (mangaId: string) => {
  try {
    return await postRequestApiWithAuth(`/favorite/delete/${mangaId}`, {});
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const addTagFavorite = createAsyncThunk('app/addTagToFavorite', async (addTagToFavorite: addTagToFavorite) => {
  try {
    return await postRequestApiWithAuth(`/favorite/tag/add`, addTagToFavorite);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});

export const deleteTagFavorite = createAsyncThunk('app/deleteTagToFavorite', async (addTagToFavorite: addTagToFavorite) => {
  try {
    return await postRequestApiWithAuth(`/favorite/tag/delete`, addTagToFavorite);
  } catch (err) {
    // check if error is an axios error
    if (axios.isAxiosError(err)) {
      if (!err?.response) {
        throw new Error('No Server Response');
      } else {
        const errorData = err.response.data as errorType;
        throw new Error(errorData.message);
      }
    }
  }
});
